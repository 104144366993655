import { find } from 'lodash';

type IConfigKey =
	| 'GOOGLE_TAG_MANAGER_KEY'
	| 'GOOGLE_TAG_MANAGER_AUTH_ENVIRONMENT'
	| 'GOOGLE_TAG_MANAGER_ENVIRONMENT_NUM'
	| 'SHOW_FACEBOOK_LOGIN_BTN'
	| 'LOCAL_STORAGE_LOGGER'
	| 'LOG_WEBSOCKET_MESSAGES_TO_CONSOLE'
	| 'REDUX_DEVTOOLS_ENABLED'
	| 'GOOGLE_ANALYTICS_KEY'
	| 'FACEBOOK_PIXEL_KEY'
	| 'GOOGLE_ADS_SENSE_KEY'
	| 'SHOW_GOOGLE_ADS';

type IConfig = Record<IConfigKey, string | boolean>;
export interface IEnvironmentBasedConfig {
	origins: string[];
	config: IConfig;
}

const localEnv: IEnvironmentBasedConfig = {
	origins: ['localhost:3000', '127.0.0.1:3000'],
	config: {
		GOOGLE_TAG_MANAGER_KEY: 'GTM-MDCC25Z',
		GOOGLE_TAG_MANAGER_AUTH_ENVIRONMENT: 'tBeAGgOb-wFOEzEwalvXWg',
		GOOGLE_TAG_MANAGER_ENVIRONMENT_NUM: 'env-7',
		SHOW_FACEBOOK_LOGIN_BTN: true,
		LOG_WEBSOCKET_MESSAGES_TO_CONSOLE: true,
		REDUX_DEVTOOLS_ENABLED: true,
		LOCAL_STORAGE_LOGGER: false,
		GOOGLE_ANALYTICS_KEY: 'G-8Y54JZTV3Z',
		FACEBOOK_PIXEL_KEY: '1316125892623013',
		GOOGLE_ADS_SENSE_KEY: '',
		SHOW_GOOGLE_ADS: false,
	},
};

const devEnv: IEnvironmentBasedConfig = {
	origins: ['dev.private.gamy.fun', 'https://dev.private.gamy.fun/'],
	config: {
		GOOGLE_TAG_MANAGER_KEY: 'GTM-MDCC25Z',
		GOOGLE_TAG_MANAGER_AUTH_ENVIRONMENT: 'tBeAGgOb-wFOEzEwalvXWg',
		GOOGLE_TAG_MANAGER_ENVIRONMENT_NUM: 'env-7',
		SHOW_FACEBOOK_LOGIN_BTN: true,
		LOG_WEBSOCKET_MESSAGES_TO_CONSOLE: true,
		REDUX_DEVTOOLS_ENABLED: true,
		LOCAL_STORAGE_LOGGER: true,
		GOOGLE_ANALYTICS_KEY: 'G-8Y54JZTV3Z',
		FACEBOOK_PIXEL_KEY: '1316125892623013',
		GOOGLE_ADS_SENSE_KEY: '',
		SHOW_GOOGLE_ADS: false,
	},
};

const prodEnv: IEnvironmentBasedConfig = {
	origins: ['gamy.fun', 'https://gamy.fun'],
	config: {
		GOOGLE_TAG_MANAGER_KEY: 'GTM-MDCC25Z',
		GOOGLE_TAG_MANAGER_AUTH_ENVIRONMENT: 'cbsmKn9SKnqPHR1G4Jpnzw',
		GOOGLE_TAG_MANAGER_ENVIRONMENT_NUM: 'env-8',
		SHOW_FACEBOOK_LOGIN_BTN: false,
		LOG_WEBSOCKET_MESSAGES_TO_CONSOLE: false,
		REDUX_DEVTOOLS_ENABLED: false,
		LOCAL_STORAGE_LOGGER: false,
		GOOGLE_ANALYTICS_KEY: 'G-M93N65G4BC',
		FACEBOOK_PIXEL_KEY: '995994977846507',
		GOOGLE_ADS_SENSE_KEY: 'ca-pub-8245938824429330',
		SHOW_GOOGLE_ADS: true,
	},
};

const determineConfigEnvironment = (host: string) => {
	return find([localEnv, devEnv, prodEnv], (conf) => {
		return conf.origins.includes(host);
	});
};

export const environmentBasedConfig = (key: IConfigKey, host: string): string | boolean => {
	const foundEnv = determineConfigEnvironment(host);
	if (foundEnv) {
		const { config } = foundEnv;
		return config[key];
	}
	return '';
};

export default environmentBasedConfig;
