import React from 'react';

const IconGamyLogoWhite = () => {
	return (
		<svg width='101' height='28' viewBox='0 0 101 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15.68 10.7608H20.12V19.2808C18.98 20.1408 17.66 20.8008 16.16 21.2608C14.66 21.7208 13.15 21.9508 11.63 21.9508C9.45 21.9508 7.49 21.4908 5.75 20.5708C4.01 19.6308 2.64 18.3408 1.64 16.7008C0.66 15.0408 0.17 13.1708 0.17 11.0908C0.17 9.01082 0.66 7.15082 1.64 5.51082C2.64 3.85082 4.02 2.56082 5.78 1.64082C7.54 0.70082 9.52 0.23082 11.72 0.23082C13.56 0.23082 15.23 0.54082 16.73 1.16082C18.23 1.78082 19.49 2.68082 20.51 3.86082L17.39 6.74082C15.89 5.16082 14.08 4.37082 11.96 4.37082C10.62 4.37082 9.43 4.65082 8.39 5.21082C7.35 5.77082 6.54 6.56082 5.96 7.58082C5.38 8.60082 5.09 9.77082 5.09 11.0908C5.09 12.3908 5.38 13.5508 5.96 14.5708C6.54 15.5908 7.34 16.3908 8.36 16.9708C9.4 17.5308 10.58 17.8108 11.9 17.8108C13.3 17.8108 14.56 17.5108 15.68 16.9108V10.7608ZM30.3145 5.21082C32.8145 5.21082 34.7345 5.81082 36.0745 7.01082C37.4145 8.19082 38.0845 9.98082 38.0845 12.3808V21.5908H33.7045V19.5808C32.8245 21.0808 31.1845 21.8308 28.7845 21.8308C27.5445 21.8308 26.4645 21.6208 25.5445 21.2008C24.6445 20.7808 23.9545 20.2008 23.4745 19.4608C22.9945 18.7208 22.7545 17.8808 22.7545 16.9408C22.7545 15.4408 23.3145 14.2608 24.4345 13.4008C25.5745 12.5408 27.3245 12.1108 29.6845 12.1108H33.4045C33.4045 11.0908 33.0945 10.3108 32.4745 9.77082C31.8545 9.21082 30.9245 8.93082 29.6845 8.93082C28.8245 8.93082 27.9745 9.07082 27.1345 9.35082C26.3145 9.61082 25.6145 9.97082 25.0345 10.4308L23.3545 7.16082C24.2345 6.54082 25.2845 6.06082 26.5045 5.72082C27.7445 5.38082 29.0145 5.21082 30.3145 5.21082ZM29.9545 18.6808C30.7545 18.6808 31.4645 18.5008 32.0845 18.1408C32.7045 17.7608 33.1445 17.2108 33.4045 16.4908V14.8408H30.1945C28.2745 14.8408 27.3145 15.4708 27.3145 16.7308C27.3145 17.3308 27.5445 17.8108 28.0045 18.1708C28.4845 18.5108 29.1345 18.6808 29.9545 18.6808Z'
				fill='#FF652E'
			/>
			<path
				d='M62.4902 5.21082C64.5102 5.21082 66.1102 5.81082 67.2902 7.01082C68.4902 8.19082 69.0902 9.97082 69.0902 12.3508V21.5908H64.4102V13.0708C64.4102 11.7908 64.1402 10.8408 63.6002 10.2208C63.0802 9.58082 62.3302 9.26082 61.3502 9.26082C60.2502 9.26082 59.3802 9.62082 58.7402 10.3408C58.1002 11.0408 57.7802 12.0908 57.7802 13.4908V21.5908H53.1002V13.0708C53.1002 10.5308 52.0802 9.26082 50.0402 9.26082C48.9602 9.26082 48.1002 9.62082 47.4602 10.3408C46.8202 11.0408 46.5002 12.0908 46.5002 13.4908V21.5908H41.8202V5.45082H46.2902V7.31082C46.8902 6.63082 47.6202 6.11082 48.4802 5.75082C49.3602 5.39082 50.3202 5.21082 51.3602 5.21082C52.5002 5.21082 53.5302 5.44082 54.4502 5.90082C55.3702 6.34082 56.1102 6.99082 56.6702 7.85082C57.3302 7.01082 58.1602 6.36082 59.1602 5.90082C60.1802 5.44082 61.2902 5.21082 62.4902 5.21082ZM88.512 5.45082L81.222 22.5808C80.482 24.4408 79.562 25.7508 78.462 26.5108C77.382 27.2708 76.072 27.6508 74.532 27.6508C73.692 27.6508 72.862 27.5208 72.042 27.2608C71.222 27.0008 70.552 26.6408 70.032 26.1808L71.742 22.8508C72.102 23.1708 72.512 23.4208 72.972 23.6008C73.452 23.7808 73.922 23.8708 74.382 23.8708C75.022 23.8708 75.542 23.7108 75.942 23.3908C76.342 23.0908 76.702 22.5808 77.022 21.8608L77.082 21.7108L70.092 5.45082H74.922L79.452 16.4008L84.012 5.45082H88.512Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M92.8354 5.11271C92.8354 3.01234 94.5158 1.24121 96.6842 1.24121C98.8414 1.24121 100.522 2.97828 100.522 5.09C100.522 7.19038 98.83 8.96151 96.6615 8.96151C94.5044 8.96151 92.8354 7.21309 92.8354 5.11271ZM93.6529 5.11271C93.6529 6.75895 94.9585 8.11 96.6615 8.11C98.3872 8.11 99.7042 6.73624 99.7042 5.09C99.7042 3.44377 98.3873 2.09271 96.6842 2.09271C94.9699 2.09271 93.6529 3.46647 93.6529 5.11271ZM96.7864 3.13722C97.7628 3.13722 98.3078 3.60271 98.3078 4.4088C98.3078 4.98782 98.058 5.39655 97.6039 5.60091L98.41 6.82707H97.4676L96.8773 5.75985H96.7864H96.0371V6.82707H95.2197V3.13722H96.7864ZM96.0371 3.79572V5.10136H96.7864C97.2519 5.10136 97.5244 4.86294 97.5244 4.44286C97.5244 4.02279 97.2519 3.79572 96.7864 3.79572H96.0371Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconGamyLogoWhite;
