import { createSlice } from '@reduxjs/toolkit';
import { IStatisticsState } from '../models/statistics-state.model';
import { emitHttpTournamentStatistics } from './statistics.actions';

export const initialStatistics: IStatisticsState = {
	loadingState: {
		fetchStatistics: false,
	},
	awardsGivenCount: 0,
	awardsTotalValue: 0,
	gamesWithAwardsCount: 0,
	winnersCount: 0,
};

const statistics = createSlice({
	name: '[Statistics] -',
	initialState: initialStatistics,
	reducers: {},
	extraReducers: ({ addCase }) => {
		addCase(emitHttpTournamentStatistics.pending, (state) => {
			state.loadingState.fetchStatistics = true;
		});
		addCase(emitHttpTournamentStatistics.fulfilled, (state, action) => {
			state.loadingState.fetchStatistics = false;
			const { awardsGivenCount, awardsTotalValue, gamesWithAwardsCount, winnersCount } = action.payload.data;

			state.awardsGivenCount = awardsGivenCount;
			state.awardsTotalValue = awardsTotalValue;
			state.gamesWithAwardsCount = gamesWithAwardsCount;
			state.winnersCount = winnersCount;
		});
		addCase(emitHttpTournamentStatistics.rejected, (state) => {
			state.loadingState.fetchStatistics = false;
		});
	},
});

export const statisticsReducer = statistics.reducer;
