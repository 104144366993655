import { getCdnUrl } from '@features/common/store/common.selectors';
import { IOnsiteNotification } from '@features/onsiteNotifications/models/onsiteNotification.model';
import {
	getRegisteredTournamentById,
	getTournamentsSelectedTournament,
} from '@features/tournaments/store/tournaments.selectors';
import Image from 'next/image';
import { Router, useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Subject, fromEvent } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import TournamentNotificationInfo from './TournamentNotificationInfo';
import { emitNotificationsIncrementUnreadCount } from '@features/notifications/store/notifications.reducer';
import { useAppDispatch } from '@store/store';

interface IOnsiteNotificationProps {
	clearOnsiteNotification: (onsiteNotificationId: string) => void;
	onsiteNotification: IOnsiteNotification;
}

const OnsiteNotification = ({ clearOnsiteNotification, onsiteNotification }: IOnsiteNotificationProps) => {
	const cdnUrl = useSelector(getCdnUrl);
	const animateRef = useRef<SVGAnimateElement>(null);
	const destroySubjectRef = useRef(new Subject());
	const dispatch = useAppDispatch();
	const registeredTournament = useSelector(getRegisteredTournamentById(onsiteNotification.id));
	const selectedTournament = useSelector(getTournamentsSelectedTournament);
	const router = useRouter();

	const clearNotification = useCallback(() => {
		destroySubjectRef.current.next();
		destroySubjectRef.current.complete();
		clearOnsiteNotification(onsiteNotification.id);
	}, [clearOnsiteNotification, onsiteNotification.id]);

	useEffect(() => {
		const subscription =
			animateRef.current &&
			fromEvent(animateRef.current, 'endEvent')
				.pipe(tap(clearNotification), first(), takeUntil(destroySubjectRef.current))
				.subscribe();

		return () => subscription?.unsubscribe();
	}, [clearNotification]);

	useEffect(() => {
		if (registeredTournament || selectedTournament) {
			Router.events.on('routeChangeStart', clearNotification);

			return () => {
				Router.events.off('routeChangeStart', clearNotification);
			};
		}
	}, [clearNotification, registeredTournament, selectedTournament]);

	useEffect(() => {
		if (router.pathname.includes('play')) {
			return;
		}

		if (onsiteNotification.type === 'WS') {
			dispatch(emitNotificationsIncrementUnreadCount());
		}
	}, [dispatch, onsiteNotification.type, router.pathname]);

	return (
		<div data-testid='onsiteNotificationItem'>
			{onsiteNotification.type === 'WS' ? (
				<div
					className='bg-white relative flex p-3 md:rounded-xl shadow-md md:w-100'
					data-testid='onsiteNotificationItem'
				>
					<div className='mr-8 flex space-x-4 items-center'>
						<div className='w-16'>
							<div className='aspect-w-14 aspect-h-9'>
								<Image
									alt='notification-image'
									unoptimized={true}
									src={`${cdnUrl}files/${onsiteNotification.imageId}.jpeg`}
									layout='fill'
									objectFit='cover'
									priority={true}
								/>
							</div>
						</div>
						<p className='text-sm text-dark-700 flex-1 line-clamp-3' title={onsiteNotification.content}>
							{onsiteNotification.content}
						</p>
					</div>
					<button
						type='button'
						className='focus:outline-none self-start absolute top-2 right-2'
						style={{
							willChange: 'transform',
							transform: 'scale(-1, 1) rotateZ(270deg)',
						}}
						data-testid='closeButton'
						onClick={clearNotification}
					>
						<svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<line
								x1='4.46967'
								y1='14.5712'
								x2='14.5712'
								y2='4.46968'
								stroke='#808191'
								strokeWidth='1.5'
							/>
							<line
								x1='14.5712'
								y1='14.6319'
								x2='4.46968'
								y2='4.53034'
								stroke='#808191'
								strokeWidth='1.5'
							/>
							<circle cx='9.5' cy='9.5' r='9' stroke='#FF652E' strokeDasharray='56' fill='none'>
								<animate
									attributeType='XML'
									attributeName='stroke-dashoffset'
									from='0'
									to='56'
									dur={`5s`}
									repeatCount='1'
									fill='freeze'
									ref={animateRef}
								/>
							</circle>
						</svg>
					</button>
				</div>
			) : (
				<div
					data-testid='tournamentRegistrationSuccess'
					className='w-full flex md:bg-opacity-75 2xs:py-3 2xs:pl-3 2xs:pr-8 md:py-5 md:pl-3.5 md:pr-9 md:rounded-xl shadow-md md:w-100 relative'
					style={{ background: 'rgba(201, 241, 182, 1)', backdropFilter: 'blur(9px)' }}
				>
					<div className=' text-white' style={{ letterSpacing: '0.1px', color: 'white' }}>
						{registeredTournament ? (
							<TournamentNotificationInfo
								registered={registeredTournament.registered}
								tournamentStartDate={registeredTournament.tournamentStartDate}
								registrationEndDate={registeredTournament.registrationEndDate}
								statusId={registeredTournament.statusId}
								name={registeredTournament.name}
							/>
						) : selectedTournament ? (
							<TournamentNotificationInfo
								registered={selectedTournament.registered}
								tournamentStartDate={selectedTournament.tournamentStartDate}
								registrationEndDate={selectedTournament.registrationEndDate}
								statusId={selectedTournament.statusId}
								name={selectedTournament.translation.name}
							/>
						) : null}
					</div>
					<button
						type='button'
						className='focus:outline-none self-start absolute right-2 top-2'
						style={{
							willChange: 'transform',
							transform: 'scale(-1, 1) rotateZ(270deg)',
						}}
						data-testid='closeButton'
						onClick={clearNotification}
					>
						<svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<line
								x1='4.46967'
								y1='14.5712'
								x2='14.5712'
								y2='4.46968'
								stroke='#808191'
								strokeWidth='1.5'
							/>
							<line
								x1='14.5712'
								y1='14.6319'
								x2='4.46968'
								y2='4.53034'
								stroke='#808191'
								strokeWidth='1.5'
							/>
							<circle cx='9.5' cy='9.5' r='9' stroke='#FF652E' strokeDasharray='56' fill='none'>
								<animate
									attributeType='XML'
									attributeName='stroke-dashoffset'
									from='0'
									to='56'
									dur={`5s`}
									repeatCount='1'
									fill='freeze'
									ref={animateRef}
								/>
							</circle>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
};

export default OnsiteNotification;
