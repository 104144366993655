import { IGameRoundParticipant } from '@features/gameplay/models/game-round-participant.model';
import { IWSGameplayCheckAnswer } from '@features/gameplay/models/gameplay-check-answer.model';
import { IGamePlayState } from '@features/gameplay/models/gameplay-state.model';
import {
	createGameRoundParticipantsWithoutCurrentPlayResult,
	createGameRoundParticipantsWithoutCurrentPoints,
	determineBalloonPoints,
	ensureCurrentParticipantIsActive,
	fillGameInfo,
	getApplicableWebsocketMessages,
	participantDuringQuestion,
	participantDuringQuestionResults,
	updateCurrentParticipant,
} from '@features/gameplay/services/gameInfoService';
import { determineGameState } from '@features/gameplay/utils/GameCurrentScreen';
import { IWSTournamentParticipantRegistered } from '@features/tournaments/models/tournament-event-participant-registered.model';
import { IWSTournamentStatusChanged } from '@features/tournaments/models/tournament-event-status.model';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IWSGameplayParticipantAnswered } from '../models/gameplay-participant-answered.model';
import { IWSGameplayPlayerJoined } from '../models/gameplay-player-join.model';
import { IWSGameplayPlayerLeft } from '../models/gameplay-player-leave.model';
import { IWSGameplayQuestionResults } from '../models/gameplay-question-results.model';
import { IWSGameplayStartGame } from '../models/gameplay-start-game.model';
import { IWSGameplayTournamentOutcome } from '../models/gameplay-tournament-outcome.model';
import { IWSQuestionResource } from '../models/question-resource-received.model';
import { localStorageLogger } from '../utils/DebugStorageTool';
import { fetchGameInfo, subscribeForPlayTopic } from './gameplay.actions';

export const gameRoundParticipantsAdapter = createEntityAdapter<IGameRoundParticipant>({
	selectId: (gameRoundParticipant) => gameRoundParticipant.id,
	sortComparer: (p1, p2) => p1.position - p2.position,
});

export const tournamentParticipantsAdapter = createEntityAdapter<
	Pick<IGameRoundParticipant, 'id' | 'customerNickname' | 'customerAvatarId' | 'customerId'>
>({
	selectId: (tournamentParticipants) => tournamentParticipants.id,
});

export const initialGameplayState: IGamePlayState = {
	currentScreen: 'UNDETERMINED',
	loadingStates: { joinGame: true },
	isOpened: false,
	totalGameRoundParticipants: 0,
	isGameInfoLoad: false,
	gameInfo: null,
	currentParticipant: null,
	socketMessageType: null,
	balloonPoints: null,
	bufferWsMessages: [],
	gameRoundParticipant: gameRoundParticipantsAdapter.getInitialState({ entities: {}, ids: [] }),
	tournamentParticipants: tournamentParticipantsAdapter.getInitialState({ entities: {}, ids: [] }),
	tournamentId: null,
	isInviteFriendsPlayNowVisible: true,
};

const gameplaySlice = createSlice({
	name: '[GAMEPLAY] - ',
	initialState: initialGameplayState,
	reducers: {
		unsubscribeForPlayTopic: (state) => {
			state.gameInfo = null;
			state.totalGameRoundParticipants = 0;
			state.currentParticipant = null;
			state.isOpened = false;
			state.socketMessageType = null;
			state.currentScreen = 'UNDETERMINED';
			state.bufferWsMessages = [];
			state.tournamentId = null;
			gameRoundParticipantsAdapter.removeAll(state.gameRoundParticipant);
			tournamentParticipantsAdapter.removeAll(state.tournamentParticipants);
			state.isInviteFriendsPlayNowVisible = true;
		},
		wsUpdateTournamentStatus: (state, { payload }: PayloadAction<IWSTournamentStatusChanged>) => {
			const tournamentIds = payload.data.tournaments.map((tournament) => tournament.id);
			if (state.tournamentId && tournamentIds.includes(state.tournamentId) && state.gameInfo) {
				if (payload.data.status !== 'FINISHED') {
					state.gameInfo.tournamentStatusId = payload.data.status;
				}
			}
		},
		setGameplaySocketIsOpened: (state, { payload }: PayloadAction<boolean>) => {
			state.isOpened = payload;
		},
		participantJoin: (state, { payload }: PayloadAction<IWSGameplayPlayerJoined>) => {
			let joinedParticipant = cloneDeep(state.gameRoundParticipant.entities[payload.data.id]);
			if (joinedParticipant) {
				gameRoundParticipantsAdapter.upsertOne(state.gameRoundParticipant, {
					...joinedParticipant,
					active: true,
				});
			}

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		participantLeft: (state, { payload }: PayloadAction<IWSGameplayPlayerLeft>) => {
			let changes: Pick<IGameRoundParticipant, 'active'> = {
				active: false,
			};

			gameRoundParticipantsAdapter.updateOne(state.gameRoundParticipant, {
				id: payload.data.id,
				changes,
			});

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		setQuestion: (state, { payload }: PayloadAction<IWSQuestionResource>) => {
			state.socketMessageType = payload.type;

			state.currentScreen = 'QUESTION';
			state.balloonPoints = null;

			if (!state.isGameInfoLoad) {
				state.bufferWsMessages.push(payload);
				return;
			}

			if (state.gameInfo !== null) {
				state.gameInfo.playNumber = state.gameInfo.playNumber + 1;
				state.gameInfo.currentGameRoundPlayResource = payload.data.currentGameRoundPlay;
				state.gameInfo.nextEventDateTime = payload.data.nextEventDateTime;
				state.gameInfo.questionResource = payload.data;
				state.gameInfo.results = null;
				state.gameInfo.correctAnswerId = null;
			}

			if (state.currentParticipant) {
				state.currentParticipant.currentPlayResult = null;
				state.currentParticipant.hasAnswered = false;
			}

			const participantWithNoHasAnswered = state.gameRoundParticipant.ids.map((id) => ({
				id,
				changes: { hasAnswered: false, currentPlayResult: null },
			}));

			gameRoundParticipantsAdapter.updateMany(state.gameRoundParticipant, participantWithNoHasAnswered);
			tournamentParticipantsAdapter.removeAll(state.tournamentParticipants);
			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		setSelectedAnswerId: (state, { payload }: PayloadAction<string | null>) => {
			if (state.currentParticipant) {
				state.currentParticipant.currentPlayResult = {
					answerId: payload,
					correct: null,
					points: null,
				};
			}
		},
		startGame: (state, { payload }: PayloadAction<IWSGameplayStartGame>) => {
			state.socketMessageType = payload.type;
			state.currentScreen = 'QUESTION';
			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		showPodium: (state, { payload }: PayloadAction<IWSGameplayTournamentOutcome>) => {
			state.socketMessageType = payload.type;
			state.currentScreen = 'PODIUM';

			if (state.currentParticipant?.currentPlayResult) {
				state.currentParticipant.hasAnswered = false;
				state.currentParticipant.points += state.currentParticipant.currentPlayResult.points || 0;
				state.currentParticipant.currentPlayResult = null;
			}

			gameRoundParticipantsAdapter.updateMany(
				state.gameRoundParticipant,
				state.gameRoundParticipant.ids.map((id) => ({
					id,
					changes: {
						hasAnswered: false,
						points:
							(state.gameRoundParticipant.entities[id]?.points || 0) +
							(state.gameRoundParticipant.entities[id]?.currentPlayResult?.points || 0),
						currentPlayResult: null,
					},
				}))
			);

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		showRanking: (state) => {
			state.currentScreen = 'RANKING';
		},
		participantAnswered: (state, { payload }: PayloadAction<IWSGameplayParticipantAnswered>) => {
			state.socketMessageType = payload.type;

			if (payload.data.id === state.currentParticipant?.id) {
				state.currentParticipant.hasAnswered = true;
			}
			gameRoundParticipantsAdapter.updateOne(state.gameRoundParticipant, {
				id: payload.data.id,
				changes: { hasAnswered: true, active: true },
			});

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		checkAnswer: (state, { payload }: PayloadAction<IWSGameplayCheckAnswer>) => {
			state.socketMessageType = payload.type;
			state.currentScreen = 'QUESTION';

			if (!state.isGameInfoLoad) {
				state.bufferWsMessages.push(payload);
				return;
			}

			if (state.gameInfo) {
				state.gameInfo.correctAnswerId = payload.data.correctAnswerId;
				state.gameInfo.currentGameRoundPlayResource = payload.data.currentGameRoundPlay;
				state.gameInfo.nextEventDateTime = payload.data.nextEventDateTime;
			}

			const updates = payload.data.answerResources.map(({ i: id, c: correct, p: points, n: position }) => ({
				id,
				changes: {
					currentPlayResult: {
						points,
						correct,
						answerId: null,
					},
					position,
				},
			}));
			const currentParticipantUpdate = updates.find(({ id }) => state.currentParticipant?.id === id);

			if (currentParticipantUpdate && state.currentParticipant) {
				state.currentParticipant = {
					...state.currentParticipant,
					currentPlayResult: {
						...currentParticipantUpdate.changes.currentPlayResult,
						answerId: state.currentParticipant.currentPlayResult?.answerId || null,
					},
					position: currentParticipantUpdate.changes.position,
				};
				state.balloonPoints = currentParticipantUpdate.changes.currentPlayResult.points;
			}

			gameRoundParticipantsAdapter.updateMany(state.gameRoundParticipant, updates);

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		setQuestionResults: (state, { payload }: PayloadAction<IWSGameplayQuestionResults>) => {
			state.socketMessageType = payload.type;
			state.currentScreen = 'QUESTION_RESULTS';

			if (!state.isGameInfoLoad) {
				state.bufferWsMessages.push(payload);
				return;
			}

			if (state.gameInfo) {
				state.gameInfo.results = payload.data;
				state.gameInfo.nextEventDateTime = payload.data.nextEventDateTime;
				state.gameInfo.currentGameRoundPlayResource = payload.data.currentGameRoundPlay;
				state.gameInfo.correctAnswerId = null;
			}

			localStorageLogger({ message: { messageType: payload.type, response: payload.data } });
		},
		wsLobbyParticipantsJoin: (state, { payload }: PayloadAction<IWSTournamentParticipantRegistered>) => {
			const { data } = payload;
			const { tournamentId, customerAvatarId, customerId, customerNickname, id } = data;

			const lobbyParticipant: Pick<
				IGameRoundParticipant,
				'id' | 'customerNickname' | 'customerAvatarId' | 'customerId'
			> = {
				customerAvatarId,
				customerId,
				customerNickname,
				id,
			};

			if (state.tournamentId === tournamentId) {
				tournamentParticipantsAdapter.addOne(state.tournamentParticipants, lobbyParticipant);
				state.totalGameRoundParticipants += 1;

				if (state.gameInfo) {
					state.gameInfo.participantsCount += 1;
				}
			}
		},
		calculatePoints: (state) => {
			if (state.currentParticipant?.currentPlayResult?.points) {
				state.currentParticipant.points += state.currentParticipant.currentPlayResult.points;
				state.balloonPoints = state.currentParticipant.currentPlayResult.points;
				state.currentParticipant.currentPlayResult.points = null;
			}

			const participantsForUpdate = Object.values(state.gameRoundParticipant.entities) as IGameRoundParticipant[];

			const participantWithCalculatedPoints = participantsForUpdate
				.filter(({ id, currentPlayResult }) => id && currentPlayResult)
				.map((participant) => ({
					id: participant.id,
					changes: {
						points: participant.points + (participant.currentPlayResult?.points || 0),
						currentPlayResult: participant.currentPlayResult
							? { ...participant.currentPlayResult, points: null }
							: null,
					},
				}));

			gameRoundParticipantsAdapter.updateMany(state.gameRoundParticipant, participantWithCalculatedPoints);
		},
		toggleIsInviteFriendsPlayNowVisible: (state, action: PayloadAction<boolean>) => {
			state.isInviteFriendsPlayNowVisible = action.payload;
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(subscribeForPlayTopic.fulfilled, (state, { payload }) => {
			state.tournamentId = payload.tournamentId;
		});
		addCase(fetchGameInfo.fulfilled, (state, { payload }) => {
			const { data } = payload;

			let stateParticipants;
			let currentParticipant: IGameRoundParticipant;

			const synchedState = getApplicableWebsocketMessages({
				gameInfo: data,
				messages: state.bufferWsMessages,
			});

			const activeStateFixed = ensureCurrentParticipantIsActive(synchedState.gameInfo);

			state.gameInfo = fillGameInfo({ data: synchedState.gameInfo });
			const { gameRoundParticipantScoreResource } = activeStateFixed;

			if (synchedState.gameInfo.tournamentStatusId === 'REGISTERING') {
				if (synchedState.gameInfo.tournamentParticipants) {
					tournamentParticipantsAdapter.addMany(
						state.tournamentParticipants,
						synchedState.gameInfo.tournamentParticipants as any
					);
				}
			}

			if (gameRoundParticipantScoreResource) {
				const { currentGameRoundParticipant, gameRoundParticipants } = gameRoundParticipantScoreResource;

				let balloonPoints = determineBalloonPoints(activeStateFixed);

				const currentScreen = determineGameState(synchedState.gameInfo);
				state.currentScreen = currentScreen;

				if (currentScreen === 'QUESTION_RESULTS') {
					currentParticipant = participantDuringQuestionResults(currentGameRoundParticipant);
					stateParticipants = createGameRoundParticipantsWithoutCurrentPoints(gameRoundParticipants.result);
				} else {
					currentParticipant = participantDuringQuestion(currentGameRoundParticipant);
					stateParticipants = createGameRoundParticipantsWithoutCurrentPlayResult(
						gameRoundParticipants.result
					);
				}

				gameRoundParticipantsAdapter.addMany(state.gameRoundParticipant, stateParticipants);

				if (synchedState.updateParticipantsScores) {
					gameRoundParticipantsAdapter.updateMany(
						state.gameRoundParticipant,
						synchedState.updateParticipantsScores
					);
				}

				const updateParticipantInfo = updateCurrentParticipant(
					currentParticipant,
					synchedState.updateParticipantsScores,
					balloonPoints
				);

				state.currentParticipant = updateParticipantInfo.currentParticipant;
				state.balloonPoints = updateParticipantInfo.balloonPoints;
				state.totalGameRoundParticipants = gameRoundParticipants.total;
			}

			state.loadingStates.joinGame = false;
			state.isGameInfoLoad = true;
			state.bufferWsMessages = [];
		});
	},
});
export const {
	unsubscribeForPlayTopic,
	participantJoin,
	participantLeft,
	startGame,
	setGameplaySocketIsOpened,
	checkAnswer,
	setQuestion,
	participantAnswered,
	setQuestionResults,
	setSelectedAnswerId,
	showPodium,
	showRanking,
	calculatePoints,
	wsLobbyParticipantsJoin,
	wsUpdateTournamentStatus,
	toggleIsInviteFriendsPlayNowVisible,
} = gameplaySlice.actions;
export const gameplayReducer = gameplaySlice.reducer;
