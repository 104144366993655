import React from 'react';

const IconEmail = () => {
	return (
		<svg width='30' height='22' viewBox='0 0 30 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M28.525 0.872598L15.25 11.7422L1.97499 0.872598C2.22878 0.776325 2.49778 0.726367 2.76922 0.725098H27.7308C28.0022 0.726367 28.2712 0.776325 28.525 0.872598ZM15.806 14.1813C15.8614 14.1513 15.9145 14.1172 15.9648 14.0792L30 2.6309V2.99397V18.8786C30 19.4804 29.7609 20.0576 29.3354 20.4832C28.9098 20.9087 28.3326 21.1478 27.7308 21.1478H2.76923C2.16739 21.1478 1.59021 20.9087 1.16464 20.4832C0.739079 20.0576 0.5 19.4804 0.5 18.8786V2.99397V2.6309L14.5352 14.0792C14.5855 14.1172 14.6386 14.1513 14.694 14.1813L14.8188 14.2494C14.9553 14.3075 15.1017 14.3383 15.25 14.3401C15.3983 14.3383 15.5447 14.3075 15.6812 14.2494L15.806 14.1813Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconEmail;
