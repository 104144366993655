import IconEmail from '@features/common/components/IconEmail';
import IconFacebookColored from '@features/common/components/IconFacebookColored';
import IconGoogleColored from '@features/common/components/IconGoogleColored';
import InformationVerificationLabel from '@features/common/components/InformationVerificationLabel';
import { useAppDispatch } from '@store/store';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { updateSwitchToPage } from '../store/auth.reducer';
import { getVerificationStatus } from '../store/auth.selectors';
import AuthFormWrapper from './AuthFormWrapper';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { showToast } from '@features/common/store/common.reducer';

const Register = () => {
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const router = useRouter();
	const status = useSelector(getVerificationStatus);

	const navigateToRegister = () => {
		dispatch(updateSwitchToPage('Continue With Email'));
	};

	const showError = useCallback(
		(query: typeof router.query) => {
			/* Should be returned when there is a way to navigate user to browser instead of opening pwa */

			// if (query.error === 'PROVIDER_ERROR') {
			// 	dispatch(
			// 		showToast({
			// 			type: 'warning',
			// 			title: formatMessage({ id: 'bcs-Common-Oops' }),
			// 			buttonText: formatMessage({ id: 'bcs-Common-GotIt' }),
			// 			redirect: '/register',
			// 			message: formatMessage({ id: 'bcs-Auth-ErrorSomethingWentWrong' }),
			// 		})
			// 	);
			// }

			if (query.error === 'PERMISSION_REQUIRED' || query.permission === 'EMAIL') {
				dispatch(
					showToast({
						type: 'warning',
						title: formatMessage({ id: 'bcs-Common-Oops' }),
						buttonText: formatMessage({ id: 'bcs-Common-GotIt' }),
						message: formatMessage({ id: 'bcs-Auth-ErrorPermissionEmail' }),
					})
				);
			}
		},
		[dispatch, formatMessage, router]
	);

	useEffect(() => {
		if (Object.keys(router.query).length) {
			showError(router.query);
		}
	}, [router, showError]);
	return (
		<AuthFormWrapper
			pathForBack='/'
			title={formatMessage({ id: 'bcs-Auth-CreateAccount' })}
			description={`${formatMessage({ id: 'bcs-Auth-AlreadyAUser' })}?`}
			link={{
				href: '/login',
				name: formatMessage({ id: 'bcs-Auth-LoginNow' }),
				testId: 'registerLoginLink',
			}}
		>
			<InformationVerificationLabel status={status} />
			<div className='grid grid-flow-row gap-2 text-white text-sm' style={{ fontFamily: "'Inter', sans-serif" }}>
				<a href='/api/v1/oauth2/authorization/facebook' id='facebook-register'>
					<div className='bg-dark-600 flex px-2 rounded-2xl h-14 items-center cursor-pointer'>
						<IconFacebookColored />
						<p className='flex-1 text-center'>{formatMessage({ id: 'bcs-Auth-ContinueWithFacebook' })}</p>
					</div>
				</a>

				<a href='/api/v1/oauth2/authorization/google' id='google-register'>
					<div
						className='bg-dark-600 flex rounded-2xl h-14 items-center cursor-pointer'
						style={{ padding: '0 10px' }}
					>
						<IconGoogleColored />
						<p className='flex-1 text-center'>{formatMessage({ id: 'bcs-Auth-ContinueWithGoogle' })}</p>
					</div>
				</a>
				{status !== 'Unknown User' ? (
					<Link href={'/register-email'} passHref>
						<a data-testid='signUpWithEmailLink'>
							<div className='bg-dark-600 flex px-4 rounded-2xl h-14 items-center cursor-pointer'>
								<IconEmail />
								<p className='flex-1 text-center'>
									{formatMessage({ id: 'bcs-Auth-ContinueWithEmail' })}
								</p>
							</div>
						</a>
					</Link>
				) : (
					<button
						className='bg-dark-600 flex px-4 rounded-2xl h-14 items-center cursor-pointer'
						onClick={navigateToRegister}
						data-testid='navigateRegisterBtn'
					>
						<IconEmail />
						<p className='flex-1 text-center'>{formatMessage({ id: 'bcs-Auth-ContinueWithEmail' })}</p>
					</button>
				)}

				<div className='text-black text-xs text-justify' style={{ fontFamily: "'Inter', sans-serif," }}>
					{formatMessage(
						{ id: 'bcs-Auth-T&C' },
						{
							'0': (
								<a href='/terms-and-conditions' target='_blank'>
									<span className='underline'>{formatMessage({ id: 'bcs-Auth-T&C-Link' })}</span>
								</a>
							),
							'1': (
								<a href='/privacy-policy' target='_blank'>
									<span className='underline'>{formatMessage({ id: 'bcs-Auth-PP-Link' })}</span>
								</a>
							),
						}
					)}
				</div>
			</div>
		</AuthFormWrapper>
	);
};

export default Register;
