import environmentBasedConfig from '@utils/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

interface IFacebookPixelProps {
	host: string;
}

const FacebookPixel = ({ host }: IFacebookPixelProps) => {
	const [facebookKey] = useState(environmentBasedConfig('FACEBOOK_PIXEL_KEY', host) as string);
	const router = useRouter();
	useEffect(() => {
		if (host === 'localhost:3000' || host === '127.0.0.1:3000') {
			return;
		}

		const handleRouteChange = () => {
			fbq('track', 'PageView');
		};

		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [host, router.asPath, router.events]);

	if (host === 'localhost:3000' || host === '127.0.0.1:3000') {
		return null;
	}

	return (
		<>
			<Script
				id='facebook-pixel'
				dangerouslySetInnerHTML={{
					__html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${facebookKey}');
  fbq('track', 'PageView');`,
				}}
			/>
		</>
	);
};

export default FacebookPixel;
