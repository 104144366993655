import IconBell from '@features/common/components/IconBell';
import IconEmptyPage from '@features/common/components/IconEmptyPage';
import ScrollView from '@features/common/components/ScrollView';
import ThreeDotMenu from '@features/common/components/ThreeDotMenu';
import { disableScroll, enableScroll } from '@features/common/store/common.reducer';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { fetchAllNotifications, readAllNotification } from '@features/notifications/store/notifications.actions';
import {
	getAllNotifications,
	getNotificationsIsLoading,
	getTotalNotifications,
	getUnreadNotificationCount,
} from '@features/notifications/store/notifications.selectors';
import { useAppDispatch } from '@store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { Router, useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { clearNotifications } from '../store/notifications.reducer';
import NotificationItem from './NotificationItem';

interface INotificationContainerProps {
	isNotificationMenuOpen: boolean;
}

const NotificationContainer = ({ isNotificationMenuOpen }: INotificationContainerProps) => {
	const dispatch = useAppDispatch();
	const [isOpened, setIsOpened] = useState(false);
	const unreadNotificationCount = useSelector(getUnreadNotificationCount);
	const isNotificationsLoading = useSelector(getNotificationsIsLoading);
	const isInitialFetchDispatchedRef = useRef(false);
	const initialSkipRef = useRef(0);
	const initialTakeRef = useRef(10);

	const fetchNotifications = useCallback(
		(skip: number, take: number) => {
			return dispatch(fetchAllNotifications({ skip, take, sort: [{ field: 'scheduledDate', desc: true }] }));
		},
		[dispatch]
	);

	useEffect(() => {
		setIsOpened(isNotificationMenuOpen);
	}, [isNotificationMenuOpen]);

	useEffect(() => {
		if (isOpened && !isNotificationsLoading && !isInitialFetchDispatchedRef.current) {
			dispatch(disableScroll());
			fetchNotifications(initialSkipRef.current, initialTakeRef.current);

			isInitialFetchDispatchedRef.current = true;
			initialSkipRef.current += initialTakeRef.current;
			document.body.classList.toggle('overflow-hidden');
		}
	}, [dispatch, fetchNotifications, isNotificationsLoading, isOpened]);

	useEffect(() => {
		if (!isOpened) {
			dispatch(clearNotifications());

			initialSkipRef.current = 0;
			initialTakeRef.current = 10;
			isInitialFetchDispatchedRef.current = false;
			if (document.body.classList.contains('overflow-hidden')) {
				document.body.classList.toggle('overflow-hidden');
			}
		}
	}, [dispatch, isOpened]);

	useEffect(() => {
		return () => {
			dispatch(enableScroll());
		};
	}, [dispatch]);

	useEffect(() => {
		const closeOnRouteChangeStart = () => setIsOpened(false);
		Router.events.on('routeChangeStart', closeOnRouteChangeStart);

		return () => {
			Router.events.off('routeChangeStart', closeOnRouteChangeStart);
		};
	});

	const formatNotificationCount = (count: number) => {
		if (count <= 9) {
			return count;
		}
		return '9+';
	};

	return (
		<>
			<AnimatePresence mode='wait'>
				{isOpened ? (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='hidden sm:block absolute top-0 left-0 w-full h-screen bg-black/30 z-40'
						onClick={() => {
							setIsOpened(false);
							dispatch(enableScroll());
						}}
					/>
				) : null}
			</AnimatePresence>
			<div className='sm:flex sm:items-center sm:w-auto h-full'>
				<div className='flex h-full sm:z-50 sm:relative'>
					<button
						type='button'
						onClick={() => {
							setIsOpened(!isOpened);
							if (isOpened) {
								dispatch(enableScroll());
							} else {
								dispatch(disableScroll());
							}
						}}
						className={`flex items-center focus:outline-none h-full`}
						data-testid='openNotificationsBtn'
						style={{ WebkitTapHighlightColor: 'transparent' }}
					>
						<div className={`w-11 h-11 rounded-full ${isOpened ? 'shadow-md' : ''}`}>
							<div
								className='flex flex-col h-full items-center justify-center relative z-50'
								style={{ WebkitTapHighlightColor: 'transparent' }}
							>
								<div
									className={`self-end absolute -top-1`}
									style={{ WebkitTapHighlightColor: 'transparent' }}
								>
									{unreadNotificationCount ? (
										<p
											className='text-center text-xs w-4 h-4 leading-4 rounded-full bg-razzmatazz-500 text-white'
											style={{ WebkitTapHighlightColor: 'transparent' }}
										>
											{formatNotificationCount(unreadNotificationCount)}
										</p>
									) : null}
								</div>
								<IconBell />
							</div>
						</div>
					</button>
					<AnimatePresence mode='wait'>
						{isOpened ? (
							<NotificationsList
								fetchNotifications={fetchNotifications}
								skip={initialSkipRef.current}
								take={initialTakeRef.current}
							/>
						) : null}
					</AnimatePresence>
				</div>
			</div>
		</>
	);
};

interface INotificationsListProps {
	fetchNotifications: (skip: number, take: number) => Promise<any>;
	skip: number;
	take: number;
}

const NotificationsList = (props: INotificationsListProps) => {
	const { fetchNotifications, skip, take } = props;
	const { formatMessage } = useIntl();
	const notifications = useSelector(getAllNotifications);
	const totalNotifications = useSelector(getTotalNotifications);
	const dispatch = useAppDispatch();
	const router = useRouter();

	const onMarkAllAsRead = () => {
		dispatch(readAllNotification());
	};

	const onNotificationSettingsClick = () => {
		router.push('/account-settings/notification');
		dispatch(enableScroll());
	};

	return createPortal(
		<motion.div
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -10 }}
			transition={{ duration: 0.2 }}
			className='fixed z-10 w-full sm:w-96 sm:pt-0 sm:left-auto inset-0 pt-18 sm:right-20 sm:mt-16 flex flex-col sm:z-50 bg-white overflow-hidden h-full sm:rounded-lg sm:h-5/6'
			data-testid='notificationListContainer'
		>
			<div className='flex justify-between px-4 min-h-[3rem] items-stretch  sm:items-center'>
				<div
					className='self-center font-bold uppercase not-italic text-dark-700 text-base tracking-wide'
					style={{ fontFamily: "'Lato', sans-serif" }}
				>
					{formatMessage({ id: 'bcs-Notification-Notification' })}
				</div>
				<ThreeDotMenu iconColor={'gray'}>
					<div className='flex flex-col'>
						<button
							className='w-full text-left hover:bg-gray-300 xs:h-8 mb-2 sm:px-2 sm:mb-0 transition-all'
							type='button'
							onClick={onMarkAllAsRead}
							data-testid='markAllAsReadBtn'
						>
							{formatMessage({ id: 'bcs-Notification-MarkAllAsRead' })}
						</button>
						<button
							className='w-full text-left hover:bg-gray-300 xs:h-8 sm:px-2 transition-all'
							type='button'
							onClick={onNotificationSettingsClick}
						>
							{formatMessage({ id: 'bcs-Notification-Settings-Container' })}
						</button>
					</div>
				</ThreeDotMenu>
			</div>
			{totalNotifications === null ? (
				<div className='w-full h-full flex flex-col justify-center items-center'>
					<LoadingIndicator contained={true} />
				</div>
			) : totalNotifications === 0 ? (
				<div className='flex flex-col justify-center items-center w-full h-full'>
					<div className='md:scale-50'>
						<IconEmptyPage />
					</div>
				</div>
			) : (
				<div className='flex-1'>
					<ScrollView
						asyncFetchProps={{
							fetchData: fetchNotifications,
							skip: skip,
							take: take,
							hasMoreData: notifications.length !== totalNotifications,
							loadingSize: 40,
						}}
					>
						{notifications.map((notification) => (
							<NotificationItem notification={notification} key={notification.id} />
						))}
					</ScrollView>
				</div>
			)}
		</motion.div>,
		document.body
	);
};

export default NotificationContainer;
