import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';

interface IFooterInfoProps {
	titleTranslationKey: string;
	items: { translationKey: string; href: string }[];
	titleColor?: string;
}

const FooterInfo = ({ titleTranslationKey, titleColor, items }: IFooterInfoProps) => {
	const { formatMessage } = useIntl();

	return (
		<div className='mt-4'>
			<p
				className={'font-semibold mb-3'}
				style={{ color: titleColor, fontFamily: "Roboto", fontSize: '15px' }}
				data-testid='footerInfoTitle'
			>
				{formatMessage({ id: titleTranslationKey })}
			</p>
			<ul>
				{items.map((item) => (
					<li
						className='font-medium mb-2'
						style={{ fontFamily: "Roboto", fontSize: '13px' }}
						key={item.translationKey}
					>
						<Link href={item.href}>{formatMessage({ id: item.translationKey })}</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default FooterInfo;
