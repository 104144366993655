import environmentBasedConfig from "@utils/config";

export interface IDebugMessage {
	date: string;
	message: any;
}

export const localStorageClear = () => {
	if (localStorage.length > 0) {
		localStorage.removeItem('debugLog');
	}
};

export const localStorageLogger = (message: Pick<IDebugMessage, 'message'>) => {
	const localStorageLoggerEnabled = environmentBasedConfig('LOCAL_STORAGE_LOGGER', window.location.host);
	if (localStorageLoggerEnabled) {
		try {
			const log = localStorage.getItem('debugLog');
			const logArray: IDebugMessage[] = log ? JSON.parse(log) : [];
			logArray.push(Object.assign(message, { date: new Date().toISOString() }));
			localStorage.setItem('debugLog', JSON.stringify(logArray));
		} catch (error) {
			console.log(error);
		}
	}
};
