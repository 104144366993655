import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconCloseModal from './IconCloseModal';
import { nanoid } from '@reduxjs/toolkit';
import { togglePlayNowModal } from '../store/common.reducer';
import { useAppDispatch } from '@store/store';
import { getIsPlayNowModal } from '../store/common.selectors';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useRouter } from 'next/router';
const modalAlert = withReactContent(Swal);

const PlayNowModal = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const router = useRouter();
	const showModal = useSelector(getIsPlayNowModal);
	const closeModalIconKeys = useRef({ static: nanoid(), animated: nanoid() });

	const closeModal = useCallback(() => {
		dispatch(togglePlayNowModal({ id: '', isPreviouslyCreated: false }));
		modalAlert.close();
	}, [dispatch]);

	const onCloseClick = useCallback(() => {
		closeModal();
	}, [closeModal]);

	const onContinueClick = useCallback(() => {
		closeModal();
		router.replace(`/tournaments/${showModal?.id}/play`).then(() => router.reload());
	}, [closeModal, router, showModal]);

	const onGoToMyGamys = useCallback(() => {
		closeModal();
		router.push('/my-tournaments/registered');
	}, [closeModal, router]);

	const modal = useCallback(() => {
		modalAlert.fire({
			html: (
				<>
					<div className='flex justify-between items-center'>
						<p
							style={{
								fontFamily: 'Montserrat',
								fontWeight: '600',
								fontSize: '26px',
								lineHeight: '25px',
								color: '#1B1D21',
							}}
						>
							{formatMessage({ id: 'bcs-Play-Now-Copy-Already-Started' })}
						</p>

						<button type='button' data-testid='closeButtonRegistrationModal' onClick={onCloseClick}>
							<IconCloseModal key={closeModalIconKeys.current.static} />
						</button>
					</div>
					<div
						className='text-lg font-medium text-center text-dark-600 sm:mt-[14px] mt-[43px] mb-13'
						style={{ fontFamily: 'Roboto' }}
					>
						<p>{formatMessage({ id: 'bcs-Play-Now-Copy-Top-Description' })}</p>
						<p>{formatMessage({ id: 'bcs-Play-Now-Copy-Bottom-Description' })}</p>
					</div>

					<div className='flex justify-center sm:mb-[35px] mb-[48px] sm:flex-row flex-col'>
						<button
							className={classNames(
								'bg-main-500 sm:mr-[13px] sm:mb-0 mb-5 disabled:bg-disabledMain-500 border border-transparent pointer-events-auto disabled:pointer-events-none text-base text-white font-bold rounded-2xl focus:outline-none outline-none transition-all',
								{
									'hover:bg-main-600': !isMobile,
									'active:bg-main-500': true,
								}
							)}
							onClick={onContinueClick}
							data-testid={'continueCurrent'}
							tabIndex={2}
							style={{ padding: '18px 30px', fontFamily: "'Inter', sans-serif" }}
						>
							{formatMessage({ id: 'bcs-Play-Now-Continue-Current-Game' })}
						</button>
						<button
							className='items-center sm:mb-0 bg-brightGray-200 rounded-2xl focus:outline-none font-bold not-italic text-headerColor-100'
							onClick={onGoToMyGamys}
							tabIndex={3}
							data-testid={'goToMyGamys'}
							style={{ padding: '18px 30px' }}
						>
							{formatMessage({ id: 'bcs-Play-Now-Go-To-My-Gamys' })}
						</button>
					</div>
				</>
			),
			showCancelButton: false,
			showCloseButton: false,
			showDenyButton: false,
			heightAuto: false,
			timer: undefined,
			position: 'center',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			width: '684px',
			customClass: {
				popup: 'alert-border-radius mt-[4%] !min-h-[200px]',
			},
			padding: '0px 0px',
		});
	}, [formatMessage, onCloseClick, onContinueClick, onGoToMyGamys]);

	useEffect(() => {
		if (showModal?.isPreviouslyCreated === true) {
			modal();
		}
	}, [modal, showModal]);

	return null;
};

export default PlayNowModal;
