import { fetchCurrentUser } from '@features/auth/store/auth.actions';
import { getAuthIsLoading, getAuthStateDetermined, getCurrentUser } from '@features/auth/store/auth.selectors';
import CookiePolicyPopup from '@features/common/components/CookiePolicyPopup';
import RegistrationNavigationModal from '@features/common/components/RegistrationNavigationModal';
import Toast from '@features/common/components/Toast';
import {
	getCommonIsLoading,
	getCommonToast,
	getIsSocialLoginNotificationsModal,
	getShowScrollbar,
} from '@features/common/store/common.selectors';
import { fetchUnreadCountNotifications } from '@features/notifications/store/notifications.actions';
import OnsiteNotificationsContainer from '@features/onsiteNotifications/components/OnsiteNotificationsContainer';
import { openNotificationsSocket } from '@features/onsiteNotifications/store/onsiteNotifications.actions';
import useListenReduxActions from '@hooks/useListenReduxActions';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames';
import { Router, useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCookie, removeCookie, setCookie } from 'tiny-cookie';
import Footer from './Footer';
import Header from './Header';
import LayoutScrollToTop from './LayoutScrollToTop';
import LoadingIndicator from './LoadingIndicator';
import PlayNowModal from '@features/common/components/PlayNowModal';

interface ILayoutProps {
	children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(getCurrentUser);
	const isCommonLoading = useSelector(getCommonIsLoading);
	const toast = useSelector(getCommonToast);
	const isSocialLoginNotificationsModal = useSelector(getIsSocialLoginNotificationsModal);
	const showScrollbar = useSelector(getShowScrollbar);
	const isAuthLoading = useSelector(getAuthIsLoading('fetchCurrentUser'));
	const getAuthStateReady = useSelector(getAuthStateDetermined);
	const router = useRouter();
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [blackLoadingState, setBlackLoadingState] = useState({
		mobile: true,
		desktop: true,
	});
	const fullScreenPaths = useRef([
		'login',
		'login-email',
		'register',
		'register-email',
		'forgot-password',
		'verification-email',
		'play',
		'reset-password',
		'set-password',
	]);

	const blackLoadingPaths = useRef([...fullScreenPaths.current]);
	const blackMobileLoadingPats = useRef(['/play']);
	const isInitialRouteLoaded = useRef<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const onRouteLoad = (newPath: string) => {
		setIsFullscreen(
			fullScreenPaths.current.some(
				(fullScreenPath) => newPath.split('/').pop()?.split('?').shift() === fullScreenPath
			)
		);

		setBlackLoadingState({
			desktop: blackLoadingPaths.current.some((fullScreenPath) => newPath.split('/').pop() === fullScreenPath),
			mobile: blackMobileLoadingPats.current.some(
				(fullScreenPath) => newPath.split('/').pop() === fullScreenPath
			),
		});
	};

	useEffect(() => {
		if (isFullscreen) {
			document.body.classList.add('body-is-fullscreen');
		} else {
			document.body.classList.remove('body-is-fullscreen');
		}
	}, [isFullscreen]);

	useEffect(() => {
		dispatch(fetchCurrentUser());
	}, [dispatch]);

	useListenReduxActions((action) => {
		if (action.type === fetchCurrentUser.fulfilled.type) {
			dispatch(fetchUnreadCountNotifications());
			dispatch(openNotificationsSocket());
		}
	});

	useEffect(() => {
		if (!isInitialRouteLoaded.current) {
			isInitialRouteLoaded.current = true;
			onRouteLoad(router.pathname);
		}
	}, [router.pathname]);

	useEffect(() => {
		if (!getAuthStateReady) {
			return;
		}
		const skipHomePageWithToken = !!(router.pathname === '/' && router.query.token);

		if (skipHomePageWithToken) {
			return;
		}

		const authRoutes = [
			'/login',
			'/login-email',
			'/register-email',
			'/register',
			'/change-password',
			'/forgot-password',
			'/verification-email',
		];
		if (!currentUser && !authRoutes.includes(router.asPath)) {
			setCookie('beforeAuthenticationUrl', router.asPath);
		}

		const cookie = getCookie('beforeAuthenticationUrl');
		if (!currentUser || !cookie) {
			return;
		}
		if (cookie != router.asPath) {
			router.events.emit('routeChangeError');
			router.replace(cookie).then(() => removeCookie('beforeAuthenticationUrl'));
		} else {
			removeCookie('beforeAuthenticationUrl');
		}
	}, [currentUser, getAuthStateReady, router]);

	useEffect(() => {
		const onRouteChangeStart = (newPath: string) => {
			onRouteLoad(newPath);
		};

		const onRouteChangeComplete = () => {
			containerRef.current?.scroll(0, 0);
		};

		Router.events.on('routeChangeStart', onRouteChangeStart);
		Router.events.on('routeChangeComplete', onRouteChangeComplete);

		return () => {
			Router.events.off('routeChangeStart', onRouteChangeStart);
			Router.events.off('routeChangeComplete', onRouteChangeComplete);
		};
	}, []);

	useEffect(() => {
		const toggleOnRouteChange = () => {
			if (document.body.classList.contains('overflow-hidden')) {
				document.body.classList.toggle('overflow-hidden');
			}
		};

		Router.events.on('routeChangeStart', toggleOnRouteChange);

		return () => {
			Router.events.off('routeChangeStart', toggleOnRouteChange);
		};
	}, []);

	return (
		<div
			className={classNames(`w-full min-h-full`, {
				'fixed overflow-auto h-full': isFullscreen,
				'blur-sm': isSocialLoginNotificationsModal,
			})}
		>
			<CookiePolicyPopup />
			{!isFullscreen && getAuthStateReady ? <Header /> : null}
			<div
				id={'contentWrap'}
				className={classNames(`flex flex-col h-full justify-between`, {
					'overflow-y-hidden sm:overflow-y-visible': !showScrollbar,
				})}
				ref={containerRef}
			>
				{isCommonLoading || isAuthLoading || !getAuthStateReady ? (
					<>
						<LoadingIndicator
							className={`hidden md:flex ${blackLoadingState.desktop ? 'bg-black' : 'bg-white'}`}
						/>
						<LoadingIndicator
							className={`md:hidden ${blackLoadingState.mobile ? 'bg-black' : 'bg-white'}`}
						/>
					</>
				) : (
					<>
						{children}
						<LayoutScrollToTop />
						{currentUser ? <OnsiteNotificationsContainer /> : null}
						<RegistrationNavigationModal />
						<PlayNowModal />
					</>
				)}
				{!isFullscreen && getAuthStateReady ? <Footer /> : null}
			</div>

			{toast ? <Toast toast={toast} /> : null}
		</div>
	);
};

export default Layout;
