export enum TournamentsActionTypes {
	FETCH_TOURNAMENTS = '[TOURNAMENTS] - Fetch tournaments',
	FETCH_TOURNAMENT = '[TOURNAMENTS] - Fetch tournament',
	FETCH_REGISTERED_TOURNAMENTS = '[TOURNAMENTS] - Fetch registered tournaments',
	FETCH_PRIZED_TOURNAMENTS = '[TOURNAMENTS] - Fetch prized tournaments',
	REGISTER_FOR_TOURNAMENT = '[TOURNAMENTS] - Register for tournament',
	FETCH_MERCHANT_TOURNAMENTS = '[TOURNAMENTS] - Fetch merchant tournaments',
	FETCH_FINISHED_MERCHANT_TOURNAMENTS = '[TOURNAMENTS] - Fetch finished merchant tournaments',
	CODE_FOR_RESTRICTED_TOURNAMENT = '[TOURNAMENTS] - Code for Restricted Tournament',
	FETCH_HOME_TOURNAMENTS = '[TOURNAMENTS] - Fetch home tournaments',
	FETCH_TOURNAMENT_BY_CHAMPIONSHIPS = '[TOURNAMENTS]- Fetch tournaments by championships id',
	FETCH_ENDED_TOURNAMENT_BY_CHAMPIONSHIPS = '[TOURNAMENTS]- Fetch ended tournaments by championships id',
	TOURNAMENT_PARTICIPANT_REGISTERED = '[TOURNAMENTS]- Participant registered',
}
