import { ILayoutState } from '@features/layout/models/layout-state.model';
import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';

const layoutSelector = ({ layout }: IApplicationState): ILayoutState => layout;

export const getSelectedLanguage = createSelector(layoutSelector, ({ selectedLanguage }) =>
	selectedLanguage.toUpperCase()
);

export const getNavOpen = createSelector(layoutSelector, ({ navOpen }) => navOpen);
