import React from 'react';

function IconLanguage() {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='#3C3C3C' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10 0C4.48583 0 0 4.48594 0 10C0 15.5142 4.48594 20 10 20C15.5141 20 20 15.5141 20 10C20 4.48583 15.5139 0 10 0ZM5.32745 10C5.32745 8.75719 5.47843 7.56831 5.74243 6.48615H9.39544V13.514H5.74243C5.47843 12.4318 5.32745 11.243 5.32745 10.0001V10ZM10.6045 1.3641C11.9663 1.71013 13.1628 3.19525 13.8995 5.27665H10.6045V1.3641ZM9.39547 1.3641V5.27665H6.10051C6.83709 3.1952 8.03368 1.71012 9.39547 1.3641ZM9.39547 14.7228V18.6354C8.03368 18.2893 6.83725 16.8042 6.10051 14.7228H9.39547ZM10.6045 18.6356V14.7228H13.8995C13.1628 16.8044 11.9663 18.2896 10.6045 18.6356ZM10.6045 13.5137V6.48588H14.2575C14.5215 7.56804 14.6725 8.75687 14.6725 9.99973C14.6725 11.2425 14.5215 12.4314 14.2575 13.5136L10.6045 13.5137ZM15.5088 6.48588H18.0531C18.525 7.56318 18.7909 8.75034 18.7909 9.99973C18.7909 11.2491 18.525 12.4363 18.0531 13.5136H15.5088C15.7463 12.4242 15.8816 11.2449 15.8816 9.99973C15.8816 8.7546 15.7463 7.57501 15.5088 6.48588ZM17.4024 5.2766H15.1953C14.7739 3.9336 14.1732 2.78947 13.4467 1.91417C15.0797 2.61295 16.4539 3.79552 17.4024 5.2766ZM6.55325 1.91417C5.82683 2.78947 5.22629 3.9336 4.80475 5.2766H2.59763C3.54607 3.79547 4.92027 2.61295 6.5532 1.91417H6.55325ZM1.94694 6.48588H4.49123C4.2538 7.57522 4.11842 8.75455 4.11842 9.99973C4.11842 11.2449 4.2538 12.4245 4.49123 13.5136H1.94694C1.47502 12.4363 1.20912 11.2491 1.20912 9.99973C1.20891 8.75017 1.47481 7.56318 1.94694 6.48588ZM2.59768 14.7229H4.80481C5.22611 16.0659 5.82688 17.21 6.55331 18.0853C4.92038 17.3865 3.54618 16.2039 2.59773 14.7229H2.59768ZM13.4468 18.0853C14.1732 17.21 14.7738 16.0659 15.1953 14.7229H17.4024C16.454 16.204 15.0798 17.3865 13.4469 18.0853H13.4468Z'
				fill='#3C3C3C'
			/>
		</svg>
	);
}

export default IconLanguage;
