import React, { ReactNode } from 'react';

interface ISecondaryButtonProps {
	text?: string;
	tabIndex: number;
	onClick?: () => void;
	disabled?: boolean;
	type?: 'submit' | 'reset' | 'button';
	testid?: string;
	children?: ReactNode;
}

const SecondaryButton = (props: ISecondaryButtonProps) => {
	const { onClick, text, tabIndex, disabled, type, testid } = props;

	return (
		<button
			className='w-full items-center bg-brightGray-200 rounded-2xl focus:outline-none font-bold not-italic text-headerColor-100'
			onClick={onClick}
			type={type}
			tabIndex={tabIndex}
			disabled={disabled}
			data-testid={testid}
			style={{ padding: '18px 0', fontFamily: 'Roboto', fontSize: '15px', lineHeight: '20px' }}
		>
			{text || props.children}
		</button>
	);
};

export default SecondaryButton;
