import { useEffect, useRef, useState } from 'react';

interface IUseScrollDirectionProps {
	triggerDistancePercentage: number;
}

const useScrollDirection = (props?: IUseScrollDirectionProps) => {
	const triggerDistancePercentageRef = useRef(props?.triggerDistancePercentage || 0);
	const [scrollDirection, setScrollDirection] = useState<'UP' | 'DOWN' | null>(null);
	const lastOffsetRef = useRef(0);

	useEffect(() => {
		const onScroll = () => {
			const { scrollY, innerHeight } = window;
			const passedScrollPosition = scrollY >= innerHeight * (triggerDistancePercentageRef.current / 100);

			if (!passedScrollPosition) {
				setScrollDirection(null);
				return;
			}

			if (lastOffsetRef.current > scrollY && scrollDirection !== 'UP') {
				setScrollDirection('UP');
			}

			if (lastOffsetRef.current < scrollY && scrollDirection !== 'DOWN') {
				setScrollDirection('DOWN');
			}

			lastOffsetRef.current = scrollY;
		};

		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [scrollDirection]);

	return scrollDirection;
};

export default useScrollDirection;
