import { cloneDeep } from 'lodash';
import { IGameRoundParticipant } from '../models/game-round-participant.model';
import { IGameplayJoinGameResponse } from '../models/gameplay-join-game.model';
import { IGamePlayState } from '../models/gameplay-state.model';
import { IGetApplicableWebsocketMessagesReturnType } from './gameInfoService';

const moment = require('moment');

export const parseDate = (date: string): any => {
	return moment(date, 'YYYY-MM-DD hh:mm:ss ZZ').format('x');
};

export const filterByDatesMessages = (
	messages: IGamePlayState['bufferWsMessages'],
	gameInfo: IGameplayJoinGameResponse
): IGamePlayState['bufferWsMessages'] => {
	return messages.filter((message) => {
		const lastModifiedDateGameInfo = parseDate(gameInfo.currentGameRoundPlayResource!?.lastModifiedDate);
		const lastModifiedDateWs = parseDate(message.data.currentGameRoundPlay.lastModifiedDate);

		return lastModifiedDateWs > lastModifiedDateGameInfo;
	});
};

export const sortByWsLastModifiedDate = (
	messages: IGamePlayState['bufferWsMessages']
): IGamePlayState['bufferWsMessages'] => {
	return [...messages].sort((a, b) => {
		const aLastModifiedDate = parseDate(a.data.currentGameRoundPlay.lastModifiedDate);
		const bLastModifiedDate = parseDate(b.data.currentGameRoundPlay.lastModifiedDate);

		return parseDate(aLastModifiedDate) - parseDate(bLastModifiedDate);
	});
};

export const getWSLastMessage = (messages: IGamePlayState['bufferWsMessages']) => {
	if (messages.length === 0) {
		return null;
	}

	return messages.reduce((maxDateObj, currentObj) => {
		if (
			currentObj.data.currentGameRoundPlay.lastModifiedDate >
			maxDateObj.data.currentGameRoundPlay.lastModifiedDate
		) {
			return currentObj;
		} else {
			return maxDateObj;
		}
	});
};

export const applyWsMessageToGameInfo = (
	wsMessage: IGamePlayState['bufferWsMessages'][number],
	gameInfo: IGameplayJoinGameResponse
): IGetApplicableWebsocketMessagesReturnType => {
	const result: IGetApplicableWebsocketMessagesReturnType = {
		gameInfo: cloneDeep(gameInfo),
		updateParticipantsScores: null,
	};

	switch (wsMessage.type) {
		case 'QUESTION_SENT':
			const questionContent = wsMessage.data;
			result.gameInfo.nextEventDateTime = questionContent.nextEventDateTime;
			result.gameInfo.currentGameRoundPlayResource = questionContent.currentGameRoundPlay;
			result.gameInfo.questionResource = questionContent;
			result.gameInfo.playNumber = gameInfo.playNumber + 1;
			result.gameInfo.results = null;
			result.gameInfo.correctAnswerId = null;
			if (result.gameInfo.gameRoundParticipantScoreResource) {
				result.gameInfo.gameRoundParticipantScoreResource.currentGameRoundParticipant.currentPlayResult = null;
				result.gameInfo.gameRoundParticipantScoreResource.currentGameRoundParticipant.hasAnswered = false;

				result.updateParticipantsScores =
					result.gameInfo.gameRoundParticipantScoreResource.gameRoundParticipants.result.map(
						(participant) => ({
							id: participant.id,
							changes: { hasAnswered: false, currentPlayResult: null },
						})
					);
			}

			break;

		case 'CHECK_ANSWER':
			const checkAnswerContent = wsMessage.data;
			result.gameInfo.nextEventDateTime = checkAnswerContent.nextEventDateTime;
			result.gameInfo.currentGameRoundPlayResource = checkAnswerContent.currentGameRoundPlay;
			result.gameInfo.correctAnswerId = checkAnswerContent.correctAnswerId;
			result.updateParticipantsScores = checkAnswerContent.answerResources.map(
				({ i: id, c: correct, p: points }) => ({
					id,
					changes: {
						currentPlayResult: {
							points,
							correct,
							answerId: null,
						},
					},
				})
			);

			break;

		case 'QUESTION_RESULTS':
			const questionResultContent = wsMessage.data;
			result.gameInfo.nextEventDateTime = questionResultContent.nextEventDateTime;
			result.gameInfo.currentGameRoundPlayResource = questionResultContent.currentGameRoundPlay;
			result.gameInfo.correctAnswerId = null;
			result.gameInfo.results = questionResultContent;

			break;
		default:
			break;
	}

	return result;
};
