export enum ChampionshipsActionTypes {
	FETCH_CHAMPIONSHIPS = '[CHAMPIONSHIPS] - Fetch championships',
	FETCH_CHAMPIONSHIPS_PLAY_NOW = '[CHAMPIONSHIPS] - Fetch championships play now',
	FETCH_CHAMPIONSHIPS_PLAY_NOW_AWARD = '[CHAMPIONSHIPS] - Fetch championships play now awards',
	FETCH_CHAMPIONSHIPS_BY_ID = '[CHAMPIONSHIPS] - Fetch championship by id',
	FETCH_CHAMPIONSHIPS_ONE_TIME_BY_MERCHANT_ID = '[CHAMPIONSHIPS] - Fetch one time championships by merchant id',
	FETCH_CHAMPIONSHIPS_PLAY_NOW_ALL_BY_MERCHANT_ID = '[CHAMPIONSHIPS] - Fetch play now all by merchant id',
	FETCH_CHAMPIONSHIP_ENDED_GAMYS = '[CHAMPIONSHIPS] - Fetch championships ended gamys',
	FETCH_FINISHED_CHAMPIONSHIPS_ONE_TIME_BY_MERCHANT_ID = '[CHAMPIONSHIPS] - Fetch finished one time championships by merchant id',
	FETCH_FINISHED_CHAMPIONSHIPS_PLAY_NOW_ALL_BY_MERCHANT_ID = '[CHAMPIONSHIPS] - Fetch finished play now all by merchant id',
	UPDATE_CHAMPIONSHIP_FINISHED_GAMYS = '[CHAMPIONSHIPS] - Update championship finished gamys',
	START_PLAY_NOW_CHAMPIONSHIP = '[CHAMPIONSHIPS] - Start play now championship',
	UPDATE_TOURNAMENT_FINISH_GAMES = '[CHAMPIONSHIPS] - Update championship finished gamys in homepage',
}
