import React, { useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import IconEye from './IconEye';
import InputContainer from './InputContainer';
import InputMessage from './InputMessage';
import InputLabel from './InputLabel';

type IInputText = {
	testId: string;
	label: string;
	id: string;
	type: 'text' | 'password' | 'email';
	placeholder: string;
	tabIndex: number;
	hookRegister: UseFormRegisterReturn;
	fieldError: FieldError | undefined;
	limitLength?: number;
	hint?: string;
	disabled?: boolean;
	defaultValue?: string;
};

const InputText = (props: IInputText) => {
	const {
		id,
		label,
		type,
		testId,
		fieldError,
		placeholder,
		tabIndex,
		hookRegister,
		hint,
		disabled,
		defaultValue,
		limitLength,
	} = props;
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [localValue, setLocalValue] = useState('');

	const togglePasswordVisible = () => {
		setIsPasswordVisible((prevState) => !prevState);
	};
	const getPasswordVisibleIconColor = () => {
		if (isPasswordVisible) {
			return '#FF652E';
		}
		return '';
	};

	return (
		<InputContainer>
			<InputLabel inputId={id} inputTestId={testId} label={label} />
			<div className='relative'>
				<input
					className={`${fieldError ? 'input-error' : 'input-valid'} disabled:text-dark-300 disabled:opacity-100`}
					id={id}
					defaultValue={defaultValue}
					type={isPasswordVisible ? 'text' : type}
					data-testid={testId}
					placeholder={placeholder}
					tabIndex={tabIndex}
					style={{
						padding: '17px 23px',
						paddingRight: type === 'password' ? '47px' : '23px',
					}}
					disabled={disabled}
					{...hookRegister}
					value={typeof limitLength !== 'number' ? undefined : localValue}
					onChange={(event) => {
						if (typeof limitLength === 'number') {
							if (event.target.value.length <= limitLength) {
								setLocalValue(event.target.value);
								hookRegister.onChange(event);
							}
						} else {
							hookRegister.onChange(event);
						}
					}}
				/>
				{type === 'password' ? (
					<span className='absolute right-0 grid place-items-center' style={{ right: '14px', top: '21px' }}>
						<button
							onClick={togglePasswordVisible}
							className='focus:outline-none'
							type='button'
							data-testid='viewPasswordBtn'
						>
							<IconEye fillHexColor={getPasswordVisibleIconColor()} />
						</button>
					</span>
				) : null}
			</div>
			<InputMessage inputTestId={testId} fieldError={fieldError} inputHint={hint} />
		</InputContainer>
	);
};

export default InputText;
