import ColoredLine from '@features/common/components/ColoredLine';
import IconCloseModal from '@features/common/components/IconCloseModal';
import IconDirectionLeft from '@features/common/components/IconDirectionLeft';
import IconGamyLogoWhite from '@features/common/components/IconGamyLogoWhite';
import { getCdnStaticUrl, getShowScrollbar } from '@features/common/store/common.selectors';
import Header from '@features/layout/components/Header';
import HeaderLanguage from '@features/layout/components/HeaderLanguage';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateSwitchToPage, updateVerificationStatus } from '../store/auth.reducer';
import { getSwitchToPage, getVerificationStatus } from '../store/auth.selectors';

interface IAuthFormWrapper {
	children: ReactNode;
	title: string;
	pathForBack: string;
	description?: string;
	link?: {
		name: string;
		href: string;
		testId: string;
	};
}

const AuthFormWrapper = (props: IAuthFormWrapper) => {
	const { title, pathForBack, description, link, children } = props;
	const router = useRouter();
	const dispatch = useAppDispatch();
	const showScrollbar = useSelector(getShowScrollbar);
	const cdnStaticUrl = useSelector(getCdnStaticUrl);
	const status = useSelector(getVerificationStatus);
	const navigateToPage = useSelector(getSwitchToPage);
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const navigateToPathForBack = () => {
		router.push(pathForBack);
	};

	const navigateToNextContent = () => {
		if (navigateToPage) {
			if (link?.href === '/register') {
				dispatch(updateSwitchToPage('Create Account'));
			} else {
				dispatch(updateSwitchToPage('Log In'));
			}
		}
	};

	const onCloseClick = () => {
		dispatch(updateSwitchToPage(null));
		dispatch(updateVerificationStatus(null));
		if (document.body.classList.contains('overflow-hidden')) {
			document.body.classList.toggle('overflow-hidden');
		}
	};

	return (
		<>
			{!navigateToPage ? (
				<div
					className={`hidden sm:block sm:h-full sm:w-full ${isImageLoaded ? '' : 'preloader'}`}
					style={{
						filter: 'brightness(50%)',
					}}
				>
					<Image
						alt='auth-background-image'
						unoptimized={true}
						src={`${cdnStaticUrl}/authBackground.jpg`}
						layout='fill'
						className='object-cover'
						priority={true}
						onLoad={() => setIsImageLoaded(true)}
					/>
				</div>
			) : null}

			<div
				className={classNames('sm:absolute sm:inset-0 flex flex-col h-full ', {
					'sm:px-0 px-4 py-4 w-full': navigateToPage,
					'sm:overflow-auto': !navigateToPage,
				})}
			>
				{!navigateToPage ? (
					<>
						<ColoredLine className='hidden sm:grid' />
						<div
							className='hidden sm:ml-7 sm:flex justify-items-center items-center'
							style={{ minHeight: '66px' }}
						>
							<Link href={'/'}>
								<div className='cursor-pointer'>
									<IconGamyLogoWhite />
								</div>
							</Link>
						</div>
						<div className='sm:hidden'>
							<Header />
						</div>
					</>
				) : null}

				{showScrollbar ? (
					<div
						className={classNames('flex-1 flex justify-center items-center w-full mt-18 md:mt-0', {
							'sm:pb-28': !navigateToPage,
						})}
					>
						<div
							className={classNames(
								'bg-white px-3 sm:px-0 py-3 sm:pt-0 h-full min-w-full sm:min-w-0 md:w-1/2 sm:mt-0',
								{
									'sm:w-96 sm:max-w-md sm:h-auto': !navigateToPage,
									'sm-w-96 sm:max-w-[480px] 2xl:h-auto h-[530px] min-h-[530px]': navigateToPage,
								}
							)}
							style={{ borderRadius: '40px' }}
						>
							<div
								className={classNames('flex flex-col sm:mt-0', {
									relative: navigateToPage,
								})}
							>
								{!navigateToPage ? (
									<div className='flex justify-between items-center mr-6 mt-3 sm:ml-3'>
										<button
											className='focus:outline-none mt-3 sm:ml-3 place-self-start'
											type='button'
											data-testid='directionBack'
											onClick={navigateToPathForBack}
										>
											<IconDirectionLeft />
										</button>
										<div className='mt-4 sm:ml-4 hidden sm:block relative'>
											<HeaderLanguage />
										</div>
									</div>
								) : null}

								<div className='mx-4 sm:mx-10'>
									<div className='flex mt-7 justify-between'>
										<p
											className='font-semibold text-dark-500 text-3xl s:text-4xl sm:text-4.5xl md:text-3xl lg:text-4.5xl'
											style={{ fontFamily: "'Poppins', sans-serif", letterSpacing: '-1px' }}
										>
											{title}
										</p>
										{navigateToPage ? (
											<button
												type='button'
												data-testid='closeButtonModal'
												onClick={onCloseClick}
												className='ml-4 absolute'
												style={{ right: '10px', top: '10px' }}
											>
												<IconCloseModal />
											</button>
										) : null}
									</div>

									<div
										className='flex font-medium text-xs sm:mt-5 mt-3'
										style={{ fontFamily: "'Inter', sans-serif" }}
									>
										{description ? <p>{description}</p> : null}
										{!navigateToPage && link ? (
											<div className='mx-4 inline'>
												<Link href={link.href} passHref>
													<a data-testid={link.testId}>
														<span className='cursor-pointer text-main-500 transition-all hover:text-main-600'>
															{link.name}
														</span>
													</a>
												</Link>
											</div>
										) : (
											<button
												className='mx-4 cursor-pointer text-main-500 transition-all hover:text-main-600'
												onClick={navigateToNextContent}
											>
												{link?.name}
											</button>
										)}
									</div>

									<div
										className={classNames('showBar', {
											'mb-12': status !== null || link?.href === '/login',
											'my-12 mt-12':
												status === null ||
												link?.testId === 'registerEmailLoginLink' ||
												link?.testId === 'loginCreateAccountLink',
											'h-[330px] overflow-auto': navigateToPage === 'Continue With Email',
										})}
									>
										{children}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

export default AuthFormWrapper;
