import { httpHandler } from '@http/httpHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { OnsiteNotificationsActionTypes } from './onsiteNotifications.action-types';

export const openNotificationsSocket = createAsyncThunk(
	OnsiteNotificationsActionTypes.OPEN_NOTIFICATIONS_SOCKET,
	() => {
		return httpHandler<{ token: string }>(axios.post('/notifications/subscribe'));
	}
);
