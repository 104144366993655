import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { fromEvent, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import IconThreeDots from './IconThreeDots';

interface IThreeDotMenuState {
	distanceFromTop: number;
	positionTop: number;
	positionLeft: number;
	positionBottom: number;
	buttonHeight: number;
	buttonWidth: number;
	scrollY: number;
	isVisible: boolean;
	showAnimation: boolean;
	renderDirection: 'up' | 'down';
}

interface IThreeDotMenuProps {
	children: ReactNode;
	iconColor: 'white' | 'black' | 'gray';
	className?: string;
}
const paddingDesktopMenu = 12;
const marginRightDesktopThreeDotIcon = 8;
const ThreeDotMenu = ({ children, iconColor, className }: IThreeDotMenuProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [menuElement, setMenuElement] = useState<HTMLDivElement | null>(null);
	const [elementDimensions, setElementDimensions] = useState<DOMRect>();
	const [state, setState] = useState<IThreeDotMenuState>({
		distanceFromTop: 0,
		positionTop: 0,
		positionLeft: 0,
		positionBottom: 0,
		buttonHeight: 0,
		buttonWidth: 0,
		scrollY: 0,
		isVisible: false,
		showAnimation: false,
		renderDirection: 'down',
	});

	const closeMenu = useCallback(() => {
		setState((prevState) => ({ ...prevState, isVisible: false }));
	}, []);

	useEffect(() => {
		if (menuElement) {
			setElementDimensions(menuElement.getBoundingClientRect());
			const { height } = menuElement.getBoundingClientRect();
			const isTallerThanAvailableSpace = window.innerHeight < height + state.positionTop;
			const isDesktop = window.innerWidth > 639;
			if (isTallerThanAvailableSpace) {
				if (isDesktop) {
					setState((prevState) => ({
						...prevState,
						positionTop: prevState.distanceFromTop - height + prevState.buttonHeight + 24,
						showAnimation: true,
						renderDirection: 'up',
					}));
				} else {
					setState((prevState) => ({
						...prevState,
						positionBottom: prevState.distanceFromTop - height,
						showAnimation: true,
						renderDirection: 'up',
					}));
				}
			} else {
				setState((prevState) => ({ ...prevState, showAnimation: true }));
			}
		}
	}, [menuElement, state.positionTop]);

	useEffect(() => {
		let subscription: Subscription;
		if (state.isVisible) {
			subscription = fromEvent(document, 'scroll', { capture: true }).pipe(take(1), tap(closeMenu)).subscribe();
		}

		return () => {
			subscription?.unsubscribe();
		};
	}, [closeMenu, state.isVisible]);

	const onOpenButtonClicked = () => {
		if (buttonRef.current) {
			const { top, width, bottom, height, left } = buttonRef.current.getBoundingClientRect();

			setState({
				isVisible: true,
				distanceFromTop: top,
				positionTop: top,
				positionBottom: bottom,
				positionLeft: left,
				buttonHeight: height,
				buttonWidth: width,
				scrollY: window.scrollY,
				showAnimation: false,
				renderDirection: 'down',
			});
		}
	};

	const getAnimationState = () => {
		return state.showAnimation && elementDimensions?.width ? 'opacity-100' : 'opacity-0';
	};

	const renderDesktopMenu = () => {
		const positionTop = state.positionTop - paddingDesktopMenu;
		const positionLeft =
			state.positionLeft - (elementDimensions?.width || 0) + state.buttonWidth + marginRightDesktopThreeDotIcon;

		return createPortal(
			<div className='fixed inset-0 z-50' onClick={closeMenu} data-testid={'dropdownMenuPopupContainer'}>
				<div
					className={`relative flex flex-col w-max space-y-2 py-3 text-sm shadow-md rounded-[10px] bg-white transition-opacity ${getAnimationState()}`}
					style={{
						top: positionTop,
						padding: paddingDesktopMenu,
						paddingLeft: 0,
						paddingRight: 0,
						left: positionLeft,
					}}
					ref={setMenuElement}
				>
					{state.renderDirection === 'down' ? (
						<IconThreeDots
							className='opacity-50 self-end mr-2 cursor-pointer'
							style={{ marginRight: marginRightDesktopThreeDotIcon }}
							iconColor={iconColor}
						/>
					) : null}
					{children}
					{state.renderDirection === 'up' ? (
						<IconThreeDots
							className='opacity-50 self-end mr-2 cursor-pointer'
							style={{ marginRight: marginRightDesktopThreeDotIcon }}
							iconColor={iconColor}
						/>
					) : null}
				</div>
			</div>,
			document.body
		);
	};

	const renderMobileMenu = () => {
		return createPortal(
			<div
				className={`absolute w-full h-screen inset-0 overflow-hidden cursor-default justify-center items-center bg-black/30 z-50 transition-all ${getAnimationState()}`}
				onClick={closeMenu}
				ref={containerRef}
				data-testid={'dropdownMenuPopupContainer'}
				style={{ top: state.scrollY }}
			>
				<div className='relative w-full h-full'>
					<div
						className='absolute bg-white rounded-full flex flex-col justify-center items-center brightness-75'
						style={{
							top: state.distanceFromTop + 5,
							left: state.positionLeft - state.buttonHeight / 3 + 5,
							width: state.buttonHeight - 10,
							height: state.buttonHeight - 10,
						}}
					>
						<IconThreeDots className='opacity-50' iconColor={iconColor} />
					</div>
					<div
						className='absolute flex flex-col bg-white w-full p-5 rounded-xl space-y-5 text-xl'
						style={{ top: state.positionBottom }}
						ref={setMenuElement}
					>
						{children}
					</div>
				</div>
			</div>,
			document.body
		);
	};

	return (
		<>
			<button
				className={`focus:outline-none grid place-items-center w-max h-auto ${className || ''}`}
				onClick={onOpenButtonClicked}
				ref={buttonRef}
				data-testid={'dropdownMenuBtn'}
			>
				<IconThreeDots className='opacity-50' iconColor={iconColor} />
			</button>
			{state.isVisible ? (window.innerWidth > 639 ? renderDesktopMenu() : renderMobileMenu()) : null}
		</>
	);
};

export default ThreeDotMenu;
