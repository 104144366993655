import React, { ReactNode } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import InputMessage from './InputMessage';

interface IInputCheckboxProps {
	testId: string;
	id: string;
	tabIndex: number;
	hookRegister: UseFormRegisterReturn;
	fieldError: FieldError | undefined;
	label?: string;
	showError?: boolean;
	children?: ReactNode;
}
const InputCheckbox = (props: IInputCheckboxProps) => {
	const { id, label, testId, fieldError, tabIndex, hookRegister, showError, children } = props;

	return (
		<>
			<div className='flex'>
				<label className='flex justify-start items-start w-max'>
					<div
						className={fieldError ? 'input-checkbox-error' : 'input-checkbox-valid'}
						style={{ width: 18, height: 18 }}
					>
						<input
							id={id}
							type='checkbox'
							className='opacity-0 absolute checkbox'
							tabIndex={tabIndex}
							data-testid={testId}
							{...hookRegister}
						/>
						<svg
							className='fill-current hidden w-4 h-4 text-main-500 pointer-events-none'
							viewBox='0 0 20 20'
						>
							<path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
						</svg>
					</div>
					{!children ? (
						<div className='select-none text-sm text-dark-600' data-testid={`${testId}Label`}>
							{label}
						</div>
					) : null}
				</label>
				{children ? (
					<div className='select-none text-sm text-dark-600' data-testid={`${testId}Children`}>
						{children}
					</div>
				) : null}
			</div>
			{showError ? <InputMessage inputTestId={testId} fieldError={fieldError} /> : null}
		</>
	);
};

export default InputCheckbox;
