import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { ITournamentCompact, ITournamentFull } from '@features/tournaments/models/tournament.model';
import { httpHandler } from '@http/httpHelpers';
import { IQueryParams, buildQuery } from '@http/queryCreators';
import { Update, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ITournamentsAllResponse } from '../models/tournament-all-response.model';
import { IChampionshipEndedTournament } from '../models/tournament-championship-ended.model';
import { ITournamentRegistration } from '../models/tournament-registration.model';
import { TournamentsActionTypes } from './tournaments.action-types';

export const wsTournamentParticipantRegistered = createAction(
	TournamentsActionTypes.TOURNAMENT_PARTICIPANT_REGISTERED,
	(payload) => {
		return { payload };
	}
);

export const emitHttpFetchTournamentsPageable = createAsyncThunk(
	TournamentsActionTypes.FETCH_TOURNAMENTS,
	(params: Required<Pick<IQueryParams, 'skip' | 'take'>>) => {
		return httpHandler<IPageableResponse<ITournamentCompact>>(axios.get('/tournaments/list', { params }));
	}
);

export const emitHttpFetchMerchantTournaments = createAsyncThunk(
	TournamentsActionTypes.FETCH_MERCHANT_TOURNAMENTS,
	({ take, merchantId }: Pick<IQueryParams, 'take'> & Record<'merchantId', string>) => {
		const params = buildQuery<ITournamentCompact>({
			take: take,
			skip: 0,
		});

		return httpHandler<IPageableResponse<ITournamentCompact>>(
			axios.get(`/tournaments/merchants/${merchantId}`, { params })
		);
	}
);

export const emitHttpFetchTournament = createAsyncThunk(
	TournamentsActionTypes.FETCH_TOURNAMENT,
	(tournamentId: string) => {
		return httpHandler<ITournamentFull>(axios.get(`/tournaments/${tournamentId}`));
	}
);

export const emitHttpRegisterForTournament = createAsyncThunk(
	TournamentsActionTypes.REGISTER_FOR_TOURNAMENT,
	(body: ITournamentRegistration) => {
		return httpHandler<boolean>(axios.post('/tournaments/register', body)).then(({ data }) => {
			const updateTournamentData: Update<ITournamentFull> = {
				id: body.tournamentId,
				changes: { registered: true },
			};
			return updateTournamentData;
		});
	}
);

export const emitHttpFetchRegisteredTournaments = createAsyncThunk(
	TournamentsActionTypes.FETCH_REGISTERED_TOURNAMENTS,
	({ take }: Required<Pick<IQueryParams, 'take'>>) => {
		const params = buildQuery<ITournamentCompact>({
			take: take,
			skip: 0,
		});

		return httpHandler<IPageableResponse<ITournamentCompact>>(axios.get('/tournaments/registered', { params }));
	}
);

export const emitHttpFetchPrizedTournaments = createAsyncThunk(
	TournamentsActionTypes.FETCH_PRIZED_TOURNAMENTS,
	(params: Required<Pick<IQueryParams, 'skip' | 'take'>>) => {
		return httpHandler<IPageableResponse<ITournamentCompact>>(axios.get('/tournaments/with-awards', { params }));
	}
);

export const emitHttpFetchTournamentsHome = createAsyncThunk(TournamentsActionTypes.FETCH_HOME_TOURNAMENTS, () => {
	return httpHandler<ITournamentsAllResponse>(axios.get('/tournaments/list-all', { params: { skip: 0, take: 8 } }));
});

export const emitHttpChampionshipsTournaments = createAsyncThunk(
	TournamentsActionTypes.FETCH_TOURNAMENT_BY_CHAMPIONSHIPS,
	({ take, championshipsId }: Pick<IQueryParams, 'take'> & Record<'championshipsId', string>) => {
		const params = buildQuery<ITournamentCompact>({
			take: take,
			skip: 0,
		});

		return httpHandler<IPageableResponse<ITournamentCompact>>(
			axios.get(`/tournaments/championships/${championshipsId}`, { params: params })
		);
	}
);

export const emitHttpChampionshipEndedGamys = createAsyncThunk(
	TournamentsActionTypes.FETCH_ENDED_TOURNAMENT_BY_CHAMPIONSHIPS,
	({ skip, take, championshipsId }: Pick<IQueryParams, 'take' | 'skip'> & Record<'championshipsId', string>) => {
		const params = buildQuery<ITournamentCompact>({
			skip: skip,
			take: take,
		});

		return httpHandler<IPageableResponse<IChampionshipEndedTournament>>(
			axios.get(`/tournaments/championships/${championshipsId}/finished`, { params: params })
		);
	}
);

export const emitHttpFetchMerchantFinishedTournaments = createAsyncThunk(
	TournamentsActionTypes.FETCH_FINISHED_MERCHANT_TOURNAMENTS,
	({ skip, take, merchantId }: Pick<IQueryParams, 'take' | 'skip'> & Record<'merchantId', string>) => {
		const params = buildQuery<ITournamentCompact>({
			take: take,
			skip: skip,
		});

		return httpHandler<IPageableResponse<ITournamentCompact>>(
			axios.get(`/tournaments/merchants/${merchantId}/finished`, { params })
		);
	}
);
