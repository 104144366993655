import InformationVerificationLabel from '@features/common/components/InformationVerificationLabel';
import InputText from '@features/common/components/InputText';
import PrimaryButton from '@features/common/components/PrimaryButton';
import { showToast } from '@features/common/store/common.reducer';
import { getFieldValidations } from '@features/common/store/common.selectors';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames';
import Link from 'next/link';
import { Router, useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ILoginForm } from '../models/login-form.model';
import { fetchCurrentUser, loginUser } from '../store/auth.actions';
import { updateSwitchToPage, updateVerificationStatus } from '../store/auth.reducer';
import { getAuthIsLoading, getSwitchToPage, getVerificationStatus } from '../store/auth.selectors';
import AuthFormWrapper from './AuthFormWrapper';
import { useEffect } from 'react';

const LoginWithEmail = () => {
	const { email, password } = useSelector(getFieldValidations);
	const isLoading = useSelector(getAuthIsLoading('loginUser'));
	const status = useSelector(getVerificationStatus);
	const navigateToPage = useSelector(getSwitchToPage);
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();

	const { register, handleSubmit, formState } = useForm<ILoginForm>({
		mode: 'all',
	});

	useEffect(() => {
		if (!navigateToPage) {
			return;
		}
		const hideModal = () => {
			dispatch(updateVerificationStatus(null));
			dispatch(updateSwitchToPage(null));
		};

		Router.events.on('routeChangeStart', hideModal);

		return () => {
			Router.events.off('routeChangeStart', hideModal);

			if (!navigateToPage) {
				return;
			}
		};
	}, [dispatch, navigateToPage]);

	const onSubmit = (formValue: ILoginForm) => {
		let message: string;
		let buttonText: string;
		let redirect: string;

		dispatch(loginUser(formValue))
			.then(unwrapResult)
			.then(() => {
				dispatch(fetchCurrentUser());
				if (navigateToPage !== null) {
					dispatch(updateVerificationStatus(null));
					dispatch(updateSwitchToPage(null));
				}
			})
			.catch((error) => {
				const parsedError = parseError(error);
				switch (parsedError.code) {
					case 'BAD_CREDENTIALS':
						message = formatMessage({
							id: 'bcs-Auth-LoginFailedCredentials',
						});
						break;
					case 'DISABLED':
						message = formatMessage({
							id: 'bcs-Auth-LoginNotAllowed',
						});
						break;
					case 'NOT_VERIFIED':
						message = formatMessage({
							id: 'bcs-Auth-NotVerified',
						});
						buttonText = formatMessage({
							id: 'bcs-Auth-ButtonText',
						});
						redirect = '/verification-email';
						break;
					default:
						message = parsedError.message;
						break;
				}

				dispatch(
					showToast({
						message,
						type: 'warning',
						title: formatMessage({ id: 'bcs-Common-Oops' }),
						buttonText: buttonText ? buttonText : formatMessage({ id: 'bcs-Common-GotIt' }),
						redirect: redirect,
					})
				);
			});
	};

	if (isLoading) {
		return (
			<LoadingIndicator
				className={classNames('flex flex-col justify-center items-center w-full h-full', {
					'bg-white md:bg-black': !navigateToPage,
				})}
				contained
			/>
		);
	}

	return (
		<AuthFormWrapper
			pathForBack='/login'
			title={`${formatMessage({ id: 'bcs-Title-Form-Login' })}`}
			description={`${formatMessage({ id: 'bcs-Auth-NewUser' })}?`}
			link={{
				href: '/register',
				name: formatMessage({ id: 'bcs-Auth-CreateAnAccount' }),
				testId: 'loginCreateAccountLink',
			}}
		>
			{status !== 'Unknown User' ? <InformationVerificationLabel status={status} /> : null}

			<form className='flex flex-col space-y-5' onSubmit={handleSubmit(onSubmit)} data-testid='form'>
				<InputText
					id={'email'}
					label={formatMessage({ id: 'bcs-Auth-Email' })}
					type={'email'}
					testId={'email'}
					tabIndex={1}
					fieldError={formState.errors.email}
					placeholder={formatMessage({ id: 'bcs-Auth-Email' })}
					hookRegister={register('email', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-Error-EmptyEmail',
							}),
						},
						pattern: {
							value: RegExp(email),
							message: formatMessage({
								id: 'bcs-Error-InvalidEmail',
							}),
						},
					})}
				/>

				<InputText
					id={'password'}
					label={formatMessage({ id: 'bcs-Auth-Password' })}
					type={'password'}
					testId={'password'}
					tabIndex={2}
					fieldError={formState.errors.password}
					placeholder={formatMessage({ id: 'bcs-Auth-Password' })}
					hookRegister={register('password', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-Error-EmptyPassword',
							}),
						},
						pattern: {
							value: RegExp(password),
							message: formatMessage(
								{ id: 'bcs-Error-Invalid' },
								{
									field: formatMessage({
										id: 'bcs-Auth-Password',
									}),
								}
							),
						},
					})}
				/>
				<div className='mt-4'>
					<PrimaryButton
						type='submit'
						text={formatMessage({ id: 'bcs-Auth-Continue' })}
						tabIndex={3}
						testId='loginSubmitBtn'
						disabled={isLoading}
					/>
				</div>
				<div className='flex justify-between' style={{ marginTop: '27px' }}>
					<Link href={'/forgot-password'} passHref>
						<a data-testid='forgotPasswordLink'>
							<span
								className='font-medium text-xs text-main-500 transition-all cursor-pointer hover:text-main-600'
								style={{ fontFamily: "'Inter', sans-serif" }}
							>
								{formatMessage({ id: 'bcs-Auth-ForgotPass' })}?
							</span>
						</a>
					</Link>
					<Link href={'/verification-email'} passHref>
						<a data-testid='verificationEmailLink'>
							<span
								className='font-medium text-xs text-main-500 transition-all cursor-pointer hover:text-main-600 text-right w-[62%]'
								style={{ fontFamily: "'Inter', sans-serif" }}
							>
								{formatMessage({ id: 'bcs-Auth-SendVerificationEmail' })}
							</span>
						</a>
					</Link>
				</div>
			</form>
		</AuthFormWrapper>
	);
};

export default LoginWithEmail;
