import React from 'react';

const IconPWADownload = () => {
	return (
		<svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.6133 4.337H16.7C16.5232 4.337 16.3536 4.40724 16.2286 4.53227C16.1036 4.65729 16.0333 4.82686 16.0333 5.00367C16.0333 5.18048 16.1036 5.35005 16.2286 5.47508C16.3536 5.6001 16.5232 5.67034 16.7 5.67034H19.6667V19.0037H2.33333V5.67034H5.25333C5.43014 5.67034 5.59971 5.6001 5.72474 5.47508C5.84976 5.35005 5.92 5.18048 5.92 5.00367C5.92 4.82686 5.84976 4.65729 5.72474 4.53227C5.59971 4.40724 5.43014 4.337 5.25333 4.337H2.38667C2.20714 4.32982 2.028 4.35899 1.86003 4.42277C1.69205 4.48655 1.5387 4.58363 1.40919 4.70816C1.27967 4.83269 1.17666 4.98212 1.10635 5.14747C1.03603 5.31281 0.999857 5.49066 1 5.67034V19.0037C0.999857 19.1833 1.03603 19.3612 1.10635 19.5265C1.17666 19.6919 1.27967 19.8413 1.40919 19.9658C1.5387 20.0904 1.69205 20.1875 1.86003 20.2512C2.028 20.315 2.20714 20.3442 2.38667 20.337H19.6133C19.7929 20.3442 19.972 20.315 20.14 20.2512C20.3079 20.1875 20.4613 20.0904 20.5908 19.9658C20.7203 19.8413 20.8233 19.6919 20.8937 19.5265C20.964 19.3612 21.0001 19.1833 21 19.0037V5.67034C21.0001 5.49066 20.964 5.31281 20.8937 5.14747C20.8233 4.98212 20.7203 4.83269 20.5908 4.70816C20.4613 4.58363 20.3079 4.48655 20.14 4.42277C19.972 4.35899 19.7929 4.32982 19.6133 4.337Z'
				fill='#3C3C3C'
				stroke='#3C3C3C'
				strokeWidth='0.2'
			/>
			<path
				d='M5.86667 11.58L10.5333 16.1733C10.658 16.2955 10.8255 16.3639 11 16.3639C11.1745 16.3639 11.342 16.2955 11.4667 16.1733L16.1333 11.58C16.2598 11.4562 16.3318 11.2873 16.3337 11.1104C16.3356 10.9335 16.2671 10.7631 16.1433 10.6367C16.0196 10.5102 15.8506 10.4382 15.6737 10.4363C15.4968 10.4344 15.3264 10.5029 15.2 10.6267L11.6667 14.1V1.66667C11.6667 1.48986 11.5964 1.32029 11.4714 1.19526C11.3464 1.07024 11.1768 1 11 1C10.8232 1 10.6536 1.07024 10.5286 1.19526C10.4036 1.32029 10.3333 1.48986 10.3333 1.66667V14.1L6.8 10.6267C6.67358 10.5029 6.50317 10.4344 6.32626 10.4363C6.14935 10.4382 5.98044 10.5102 5.85667 10.6367C5.7329 10.7631 5.66442 10.9335 5.6663 11.1104C5.66817 11.2873 5.74025 11.4562 5.86667 11.58Z'
				fill='#3C3C3C'
				stroke='#3C3C3C'
				strokeWidth='0.2'
			/>
		</svg>
	);
};

export default IconPWADownload;
