import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { IMerchantsLoadingState } from '../models/merchants-state-loading.model';
import { IMerchantsState } from '../models/merchants-state.model';
import { merchantsAdapter } from './merchants.reducer';

const merchantsEntitySelectors = merchantsAdapter.getSelectors();
const merchantSelector = ({ merchants }: IApplicationState): IMerchantsState => merchants;

export const getMerchantsLoading = (loadingKey: keyof IMerchantsLoadingState) =>
	createSelector(merchantSelector, ({ loadingState }) => loadingState[loadingKey]);

export const getMerchantById = (merchantId: string) =>
	createSelector(merchantSelector, (state) =>
		merchantsEntitySelectors.selectAll(state).find(({ id }) => id === merchantId)
	);

export const getAllMerchants = createSelector(merchantSelector, merchantsEntitySelectors.selectAll);
