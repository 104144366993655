import { ICommonState } from '@features/common/models/common-state.model';
import IToast from '@features/common/models/toast.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchConfig } from './common.actions';
import { IChampionshipPlayNowCopy } from '@features/championships/models/championship-play-now-create-copy.model';

export const initialCommonState: ICommonState = {
	cdnUrl: null,
	facebookPixelKey: null,
	hotJarKey: null,
	recaptchaKey: null,
	supportedCountries: [],
	supportedLanguages: [],
	fieldValidations: {
		domain: '',
		email: '',
		password: '',
		phone: '',
		serverDateTime: '',
	},
	isLoading: false,
	toast: null,
	enableScroll: true,
	datesSyncDiffMs: 0,
	showSocialLoginNotificationsModal: false,
	showChampionshipNotificationsModal: false,
	isRecaptchaLoaded: false,
	isRecaptchaActivated: false,
	defaultLanguage: 'BG',
	playNowModal: null,
};

const commonSlice = createSlice({
	name: '[COMMON] - ',
	initialState: initialCommonState,
	reducers: {
		showToast: (state, { payload }: PayloadAction<IToast>) => {
			state.toast = payload;
		},
		hideToast: (state) => {
			state.toast = null;
		},
		enableScroll: (state) => {
			state.enableScroll = true;
		},
		disableScroll: (state) => {
			state.enableScroll = false;
		},
		toggleSocialLoginNotificationsModal: (state, action: PayloadAction<boolean>) => {
			state.showSocialLoginNotificationsModal = action.payload;
		},
		toggleChampionshipNotificationsModal: (state, action: PayloadAction<boolean>) => {
			state.showSocialLoginNotificationsModal = action.payload;
		},
		toggleIsRecaptchaLoaded: (state, action: PayloadAction<boolean>) => {
			state.isRecaptchaLoaded = action.payload;
		},
		togglePlayNowModal: (state, action: PayloadAction<IChampionshipPlayNowCopy>) => {
			state.playNowModal = action.payload;
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchConfig.pending, (state) => {
			state.isLoading = true;
		});
		addCase(fetchConfig.fulfilled, (state, { payload: { data } }) => {
			state.supportedCountries = data.supportedCountries;
			state.supportedLanguages = data.supportedLanguages;
			state.fieldValidations = data.fieldValidations;
			state.datesSyncDiffMs = data.datesSyncDiffMs;
			state.cdnUrl = data.cdnUrl;
			state.isLoading = false;
			state.facebookPixelKey = data.facebookPixelKey;
			state.defaultLanguage = data.defaultLanguage;
			state.hotJarKey = data.hotJarKey;
			state.recaptchaKey = data.recaptchaKey;
			state.isRecaptchaActivated = data.isRecaptchaActivated;
		});
		addCase(fetchConfig.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const {
	showToast,
	hideToast,
	enableScroll,
	disableScroll,
	toggleSocialLoginNotificationsModal,
	toggleChampionshipNotificationsModal,
	toggleIsRecaptchaLoaded,
	togglePlayNowModal,
} = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
