import { wsTournamentParticipantRegistered } from '@features/tournaments/store/tournaments.actions';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reduce as lodashReduce } from 'lodash';
import { IWSChampionshipNewEntriesParsed } from '../models/championship-event-new-championship.model';
import { IWSChampionshipStatusChanged } from '../models/championship-event-status.model';
import { IChampionship } from '../models/championship-model';
import { IChampionshipsState } from '../models/championships-state.model';
import {
	emitChampionshipEndedTournamentsCount,
	emitFinishGameCountOnHomepage,
	emitHttpChampionshipById,
	emitHttpChampionshipsOneTimeFetchAll,
	emitHttpChampionshipsOneTimeFetchByMerchantId,
	emitHttpChampionshipsPlayNowAwardsFetchAll,
	emitHttpChampionshipsPlayNowFetchAll,
	emitHttpPlayNowAllFetchByMerchantId,
	emitHttpFinishedChampionshipsOneTimeFetchByMerchantId,
	emitHttpFinishedPlayNowAllFetchByMerchantId,
} from './championships.actions';

const sortCompareChampionships = (tournament1: IChampionship, tournament2: IChampionship) => {
	return (
		new Date(tournament2.startDate).getTime() - new Date(tournament1.startDate).getTime() ||
		tournament2.participantsCount - tournament1.participantsCount
	);
};

export const championshipsOneTimeAdapter = createEntityAdapter<IChampionship>({
	selectId: (championship) => championship.id,
	sortComparer: sortCompareChampionships,
});

export const championshipPlayNowAdapter = createEntityAdapter<IChampionship>({
	selectId: (championship) => championship.id,
	sortComparer: sortCompareChampionships,
});

export const championshipPlayNowAwardsAdapter = createEntityAdapter<IChampionship>({
	selectId: (championship) => championship.id,
	sortComparer: sortCompareChampionships,
});

export const finishedChampionshipPlayNowAdapter = createEntityAdapter<IChampionship>({
	selectId: (championship) => championship.id,
	sortComparer: sortCompareChampionships,
});

export const finishedChampionshipOneTimeAdapter = createEntityAdapter<IChampionship>({
	selectId: (championship) => championship.id,
	sortComparer: sortCompareChampionships,
});

export const initialChampionshipsState: IChampionshipsState = {
	championshipOneTime: championshipsOneTimeAdapter.getInitialState({ entities: {}, ids: [], total: null }),
	championshipPlayNowAll: championshipPlayNowAdapter.getInitialState({ entities: {}, ids: [], total: null }),
	championshipPlayNowAwards: championshipPlayNowAwardsAdapter.getInitialState({ entities: {}, ids: [], total: null }),
	finishedChampionshipOneTime: finishedChampionshipOneTimeAdapter.getInitialState({
		entities: {},
		ids: [],
		total: null,
	}),
	finishedChampionshipPlayNowAll: finishedChampionshipPlayNowAdapter.getInitialState({
		entities: {},
		ids: [],
		total: null,
	}),
	loadingState: {
		championshipsFetchAll: false,
		championshipsFetchAllByMerchantId: false,
		championshipFetchById: false,
		championshipsPlayNowAwardsFetchAll: false,
		championshipsPlayNowFetchAll: false,
		championshipPlayNowByMerchantId: false,
		championshipPlayNowStartCopy: false,
		finishedChampionshipsOneTimeFetchByMerchantId: false,
		finishedPlayNowAllFetchByMerchantId: false,
	},
	selectedChampionship: null,
	wsMessageType: null,
	championshipPlayNowCopy: {
		id: '',
		isPreviouslyCreated: false,
	},
	showChampionshipLeaderBoardButtonRefresh: false,
};

const championshipsSlice = createSlice({
	name: '[CHAMPIONSHIPS] - ',
	initialState: initialChampionshipsState,
	reducers: {
		emitChampionshipRemoveSelected: (state) => {
			state.selectedChampionship = null;
		},
		emitChampionshipRemoveAll: (state) => {
			championshipsOneTimeAdapter.removeAll(state.championshipOneTime);
			championshipPlayNowAdapter.removeAll(state.championshipPlayNowAll);
			championshipPlayNowAwardsAdapter.removeAll(state.championshipPlayNowAwards);
			finishedChampionshipOneTimeAdapter.removeAll(state.finishedChampionshipOneTime);
			finishedChampionshipPlayNowAdapter.removeAll(state.finishedChampionshipPlayNowAll);

			state.championshipOneTime.total = null;
			state.championshipPlayNowAwards.total = null;
			state.championshipPlayNowAll.total = null;
			state.finishedChampionshipOneTime.total = null;
			state.finishedChampionshipPlayNowAll.total = null;
		},
		emitChampionshipWSnewChampionship: (state, { payload }: PayloadAction<IWSChampionshipNewEntriesParsed>) => {
			state.wsMessageType = payload.type;
		},
		emitChampionshipWSnewChampionshipStatus: (state, { payload }: PayloadAction<IWSChampionshipStatusChanged>) => {
			const changes = lodashReduce(
				payload.data.championshipIds,
				(result, value) => {
					result.push({
						id: value,
						changes: { statusId: payload.data.status },
					});
					return result;
				},
				[] as any[]
			);
			const isSelectedTournamentHasNewStatus = payload.data.championshipIds.some(
				(championshipId) => championshipId === state.selectedChampionship?.id
			);

			if (isSelectedTournamentHasNewStatus && state.selectedChampionship) {
				state.selectedChampionship.statusId = payload.data.status;
			}

			switch (payload.data.status) {
				case 'PUBLISHED':
					try {
						if (state.championshipPlayNowAll.total !== null) {
							championshipPlayNowAdapter.addMany(state.championshipPlayNowAll, changes);
						}

						if (state.championshipPlayNowAwards.total !== null) {
							championshipPlayNowAwardsAdapter.addMany(state.championshipPlayNowAwards, changes);
						}
					} catch (e) {}
				case 'RUNNING':
					try {
						const playNowChampionshipStatuses = payload.data.championshipIds
							.filter((playNowChampionshipId) =>
								state.championshipPlayNowAll.ids.includes(playNowChampionshipId)
							)
							.map((id) => ({ id, changes: { statusId: payload.data.status } }));

						const playNowAwardsChampionshipStatuses = payload.data.championshipIds
							.filter((playNowAwardsChampionshipId) =>
								state.championshipPlayNowAwards.ids.includes(playNowAwardsChampionshipId)
							)
							.map((id) => ({ id, changes: { statusId: payload.data.status } }));

						championshipPlayNowAdapter.updateMany(
							state.championshipPlayNowAll,
							playNowChampionshipStatuses
						);

						championshipPlayNowAwardsAdapter.updateMany(
							state.championshipPlayNowAwards,
							playNowAwardsChampionshipStatuses
						);
					} catch (e) {}
				case 'FINISHED_HIDDEN':
					try {
						championshipsOneTimeAdapter.removeMany(state.championshipOneTime, payload.data.championshipIds);
						championshipPlayNowAdapter.removeMany(
							state.championshipPlayNowAll,
							payload.data.championshipIds
						);
						championshipPlayNowAwardsAdapter.removeMany(
							state.championshipPlayNowAwards,
							payload.data.championshipIds
						);
					} catch (e) {}

					break;
				case 'FINISHED_VISIBLE':
					try {
						championshipsOneTimeAdapter.updateMany(state.championshipOneTime, changes);

						if (state.championshipPlayNowAll.total !== null) {
							championshipPlayNowAdapter.updateMany(state.championshipPlayNowAll, changes);
						}

						if (state.championshipPlayNowAwards.total !== null) {
							championshipPlayNowAwardsAdapter.updateMany(state.championshipPlayNowAwards, changes);
						}
					} catch (e) {}
					break;
			}
		},
		refreshChampionshipPage: (state, { payload }: PayloadAction<boolean>) => {
			state.showChampionshipLeaderBoardButtonRefresh = payload;
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(emitFinishGameCountOnHomepage.type, (state, { payload }: PayloadAction | any) => {
			const finishedGamy = payload[0];
			if (finishedGamy.championshipId) {
				const playNowChampionship = state.championshipPlayNowAll.entities[finishedGamy.championshipId];
				const playNowChampionshipAwards = state.championshipPlayNowAwards.entities[finishedGamy.championshipId];
				if (playNowChampionship) {
					championshipPlayNowAdapter.updateOne(state.championshipPlayNowAll, {
						id: finishedGamy.championshipId,
						changes: {
							finishedGamesCount: playNowChampionship.finishedGamesCount + 1,
						},
					});
				}

				if (playNowChampionshipAwards) {
					championshipPlayNowAdapter.updateOne(state.championshipPlayNowAwards, {
						id: finishedGamy.championshipId,
						changes: {
							finishedGamesCount: playNowChampionshipAwards.finishedGamesCount + 1,
						},
					});
				}
			}
		});
		addCase(emitChampionshipEndedTournamentsCount.type, (state, { payload }: PayloadAction | any) => {
			const { championshipId } = payload;

			if (championshipId) {
				try {
					const championship = state.championshipOneTime.entities[championshipId] as IChampionship;
					if (championship) {
						championshipsOneTimeAdapter.updateOne(state.championshipOneTime, {
							id: payload.championshipId,
							changes: {
								finishedGamesCount: championship.finishedGamesCount + 1,
							},
						});
					}
				} catch (e) {}

				try {
					if (state.selectedChampionship && state.selectedChampionship.id === championshipId) {
						state.selectedChampionship.finishedGamesCount =
							state.selectedChampionship.finishedGamesCount + 1;
					}
				} catch (e) {}
			}
		});
		addCase(wsTournamentParticipantRegistered.type, (state, action: PayloadAction | any) => {
			if (action.payload) {
				const { championshipId } = action.payload.data;
				if (championshipId) {
					try {
						const championship = state.championshipOneTime.entities[championshipId] as IChampionship;
						if (championship) {
							const { participantsCount } = championship;
							if (!!participantsCount) {
								championshipsOneTimeAdapter.updateOne(state.championshipOneTime, {
									id: championshipId,
									changes: { participantsCount: participantsCount + 1 },
								});
							}
						}
					} catch (e) {}

					try {
						if (state.selectedChampionship && state.selectedChampionship.id === championshipId) {
							state.selectedChampionship.participantsCount =
								state.selectedChampionship.participantsCount + 1;
						}
					} catch (e) {}
				}
			}
		});
		addCase(emitHttpChampionshipsOneTimeFetchAll.pending, (state) => {
			state.loadingState.championshipsFetchAll = true;
		});
		addCase(emitHttpChampionshipsOneTimeFetchAll.fulfilled, (state, action) => {
			state.loadingState.championshipsFetchAll = false;
			championshipsOneTimeAdapter.addMany(
				state.championshipOneTime,
				action.payload.data.result.map((element) => ({ ...element, rewards: [] }))
			);
			state.championshipOneTime.total = action.payload.data.total;
		});
		addCase(emitHttpChampionshipsOneTimeFetchAll.rejected, (state) => {
			state.loadingState.championshipsFetchAll = false;
		});
		addCase(emitHttpChampionshipsPlayNowFetchAll.pending, (state) => {
			state.loadingState.championshipsPlayNowFetchAll = true;
		});
		addCase(emitHttpChampionshipsPlayNowFetchAll.fulfilled, (state, action) => {
			const { total, result } = action.payload.data;
			state.loadingState.championshipsPlayNowFetchAll = false;
			championshipPlayNowAdapter.addMany(
				state.championshipPlayNowAll,
				result.map((element) => ({ ...element, rewards: [] }))
			);
			state.championshipPlayNowAll.total = total;
		});
		addCase(emitHttpChampionshipsPlayNowFetchAll.rejected, (state) => {
			state.loadingState.championshipsPlayNowFetchAll = false;
		});
		addCase(emitHttpChampionshipsPlayNowAwardsFetchAll.pending, (state) => {
			state.loadingState.championshipsPlayNowAwardsFetchAll = true;
		});
		addCase(emitHttpChampionshipsPlayNowAwardsFetchAll.fulfilled, (state, action) => {
			const { total, result } = action.payload.data;

			state.loadingState.championshipsPlayNowAwardsFetchAll = false;
			championshipPlayNowAwardsAdapter.addMany(
				state.championshipPlayNowAwards,
				result.map((element) => ({ ...element, rewards: [] }))
			);
			state.championshipPlayNowAwards.total = total;
		});
		addCase(emitHttpChampionshipsPlayNowAwardsFetchAll.rejected, (state) => {
			state.loadingState.championshipsPlayNowAwardsFetchAll = false;
		});
		addCase(emitHttpChampionshipsOneTimeFetchByMerchantId.pending, (state) => {
			state.loadingState.championshipsFetchAllByMerchantId = true;
		});
		addCase(emitHttpChampionshipsOneTimeFetchByMerchantId.fulfilled, (state, action) => {
			const { result } = action.payload.data;

			state.loadingState.championshipsFetchAllByMerchantId = false;
			championshipsOneTimeAdapter.upsertMany(
				state.championshipOneTime,
				result.map((element) => ({ ...element, rewards: [] }))
			);
		});
		addCase(emitHttpChampionshipsOneTimeFetchByMerchantId.rejected, (state) => {
			state.loadingState.championshipsFetchAllByMerchantId = false;
		});
		addCase(emitHttpChampionshipById.pending, (state) => {
			state.loadingState.championshipFetchById = true;
		});
		addCase(emitHttpChampionshipById.fulfilled, (state, action) => {
			state.loadingState.championshipFetchById = false;
			state.selectedChampionship = action.payload.data;
		});
		addCase(emitHttpChampionshipById.rejected, (state) => {
			state.loadingState.championshipFetchById = false;
		});
		addCase(emitHttpPlayNowAllFetchByMerchantId.pending, (state) => {
			state.loadingState.championshipPlayNowByMerchantId = true;
		});
		addCase(emitHttpPlayNowAllFetchByMerchantId.fulfilled, (state, action) => {
			const { total, result } = action.payload.data;

			state.loadingState.championshipPlayNowByMerchantId = false;
			championshipPlayNowAdapter.upsertMany(
				state.championshipPlayNowAll,
				result.map((element) => ({ ...element, rewards: [] }))
			);
			state.championshipPlayNowAll.total = total;
		});
		addCase(emitHttpPlayNowAllFetchByMerchantId.rejected, (state) => {
			state.loadingState.championshipPlayNowByMerchantId = false;
		});
		addCase(emitHttpFinishedChampionshipsOneTimeFetchByMerchantId.pending, (state) => {
			state.loadingState.finishedChampionshipsOneTimeFetchByMerchantId = true;
		});
		addCase(emitHttpFinishedChampionshipsOneTimeFetchByMerchantId.fulfilled, (state, action) => {
			const { result } = action.payload.data;

			state.loadingState.finishedChampionshipsOneTimeFetchByMerchantId = false;
			finishedChampionshipOneTimeAdapter.upsertMany(
				state.finishedChampionshipOneTime,
				result.map((element) => ({ ...element, rewards: [] }))
			);
			state.finishedChampionshipOneTime.total = action.payload.data.total;
		});
		addCase(emitHttpFinishedChampionshipsOneTimeFetchByMerchantId.rejected, (state) => {
			state.loadingState.finishedChampionshipsOneTimeFetchByMerchantId = false;
		});
		addCase(emitHttpFinishedPlayNowAllFetchByMerchantId.pending, (state) => {
			state.loadingState.finishedPlayNowAllFetchByMerchantId = true;
		});
		addCase(emitHttpFinishedPlayNowAllFetchByMerchantId.fulfilled, (state, action) => {
			const { result } = action.payload.data;

			state.loadingState.finishedPlayNowAllFetchByMerchantId = false;
			finishedChampionshipOneTimeAdapter.upsertMany(
				state.finishedChampionshipPlayNowAll,
				result.map((element) => ({ ...element, rewards: [] }))
			);
			state.finishedChampionshipPlayNowAll.total = action.payload.data.total;
		});
		addCase(emitHttpFinishedPlayNowAllFetchByMerchantId.rejected, (state) => {
			state.loadingState.finishedPlayNowAllFetchByMerchantId = false;
		});
	},
});

export const championshipsReducer = championshipsSlice.reducer;

export const {
	emitChampionshipRemoveSelected,
	emitChampionshipRemoveAll,
	emitChampionshipWSnewChampionship,
	emitChampionshipWSnewChampionshipStatus,
	refreshChampionshipPage,
} = championshipsSlice.actions;
