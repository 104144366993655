import React from 'react';

const IPhoneInstallIcon = () => {
	return (
		<div>
			<svg xmlns='http://www.w3.org/2000/svg' className='_4408 iOSPWA-step1-icon' viewBox='0 0 120 169'>
				<g fill='#107BFE'>
					<path d='M60 0l28 28-2 2a586 586 0 0 0-4 4L64 15v90h-8V15L38 34l-4-4-2-2L60 0z'></path>
					<path d='M0 49h44v8H8v104h104V57H76v-8h44v120H0V49z'></path>
				</g>
			</svg>
		</div>
	);
};

export default IPhoneInstallIcon;
