import React from 'react';

const IconCheckSign = () => {
	return (
		<svg width='112' height='108' viewBox='0 0 112 108' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M75.3031 94.6003C95.95 84.1032 104.681 60.4362 97.9421 38.7044C96.4196 33.7946 104.102 31.7144 105.614 36.5894C112.523 58.8693 105.309 83.1111 86.3641 97.0773C53.3847 121.389 5.43325 99.5695 1.12632 59.1836C-3.55136 15.3208 44.5791 -13.5662 82.0413 7.41069C86.514 9.91525 82.4981 16.7848 78.0257 14.2806C57.2163 2.6283 30.6336 7.7049 16.8804 27.6302C5.0897 44.7122 6.45846 68.1967 20.0715 83.8376C33.6464 99.4347 56.8608 103.977 75.3031 94.6003ZM51.5873 65.7207L104.549 4.03887C107.892 0.146292 113.495 5.79875 110.175 9.66459L54.4642 74.5481C53.1163 76.1178 50.1985 76.0617 48.8383 74.5481L47.253 72.7839C39.9156 64.6182 32.5784 56.4527 25.241 48.2874C21.8256 44.4865 27.4373 38.845 30.8669 42.6615L51.5873 65.7207Z'
				fill='#06CB84'
			/>
		</svg>
	);
};

export default IconCheckSign;
