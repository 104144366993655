import TournamentTimeRemaining from '@features/tournaments/components/TournamentTimeRemaining';
import { ITournamentCompact } from '@features/tournaments/models/tournament.model';
import React from 'react';
import { useIntl } from 'react-intl';

const TournamentNotificationInfo = (
	tournament: Pick<
		ITournamentCompact,
		'registered' | 'tournamentStartDate' | 'registrationEndDate' | 'statusId' | 'name'
	>
) => {
	const { registered, registrationEndDate, statusId, tournamentStartDate, name } = tournament;
	const { formatMessage } = useIntl();

	return (
		<p className='font-normal text-dark-700 text-sms'>
			{formatMessage(
				{ id: 'bcs-Participant-GreenLabel-Text' },
				{
					tournamentName: <b>{name}</b>,
					tournamentStart: (
						<TournamentTimeRemaining
							isPopupRegisteredSuccess={true}
							registered={registered}
							tournamentStartDate={tournamentStartDate}
							registrationEndDate={registrationEndDate}
							statusId={statusId}
						/>
					),
				}
			)}
		</p>
	);
};

export default TournamentNotificationInfo;
