import { RecursiveKeyOf } from '@features/common/models/recursiveKeyof.model';
import { and, comparison, Operation, or } from 'rsql-builder';
import { Operators } from 'rsql-builder/dist/operation';

interface IQuerySort<EntityKeys = string> {
	field: EntityKeys;
	desc: boolean;
}

interface IFilter<EntityKeys = string> {
	field: EntityKeys;
	operator:
		| FilterOperators.CONTAINS
		| FilterOperators.ENDS_WITH
		| FilterOperators.EQ
		| FilterOperators.GE
		| FilterOperators.GT
		| FilterOperators.IS_NULL
		| FilterOperators.LE
		| FilterOperators.LT
		| FilterOperators.NE
		| FilterOperators.NOT_CONTAINS
		| FilterOperators.STARTS_WITH;
	value: any;
}

interface IFilterWithInOperator<EntityKeys = string> {
	field: EntityKeys;
	operator: FilterOperators.IN;
	value: string[];
}

type IQueryFilter<EntityKeys = string> = {
	logic: 'and' | 'or';
	filters: Array<IFilter<EntityKeys> | IFilterWithInOperator<EntityKeys>>;
}[];

export interface IQueryParams<EntityKeys = string, ExtraQueries = Record<string, any>> {
	skip?: number;
	take?: number;
	filter?: IQueryFilter<EntityKeys>;
	distinct?: boolean;
	sort?: IQuerySort<EntityKeys>[];
	global?: string;
	extraQueries?: ExtraQueries;
}

export enum FilterOperators {
	EQ = '=',
	NE = '<>',
	LT = '<',
	GT = '>',
	LE = '<=',
	GE = '>=',
	CONTAINS = 'contains',
	STARTS_WITH = 'startswith',
	ENDS_WITH = 'endswith',
	NOT_CONTAINS = 'notcontains',
	IS_NULL = 'isNull',
	IN = '=in=',
}

const getShouldAddQuotes = (value: any) => {
	const hasWhiteSpacesRegex = /\s/gm;
	const specialChars = [
		'~',
		'`',
		'!',
		'@',
		'#',
		'$',
		'%',
		'^',
		'&',
		'*',
		'.',
		'(',
		')',
		'_',
		'+',
		'-',
		'=',
		'[',
		']',
		'{',
		'}',
		';',
		`'`,
		':',
		'\\',
		'|',
		'/',
		',',
		'.',
		'<',
		'>',
		'?',
	];

	if (typeof value === 'boolean' || typeof value === 'number') {
		return false;
	}
	if (value.length !== value.trim().length) {
		return true;
	}
	if (hasWhiteSpacesRegex.test(value)) {
		return true;
	}

	return value.includes('"') && !specialChars.some((char) => (value as string).includes(char));
};

const parseValue = (value: any, operator?: FilterOperators) => {
	const isDate =
		value instanceof Date ||
		(typeof value === 'object' && Object.prototype.toString.call(value) === '[object Date]');
	if (isDate) {
		const date = Object.fromEntries(
			Intl.DateTimeFormat(navigator.language, {
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				timeZone: 'UTC',
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour12: false,
			})
				.formatToParts(value)
				.map(({ type, value: dateValue }) => [type, dateValue])
		);

		const { year, month, day, hour, minute, second } = date;

		return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
	}
	let parsedValue: string;

	switch (operator) {
		case FilterOperators.CONTAINS:
		case FilterOperators.NOT_CONTAINS:
			parsedValue = `*${value}*`;
			break;
		case FilterOperators.STARTS_WITH:
			parsedValue = `${value}*`;
			break;
		case FilterOperators.ENDS_WITH:
			parsedValue = `*${value}`;
			break;
		case FilterOperators.IS_NULL:
			parsedValue = `${FilterOperators.IS_NULL}=${value}`;
			break;
		default:
			parsedValue = value;
	}

	if (typeof value !== 'number') {
		parsedValue = parsedValue.replace(/"/g, '\\"');
	}

	if (getShouldAddQuotes(value)) {
		return encodeURIComponent(`"${parsedValue}"`);
	}

	return encodeURIComponent(parsedValue);
};

const rsqlOperation = (operation: FilterOperators, value: any): Operation => {
	switch (operation) {
		case FilterOperators.EQ:
			return new Operation(parseValue(value), Operators.EQUAL);
		case FilterOperators.NE:
			return new Operation(parseValue(value), Operators.NOT_EQUAL);
		case FilterOperators.LT:
			return new Operation(parseValue(value), Operators.LESS_THAN);
		case FilterOperators.GT:
			return new Operation(parseValue(value), Operators.GREATER_THAN);
		case FilterOperators.LE:
			return new Operation(parseValue(value), Operators.LESS_OR_EQUAL);
		case FilterOperators.GE:
			return new Operation(parseValue(value), Operators.GREATER_OR_EQUAL);
		case FilterOperators.CONTAINS:
			return new Operation(parseValue(value, FilterOperators.CONTAINS), Operators.EQUAL);
		case FilterOperators.STARTS_WITH:
			return new Operation(parseValue(value, FilterOperators.STARTS_WITH), Operators.EQUAL);
		case FilterOperators.ENDS_WITH:
			return new Operation(parseValue(value, FilterOperators.ENDS_WITH), Operators.EQUAL);
		case FilterOperators.NOT_CONTAINS:
			return new Operation(parseValue(value, FilterOperators.NOT_CONTAINS), Operators.NOT_EQUAL);
		case FilterOperators.IS_NULL:
			return new Operation(parseValue(value, FilterOperators.IS_NULL), FilterOperators.EQ);
		case FilterOperators.IN:
			return { toString: () => `${FilterOperators.IN}(${value.join(',')})` } as Operation;
		default:
			return new Operation(parseValue(value), Operators.EQUAL);
	}
};

const buildFilters = (queryFilters?: IQueryFilter): string => {
	if (!queryFilters) {
		return '';
	}
	const queryMapper = queryFilters.map((queryFilter) => {
		const filters = queryFilter.filters.map(({ field, operator, value }) => {
			if (queryFilter.logic === 'and') {
				return and(comparison(field, rsqlOperation(operator, value)));
			}
			return or(comparison(field, rsqlOperation(operator, value)));
		});

		if (queryFilter.logic === 'and') {
			return `${filters.join(';')}`;
		}
		return `${filters.join(',')}`;
	});

	return queryMapper.map((query) => `(${query})`).join(',');
};

const buildSorts = (sortArr: IQuerySort[] | undefined): string[] => {
	if (!sortArr) {
		return [];
	}
	return sortArr.map(({ field, desc }) => `${field};${desc ? 'desc' : 'asc'}`);
};

const buildQuery = function <Entity extends Object>(
	queryParams: IQueryParams<RecursiveKeyOf<Entity>>
): URLSearchParams {
	const params = new URLSearchParams();

	const { skip, take, filter, distinct, sort, global, extraQueries } = queryParams;
	const sorts = buildSorts(sort);
	const filters = buildFilters(filter);

	if (skip) {
		params.append('skip', `${skip}`);
	}

	if (take) {
		params.append('take', `${take}`);
	}

	if (global) {
		params.append('globalSearch', `${global}`);
	}

	if (sorts.length) {
		sorts.forEach((sort) => params.append('sort', sort));
	}

	if (filters) {
		params.append('filter', '(' + filters + ')');
	}

	if (distinct) {
		params.append('distinct', `${distinct}`);
	}

	if (extraQueries) {
		Object.keys(extraQueries).forEach((extraQueryKey) => params.append(extraQueryKey, extraQueries[extraQueryKey]));
	}

	return params;
};

export { rsqlOperation, buildSorts, buildQuery, parseValue };
