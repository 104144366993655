import React from 'react';

interface IIconArrowDownProps {
	hasColor?: string;
}

const IconArrowDown = ({ hasColor = '#808191' }: IIconArrowDownProps) => {
	return (
		<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M9 1L5 5L1 1' stroke={hasColor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};

export default IconArrowDown;
