import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { ITournamentParticipantRanking } from '@features/gameplay/models/tournament-participant-ranking.model';
import { httpHandler } from '@http/httpHelpers';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IGameplayJoinGameResponse } from '../models/gameplay-join-game.model';
import { IReqSelectedAnswer } from '../models/req-selected-answer.model';
import { GamePlayActionTypes } from './gameplay.action-types';

export const subscribeForPlayTopic = createAsyncThunk(
	GamePlayActionTypes.SUBSCRIBE_FOR_PLAY_TOPIC,
	async (requestBody: { tournamentId: string }) => {
		try {
			const response = await httpHandler(axios.post('/tournaments/play', requestBody));

			return { data: response.data, tournamentId: requestBody.tournamentId };
		} catch (error) {
			throw error;
		}
	}
);

export const sendChosenAnswer = createAsyncThunk(
	GamePlayActionTypes.SEND_CHOSEN_ANSWER,
	({ captchaToken, ...body }: IReqSelectedAnswer) => {
		return httpHandler(
			axios.post('/game-round-participant-plays', body, {
				params: { 'g-recaptcha-response': captchaToken },
			})
		);
	}
);

export const fetchTournamentRanking = createAsyncThunk(
	GamePlayActionTypes.FETCH_TOURNAMENT_RANKING,
	(params: { tournamentId: string; take: number; skip: number }) => {
		return httpHandler<IPageableResponse<ITournamentParticipantRanking>>(
			axios.get('/game-round-participants/ranking', { params })
		);
	}
);

export const openGameplaySocket = createAction(GamePlayActionTypes.OPEN_GAMEPLAY_SOCKET);

export const fetchGameInfo = createAsyncThunk(GamePlayActionTypes.GAME_INFO, (tournamentId: string) => {
	return httpHandler<IGameplayJoinGameResponse>(axios.get(`/tournaments/${tournamentId}/game-info`));
});
