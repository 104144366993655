import IconArrowUp from '@features/common/components/IconArrowUp';
import useScrollDirection from '@hooks/useScrollDirection';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const LayoutScrollToTop = () => {
	const scrollDirection = useScrollDirection({ triggerDistancePercentage: 250 });

	const onScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<AnimatePresence mode='wait'>
			{scrollDirection === 'UP' ? (
				<motion.button
					className='rounded-full w-[55px] h-[55px] bg-white fixed bottom-[3%] right-[6%] transition-none lg:hidden flex justify-center items-center'
					style={{
						boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px',
						zIndex: '2'
					}}
					initial={{ opacity: 0, y: '-100%' }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: '-100%' }}
					transition={{ duration: 0.2 }}
					type='button'
					onClick={onScrollToTop}
					data-testid='scrollButtonToTop'
				>
					<IconArrowUp />
				</motion.button>
			) : null}
		</AnimatePresence>
	);
};

export default LayoutScrollToTop;
