import { getSelectedLanguage } from '@features/layout/store/layout.selectors';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { getCdnStaticUrl } from '../store/common.selectors';

const IconEmptyPage = () => {
	const cdnStaticUrl = useSelector(getCdnStaticUrl);
	const selectedLanguage = useSelector(getSelectedLanguage);

	return (
		<div className='flex h-full w-full items-center justify-center'>
			<div className='w-[189px] h-[182px] mb-8 relative md:hidden'>
				<Image
					alt='empty-page'
					src={`${cdnStaticUrl}/noResults_${selectedLanguage.toUpperCase()}.jpg`}
					unoptimized={true}
					objectFit='fill'
					quality={100}
					layout={'fill'}
				/>
			</div>
			<div className='w-[364px] h-[350px] mb-8 relative hidden md:block'>
				<Image
					unoptimized={true}
					alt='empty-page'
					src={`${cdnStaticUrl}/noResultsBig_${selectedLanguage.toUpperCase()}.jpg`}
					objectFit='fill'
					quality={100}
					layout={'fill'}
				/>
			</div>
		</div>
	);
};

export default IconEmptyPage;
