import { getAuthStateDetermined, getCurrentUser } from '@features/auth/store/auth.selectors';
import IconDice from '@features/common/components/IconDice';
import IconGamyLogoBlack from '@features/common/components/IconGamyLogoBlack';
import TabButton from '@features/common/components/TabButton';
import { disableScroll, enableScroll } from '@features/common/store/common.reducer';
import NotificationContainer from '@features/notifications/components/NotificationContainer';
import { useAppDispatch } from '@store/store';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import HeaderLanguage from './HeaderLanguage';
import HeaderProfile from './HeaderProfile';
import MobileMenu from './MobileMenu';
import PwaInstallPrompt from './PwaInstallPrompt';
import classNames from 'classnames';
import { setNavOpen } from '@features/layout/store/layout.reducer';

interface INavigationButtonTextProps {
	isIconExists: boolean;
	translationId: string;
}

const NavigationBarButtonsText = (props: INavigationButtonTextProps) => {
	const { formatMessage } = useIntl();
	const { isIconExists, translationId } = props;

	return (
		<p className={`h-5 ${isIconExists ? 'ml-2' : ''} text-headerColor-100`}>
			{formatMessage({
				id: translationId,
			})}
		</p>
	);
};

const Header = () => {
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();

	const currentUser = useSelector(getCurrentUser);
	const isAuthReady = useSelector(getAuthStateDetermined);
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);

	const toggleScroll = (toggle: boolean) => {
		if (toggle) {
			dispatch(enableScroll());
		} else {
			dispatch(disableScroll());
		}
	};

	useEffect(() => {
		dispatch(setNavOpen(navbarOpen));
		try {
			const contentWrap = document.getElementById('contentWrap');
			if (contentWrap) {
				if (navbarOpen) {
					document.body.classList.add('body-is-fullscreen');
				} else {
					document.body.classList.remove('body-is-fullscreen');
				}
			}
		} catch (e) {}
	}, [navbarOpen, dispatch]);

	return (
		<>
			{navbarOpen ? (
				<MobileMenu
					isAuthReady={isAuthReady}
					onClose={() => {
						setNavbarOpen(!navbarOpen);
						toggleScroll(navbarOpen);
					}}
					currentUser={currentUser}
				/>
			) : null}
			<nav className={`nav-wrap`} style={{ fontSize: '15px' }}>
				<div
					className='w-1/3'
					onClick={() => {
						setNotificationsOpen(false);
						setNavbarOpen(false);
						toggleScroll(true);
					}}
				>
					<div className='w-max'>
						<Link href={'/'}>
							<a>
								<div className='cursor-pointer'>
									<IconGamyLogoBlack />
								</div>
							</a>
						</Link>
					</div>
				</div>
				<div
					className={classNames(
						'sm:h-full lg:grid sm:grid-flow-col sm:auto-cols-max xl:gap-[0.5rem] gap-0 sm:justify-center hidden mr-2.5 xl:mr-0',
						{
							flex: navbarOpen,
							hidden: !navbarOpen,
						}
					)}
				>
					{isAuthReady ? (
						<>
							{currentUser ? (
								<>
									<TabButton LinkHref='/'>
										<Link href={`/#scrollOnGamys`} scroll={true}>
											<a data-testid='navDesktopGamysLink' className='flex items-center'>
												<IconDice />
												<NavigationBarButtonsText
													translationId='bcs-Header-WhatsHot'
													isIconExists={true}
												/>
											</a>
										</Link>
									</TabButton>
									<TabButton LinkHref={'/my-tournaments'}>
										<Link href={'/my-tournaments/registered'} passHref>
											<a data-testid='navDesktopMyGamysLink' className='flex sm:items-center'>
												<NavigationBarButtonsText
													translationId='bcs-Header-MyGamys'
													isIconExists={false}
												/>
											</a>
										</Link>
									</TabButton>
								</>
							) : (
								<>
									<TabButton LinkHref='/'>
										<Link href={`/#scrollOnGamys`} scroll={true}>
											<a data-testid='navDesktopGamysLink' className='flex items-center'>
												<IconDice />
												<NavigationBarButtonsText
													translationId='bcs-Common-Gamys'
													isIconExists={true}
												/>
											</a>
										</Link>
									</TabButton>
								</>
							)}
							<TabButton LinkHref={'/how-gamy-works'}>
								<Link href={'/how-gamy-works'} passHref>
									<a data-testid='navDesktopHowItWorksLink'>
										<NavigationBarButtonsText
											translationId='bcs-Header-HowGamyWorks'
											isIconExists={false}
										/>
									</a>
								</Link>
							</TabButton>
							<TabButton LinkHref={'/organize-a-gamy'}>
								<Link href={'/organize-a-gamy'} passHref>
									<a data-testid='navDesktopOrganizeLink'>
										<NavigationBarButtonsText
											translationId='bcs-Title-Organize'
											isIconExists={false}
										/>
									</a>
								</Link>
							</TabButton>
						</>
					) : null}
				</div>
				<div className='h-full lg:w-1/3 sm:justify-end flex items-center lg:min-w-[372px]'>
					<div className='lg:block hidden pr-[30px]'>
						<PwaInstallPrompt />
					</div>
					<HeaderLanguage />
					{isAuthReady ? (
						currentUser ? (
							<div className='flex h-full items-center'>
								<div
									className='flex h-full ml-[18px] mr-[19px]'
									onClick={() => {
										setNavbarOpen(false);
										setNotificationsOpen(true);
									}}
								>
									<NotificationContainer isNotificationMenuOpen={notificationsOpen} />
								</div>
								<div className='hidden lg:flex'>
									<HeaderProfile />
								</div>
							</div>
						) : (
							<div className='hidden sm:flex sm:space-x-6 h-full'>
								<div className='hidden lg:block lg:mr-3 lg:h-full'>
									<TabButton>
										<Link href={'/login'} passHref>
											<a data-testid='navDesktopLoginLink'>
												{formatMessage({
													id: 'bcs-Header-Login',
												})}
											</a>
										</Link>
									</TabButton>
								</div>
								<div className='hidden lg:block lg:h-full lg:mr-3'>
									<TabButton colorful={true}>
										<Link href={'/register'}>
											<a data-testid='navDesktopRegisterLink'>
												{formatMessage({
													id: 'bcs-Header-SignUp',
												})}
											</a>
										</Link>
									</TabButton>
								</div>
							</div>
						)
					) : null}
					<button
						className='focus:outline-none lg:hidden h-full w-7 ml-3'
						onClick={() => {
							setNavbarOpen(!navbarOpen);
							setNotificationsOpen(false);
							toggleScroll(navbarOpen);
						}}
						data-testid='hamburgerMobileMenuBtn'
					>
						<span
							className={`bg-headerColor-200 h-[3px] w-7 block transition-transform  ${
								navbarOpen ? 'rotate-45 translate-y-[3px] mb-0 rounded-[1px]' : 'mb-[9px] rounded-sm'
							}`}
						></span>
						<span
							className={`bg-headerColor-200 h-[3px] w-7 block transition-transform ${
								navbarOpen ? '-rotate-45 -translate-y-[8px] rounded-[1px]' : 'rounded-sm'
							}`}
						></span>
					</button>
				</div>
			</nav>
		</>
	);
};

export default Header;
