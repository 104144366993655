import React from 'react';

interface IColoredLineProps {
	className?: string;
}

const ColoredLine = ({ className }: IColoredLineProps) => {
	return (
		<div className={`grid grid-flow-col auto-cols-fr${className ? ` ${className}` : ''}`}>
			<span className='bg-razzmatazz-500' style={{ height: '5px' }}></span>
			<span className='bg-main-500'></span>
			<span style={{ height: '5px', background: '#FFBF01' }}></span>
			<span style={{ height: '5px', background: '#00E090' }}></span>
			<span style={{ height: '5px', background: '#00D2CF' }}></span>
			<span style={{ height: '5px', background: '#D234FF' }}></span>
		</div>
	);
};

export default ColoredLine;
