import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { IChampionshipsLoadingState } from '../models/championships-state-loading.model';
import {
	championshipPlayNowAdapter,
	championshipPlayNowAwardsAdapter,
	championshipsOneTimeAdapter,
	finishedChampionshipOneTimeAdapter,
} from './championships.reducer';

const championshipsSelector = ({ championships }: IApplicationState) => championships;
const championshipOneTimeSelector = ({ championships }: IApplicationState) => championships.championshipOneTime;
const championshipPlayNowSelector = ({ championships }: IApplicationState) => championships.championshipPlayNowAll;
const championshipPlayNowAwardsSelector = ({ championships }: IApplicationState) =>
	championships.championshipPlayNowAwards;

const finishedChampionshipsOneTimeByMerchantSelector = ({ championships }: IApplicationState) =>
	championships.finishedChampionshipOneTime;
const finishedChampionshipPlayNowAllByMerchantSelector = ({ championships }: IApplicationState) =>
	championships.finishedChampionshipPlayNowAll;

const championshipsOneTimeEntitySelectors = championshipsOneTimeAdapter.getSelectors();
const championshipsPlayNowAllEntitySelectors = championshipPlayNowAdapter.getSelectors();
const championshipsPlayNowAwardsEntitySelectors = championshipPlayNowAwardsAdapter.getSelectors();
const finishedChampionshipsOneTimeEntitySelectors = finishedChampionshipOneTimeAdapter.getSelectors();
const finishedChampionshipsPlayNowAllEntitySelectors = finishedChampionshipOneTimeAdapter.getSelectors();

export const getChampionshipOneTimeCount = createSelector(championshipOneTimeSelector, ({ total }) => total);
export const getChampionshipsPlayNowAllCount = createSelector(championshipPlayNowSelector, ({ total }) => total);
export const getChampionshipsPlayNowAwardsAllCount = createSelector(
	championshipPlayNowAwardsSelector,
	({ total }) => total
);
export const getFinishedChampionshipOneTimeAllCount = createSelector(
	finishedChampionshipsOneTimeByMerchantSelector,
	({ total }) => total
);
export const getFinishedChampionshipPlayNowAllCount = createSelector(
	finishedChampionshipPlayNowAllByMerchantSelector,
	({ total }) => total
);

export const getChampionshipsLoading = (loadingKey: keyof IChampionshipsLoadingState) =>
	createSelector(championshipsSelector, ({ loadingState }) => loadingState[loadingKey]);

export const getChampionById = (championshipId: string) =>
	createSelector(championshipsSelector, (state) =>
		championshipsOneTimeEntitySelectors.selectAll(state.championshipOneTime).find(({ id }) => id === championshipId)
	);

export const getChampionshipByMerchantId = (merchant_Id: string) =>
	createSelector(championshipsSelector, (state) =>
		championshipsOneTimeEntitySelectors
			.selectAll(state.championshipOneTime)
			.filter(({ merchantId }) => merchantId === merchant_Id)
	);

export const getWSSocketType = createSelector(championshipsSelector, ({ wsMessageType }) => wsMessageType);

export const getChampionshipsOneTimeAll = createSelector(
	championshipOneTimeSelector,
	championshipsOneTimeEntitySelectors.selectAll
);
export const getChampionshipsPlayNowAll = createSelector(
	championshipPlayNowSelector,
	championshipsPlayNowAllEntitySelectors.selectAll
);
export const getChampionshipsPlayNowAwardsAll = createSelector(
	championshipPlayNowAwardsSelector,
	championshipsPlayNowAwardsEntitySelectors.selectAll
);

export const getSelectedChampionship = createSelector(
	championshipsSelector,
	({ selectedChampionship }) => selectedChampionship
);

export const getShowChampionshipLeaderBoardButtonRefresh = createSelector(
	championshipsSelector,
	({ showChampionshipLeaderBoardButtonRefresh }) => showChampionshipLeaderBoardButtonRefresh
);

export const getFinishedChampionshipsOneTimeByMerchant = createSelector(
	finishedChampionshipsOneTimeByMerchantSelector,
	finishedChampionshipsOneTimeEntitySelectors.selectAll
);

export const getFinishedChampionshipsPlayNowAllByMerchant = createSelector(
	finishedChampionshipPlayNowAllByMerchantSelector,
	finishedChampionshipsPlayNowAllEntitySelectors.selectAll
);
