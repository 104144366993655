import { ICurrentUser } from '@features/auth/models/current-user.model';
import { logoutUser } from '@features/auth/store/auth.actions';
import AvatarIcon from '@features/common/components/AvatarIcon';
import IconDice from '@features/common/components/IconDice';
import PrimaryButton from '@features/common/components/PrimaryButton';
import SecondaryButton from '@features/common/components/SecondaryButton';
import ButtonLink from '@features/layout/components/ButtonLink';
import PwaInstaller from '@features/layout/components/PwaInstaller';
import { useAppDispatch } from '@store/store';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

interface IMobileMenuProps {
	isAuthReady: boolean;
	onClose: () => void;
	currentUser?: ICurrentUser | null;
}

const MobileMenu = (props: IMobileMenuProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const router = useRouter();

	const onLinkClick = (path: string) => {
		if (path === '/') {
			router.push(`${path}#scrollOnGamys`);
		} else {
			router.push(path);
		}

		props.onClose();
	};

	return (
		<div className='mobile-nav-wrap'>
			{props.isAuthReady ? (
				props.currentUser ? (
					<div className={'nav-items-container'}>
						<ButtonLink
							testId='hamburgerMobileWhatsHotBtn'
							onClick={() => onLinkClick('/')}
							buttonText='bcs-Header-WhatsHot'
							icon={<IconDice />}
						/>
						<ButtonLink
							testId='hamburgerMobileMyGamysBtn'
							onClick={() => onLinkClick('/my-tournaments/registered')}
							buttonText='bcs-Header-MyGamys'
						/>
						<ButtonLink
							testId='hamburgerMobileHowItWorksBtn'
							onClick={() => onLinkClick('/how-gamy-works')}
							buttonText='bcs-Header-HowGamyWorks'
						/>
						<ButtonLink
							testId='hamburgerMobileOrganizeBtn'
							onClick={() => onLinkClick('/organize-a-gamy')}
							buttonText='bcs-Title-Organize'
						/>
						<div className='w-auto ml-2 mt-13 mb-[2.9375rem]' style={{ border: '1px solid #E4E4E4' }} />
						<div className='flex items-center'>
							<div
								className='w-[51px] h-[51px] mr-5 border rounded-full'
								style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px' }}
							>
								<AvatarIcon
									avatarId={`${props.currentUser?.avatarId}`}
									key={props.currentUser?.avatarId}
									width='51px'
									height='51px'
									rounded={true}
									borderRadius='100%'
								/>
							</div>
							<p
								className='font-bold'
								style={{
									fontFamily: 'Montserrat',
									fontWeight: '600',
									fontStyle: 'normal',
									fontSize: '18px',
									lineHeight: '20px',
									color: '#808191',
								}}
							>
								{props.currentUser?.nickname}
							</p>
						</div>
						<button
							className='flex'
							data-testid='hamburgerMobileSettingsBtn'
							onClick={() => onLinkClick('/account-settings/general')}
						>
							<p
								className='ml-2 font-medium text-headerColor-100'
								style={{
									fontFamily: 'Montserrat',
									fontStyle: 'normal',
									fontSize: '18px',
									lineHeight: '44px',
									letterSpacing: '-1px',
								}}
							>
								{formatMessage({
									id: 'bcs-User-Profile-Account-Settings',
								})}
							</p>
						</button>
						<button
							className='flex'
							data-testid='hamburgerMobileLogoutBtn'
							onClick={() => {
								dispatch(logoutUser());
								props.onClose();
							}}
						>
							<p
								className='ml-2 font-medium text-headerColor-100'
								style={{
									fontFamily: 'Montserrat',
									fontStyle: 'normal',
									fontSize: '18px',
									lineHeight: '44px',
									letterSpacing: '-1px',
								}}
							>
								{formatMessage({ id: 'bcs-Header-SignOut' })}
							</p>
						</button>
						<PwaInstaller />
					</div>
				) : (
					<div className={'nav-items-container'}>
						<ButtonLink
							testId='hamburgerMobileGamysBtn'
							onClick={() => onLinkClick('/')}
							buttonText='bcs-Common-Gamys'
							icon={<IconDice />}
						/>
						<ButtonLink
							testId='hamburgerMobileHowItWorksBtn'
							onClick={() => onLinkClick('/how-gamy-works')}
							buttonText='bcs-Header-HowGamyWorks'
						/>
						<ButtonLink
							testId='hamburgerMobileOrganizeBtn'
							onClick={() => onLinkClick('/organize-a-gamy')}
							buttonText='bcs-Title-Organize'
						/>
						<div className='flex content-between mt-20 space-x-4'>
							<PrimaryButton
								type='button'
								testId='hamburgerMobileRegisterBtn'
								tabIndex={2}
								onClick={() => onLinkClick('/register')}
							>
								{formatMessage({ id: 'bcs-Header-SignUp' })}
							</PrimaryButton>
							<SecondaryButton
								testid='hamburgerMobileLoginBtn'
								type='button'
								tabIndex={2}
								onClick={() => onLinkClick('/login')}
							>
								{formatMessage({ id: 'bcs-Header-Login' })}
							</SecondaryButton>
						</div>
						<PwaInstaller />
					</div>
				)
			) : null}
		</div>
	);
};

export default MobileMenu;
