import { IEventParams } from 'model/global.model';
import { useCallback } from 'react';

const useFacebookPixel = () => {
	const sendFacebookPixelEvent = useCallback(({ eventName, params }: IEventParams) => {
		try {
			fbq('trackCustom', eventName, params);
		} catch (error) {
			console.error('NO FACEBOOK PIXEL SCRIPT AVAILABLE');
		}
	}, []);

	return { sendFacebookPixelEvent };
};

export default useFacebookPixel;
