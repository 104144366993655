import { IEventParams } from 'model/global.model';
import { useCallback } from 'react';

const useGoogleAnalytics = () => {
	const sendGoogleAnalyticsEvent = useCallback(({ eventName, params }: IEventParams) => {
		try {
			gtag('event', eventName, params);
		} catch (error) {
			console.error('NO GOOGLE ANALYTICS SCRIPT AVAILABLE');
		}
	}, []);

	return { sendGoogleAnalyticsEvent };
};

export default useGoogleAnalytics;
