import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { RecursiveKeyOf } from '@features/common/models/recursiveKeyof.model';
import { httpHandler } from '@http/httpHelpers';
import { IQueryParams, buildQuery } from '@http/queryCreators';
import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { IApplicationState } from '@store/root.reducer';
import axios from 'axios';
import { INotification, NotificationStatus } from '../models/notification.model';
import { NotificationsActionTypes } from './notifications.action-types';

const endpoint = '/notifications';

export const readNotification = createAsyncThunk(
	NotificationsActionTypes.READ_NOTIFICATION,
	(notificationId: string) => {
		const readNotificationChanges: Update<INotification> = {
			id: notificationId,
			changes: { status: NotificationStatus.READ },
		};

		return httpHandler<Update<INotification>>(axios.patch(`${endpoint}/${notificationId}/read`)).then(
			() => readNotificationChanges
		);
	}
);

export const unreadNotification = createAsyncThunk(
	NotificationsActionTypes.UNREAD_NOTIFICATION,
	(notificationId: string) => {
		const readNotificationChanges: Update<INotification> = {
			id: notificationId,
			changes: { status: NotificationStatus.UNREAD },
		};

		return httpHandler<Update<INotification>>(axios.patch(`${endpoint}/${notificationId}/unread`)).then(
			() => readNotificationChanges
		);
	}
);

export const readAllNotification = createAsyncThunk(
	NotificationsActionTypes.READ_ALL_NOTIFICATIONS,
	(_, { getState }) => {
		const state = getState() as IApplicationState;
		const readNotificationsChanges: Update<INotification>[] = state.notifications.ids.map((id) => ({
			id,
			changes: { status: NotificationStatus.READ },
		}));

		return httpHandler<void>(axios.patch(`${endpoint}/read`)).then(() => readNotificationsChanges);
	}
);

export const fetchUnreadCountNotifications = createAsyncThunk(
	NotificationsActionTypes.FETCH_UNREAD_COUNT_NOTIFICATIONS,
	() => {
		return httpHandler<number>(axios.get(`${endpoint}/unread-count`));
	}
);

export const fetchAllNotifications = createAsyncThunk(
	NotificationsActionTypes.FETCH_NOTIFICATIONS,
	(queryParams: IQueryParams<RecursiveKeyOf<INotification>>) => {
		return httpHandler<IPageableResponse<INotification>>(
			axios.get(`${endpoint}`, { params: buildQuery(queryParams) })
		);
	}
);
