import { IGameplayJoinGameResponse } from '@features/gameplay/models/gameplay-join-game.model';

export declare type GameCurrentScreen =
	| 'STARTING' // transitions to -> QUESTION
	| 'QUESTION' // transitions to -> QUESTION_RESULTS
	| 'QUESTION_RESULTS' // transitions to -> QUESTION or QUESTION_STATISTICS
	| 'PODIUM' // transitions to -> RANKING
	| 'RANKING'
	| 'UNDETERMINED';

export const determineGameState = (gameInfo: IGameplayJoinGameResponse): GameCurrentScreen => {
	const { playNumber, tournamentStatusId, questionResource, results } = gameInfo;

	if (playNumber === 0) {
		return 'STARTING';
	}

	if (playNumber !== 0 && questionResource !== null) {
		if (results === null) {
			return 'QUESTION';
		} else {
			return 'QUESTION_RESULTS';
		}
	}

	if (tournamentStatusId === 'FINISHED') {
		return 'RANKING';
	}

	return 'UNDETERMINED';
};
