import { hideToast } from '@features/common/store/common.reducer';
import { useAppDispatch } from '@store/store';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IToast from '../models/toast.model';
import IconCheckSign from './IconCheckSign';
import IconCloseModal from './IconCloseModal';
import IconWarningSign from './IconWarningSign';
import PrimaryButton from './PrimaryButton';

interface IToastProps {
	toast: IToast;
}

const toastAlert = withReactContent(Swal);

const Toast = ({ toast }: IToastProps) => {
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { formatMessage } = useIntl();
	const [isSessionExpired] = useState(toast.message === formatMessage({ id: 'bcs-Auth-LoginSessionExpired' }));

	const onButtonClick = useCallback(() => {
		dispatch(hideToast());
		toastAlert.close();

		if (toast.redirect) {
			router.push(toast.redirect);
		}
	}, [dispatch, router, toast.redirect]);

	const onCloseButtonClick = useCallback(() => {
		dispatch(hideToast());
		toastAlert.close();
	}, [dispatch]);

	const onToastClosed = useCallback(() => {
		dispatch(hideToast());
	}, [dispatch]);

	const showToast = useCallback(() => {
		toastAlert.fire({
			html: (
				<div className='flex flex-col items-center' data-testid='toastContainer'>
					<div className='flex justify-between items-center w-full'>
						<p
							className='font-semibold text-2xl text-dark-500'
							style={{ fontFamily: "'Montserrat', normal" }}
							data-testid='toastMessage'
						>
							{toast.title}
						</p>
						<button type='button' data-testid='closeButton' onClick={onCloseButtonClick}>
							<IconCloseModal animate={toast.autoClose || isSessionExpired} />
						</button>
					</div>
					{toast.type === 'success' ? <IconCheckSign /> : <IconWarningSign />}
					<p className='mt-5 mb-5 text-lg text-center text-dark-500 font-medium'>{toast.message}</p>
					{toast.buttonText ? (
						<div className='w-40'>
							<PrimaryButton type='button' tabIndex={1} onClick={onButtonClick}>
								{toast.buttonText}
							</PrimaryButton>
						</div>
					) : null}
				</div>
			),
			showConfirmButton: false,
			showCancelButton: false,
			showCloseButton: false,
			showDenyButton: false,
			showLoaderOnConfirm: false,
			showLoaderOnDeny: false,
			heightAuto: false,
			customClass: {
				popup: 'alert-border-radius mt-[4%]',
			},
			padding: '0px 0px 15px',
			position: isMobile ? 'center' : 'top',
			timer: isSessionExpired || toast.autoClose ? 3000 : undefined,
			didClose: onToastClosed,
		});
	}, [
		isSessionExpired,
		onButtonClick,
		onCloseButtonClick,
		onToastClosed,
		toast.autoClose,
		toast.buttonText,
		toast.message,
		toast.title,
		toast.type,
	]);

	useEffect(() => {
		showToast();
	}, [showToast]);

	return null;
};

export default Toast;
