import { logoutUser } from '@features/auth/store/auth.actions';
import {
	emitChampionshipWSnewChampionship,
	emitChampionshipWSnewChampionshipStatus,
} from '@features/championships/store/championships.reducer';
import { IWSGameplaySocketMessages } from '@features/gameplay/models/gameplay-socket-messages.model';
import { openGameplaySocket, subscribeForPlayTopic } from '@features/gameplay/store/gameplay.actions';
import {
	checkAnswer,
	participantAnswered,
	participantJoin,
	participantLeft,
	setGameplaySocketIsOpened,
	setQuestion,
	setQuestionResults,
	showPodium,
	startGame,
	unsubscribeForPlayTopic,
	wsLobbyParticipantsJoin,
	wsUpdateTournamentStatus,
} from '@features/gameplay/store/gameplay.reducer';
import { IReward } from '@features/tournaments/models/tournament-reward.model';
import { IWSTournamentSocketMessages } from '@features/tournaments/models/tournament-socket-messages.model';
import { ITournamentFull, ITournamentWithTranslations } from '@features/tournaments/models/tournament.model';
import { wsTournamentParticipantRegistered } from '@features/tournaments/store/tournaments.actions';
import {
	emitTournamentsRemoveAll,
	emitTournamentsWSFinishSelectedTournament,
	emitTournamentsWSnewTournaments,
	emitTournamentsWSstatusChanged,
	emitTournamentsWSsubscribe,
} from '@features/tournaments/store/tournaments.reducer';
import { RxStompState } from '@stomp/rx-stomp';
import { resetState } from '@store/root.actions';
import { wsLogger } from '@utils/wsLogger';
import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
	catchError,
	delayWhen,
	filter,
	map,
	mergeMap,
	switchMap,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { IReduxObservableEpic } from './models/epic.model';
import RxStompSocket from './rxStompSocket';

type ILocales = Record<'selectedLanguage' | 'defaultLanguage', string>;

export const rxStompGameplay = new RxStompSocket({ heartBeatTimeMs: 25000 });
const gameplayEpics: IReduxObservableEpic[] = [];

const buildReward = (reward: IReward, locales: ILocales): IReward => {
	const { defaultLanguage, selectedLanguage } = locales;
	const { award, ...restReward } = reward;
	const emptyRewardTranslation = { locale: '', description: '', name: '' };

	const selectedLanguageTranslation = award.translations.find(({ locale }) => locale === selectedLanguage);
	const defaultLanguageTranslation = award.translations.find(({ locale }) => locale === defaultLanguage);

	Object.assign(emptyRewardTranslation, defaultLanguageTranslation, selectedLanguageTranslation);

	return {
		...restReward,
		award: { ...award, translation: emptyRewardTranslation, translations: [emptyRewardTranslation] },
	};
};

const buildTournamentWithTranslation = (tournament: ITournamentWithTranslations, locales: ILocales) => {
	const { selectedLanguage, defaultLanguage } = locales;

	const rewardWithTranslations: IReward[] = [];
	const { translations, rewards, ...restOfTournament } = tournament;
	const tournamentTranslation: ITournamentFull['translation'] = {
		locale: '',
		name: '',
		description: '',
		promoPage: '',
	};

	const selectedLanguageTranslation = translations.find((translation) => translation.locale === selectedLanguage);
	const defaultLanguageTranslation = translations.find((translation) => translation.locale === defaultLanguage);
	Object.assign(tournamentTranslation, defaultLanguageTranslation, selectedLanguageTranslation);

	if (Array.isArray(rewards)) {
		rewardWithTranslations.push(...rewards.map((reward) => buildReward(reward, locales)));
	}

	const newTournamentForState: ITournamentFull = {
		...restOfTournament,
		rewards: rewardWithTranslations,
		translations: [tournamentTranslation],
		translation: tournamentTranslation,
		registered: false,
		name: tournamentTranslation.name,
		description: tournamentTranslation.description,
		awardName: rewardWithTranslations.length > 0 ? rewardWithTranslations[0].award.translation.name : null,
	};

	return newTournamentForState;
};

const openGameplaySocketEpic: IReduxObservableEpic = (actions$) =>
	actions$.pipe(
		ofType(openGameplaySocket.type),
		switchMap(() => {
			if (!rxStompGameplay.active) {
				rxStompGameplay.openConnection({ url: '/gaming' });
				return of(setGameplaySocketIsOpened(true)).pipe(
					tap(() => {
						if (rxStompGameplay.connectionState$.value !== RxStompState.OPEN) {
							rxStompGameplay.openConnection({ url: '/gaming' });
						}
					})
				);
			} else {
				return of(setGameplaySocketIsOpened(true));
			}
		}),
		catchError((error) => {
			return of(setGameplaySocketIsOpened(false));
		})
	);

const subscribeForPlayTopicEpic: IReduxObservableEpic = (actions$) =>
	actions$.pipe(
		ofType(subscribeForPlayTopic.fulfilled.type),
		filter((action) => {
			const { token, participantId, gameRoundId } = action.payload.data;

			if (token === null && participantId === null && gameRoundId === null) {
				return false;
			}

			return true;
		}),
		delayWhen(() => rxStompGameplay.connectionState$.pipe(filter((state) => state === RxStompState.OPEN))),
		switchMap(
			({
				payload: {
					data: { token, participantId, gameRoundId },
				},
			}) =>
				rxStompGameplay
					.watch(`/tournament/play/${gameRoundId}`, {
						token,
						participantId,
					})
					.pipe(
						takeUntil(
							actions$.pipe(
								ofType(unsubscribeForPlayTopic.type, resetState.type, logoutUser.fulfilled.type)
							)
						)
					)
		),
		map(({ body }) => JSON.parse(body)),
		tap((message) => wsLogger(message, 'gameplay')),
		mergeMap((payload: IWSGameplaySocketMessages) => {
			switch (payload.type) {
				case 'PARTICIPANT_JOINED':
					return [participantJoin(payload)];
				case 'PARTICIPANT_LEFT':
					return [participantLeft(payload)];
				case 'QUESTION_SENT':
					return [setQuestion(payload)];
				case 'START_GAME':
					return [startGame(payload)];
				case 'CHECK_ANSWER':
					return [checkAnswer(payload)];
				case 'PARTICIPANT_ANSWERED':
					return [participantAnswered(payload)];
				case 'QUESTION_RESULTS':
					return [setQuestionResults(payload)];
				case 'TOURNAMENT_OUTCOME':
					return [showPodium(payload)];
			}
		}),
		catchError((error) => {
			console.log('Error WS', error);
			return of(error);
		})
	);

const subscribeForTournamentTopicEpic: IReduxObservableEpic = (actions$, state$) =>
	actions$.pipe(
		ofType(emitTournamentsWSsubscribe.type),
		delayWhen(() => rxStompGameplay.connectionState$.pipe(filter((state) => state === RxStompState.OPEN))),
		switchMap(() =>
			rxStompGameplay
				.watch('/tournament/events')
				.pipe(takeUntil(actions$.pipe(ofType(emitTournamentsRemoveAll.type))))
		),
		withLatestFrom(
			state$.pipe(
				map(({ layout: { selectedLanguage }, common: { defaultLanguage } }) => ({
					selectedLanguage,
					defaultLanguage,
				}))
			)
		),
		map(([{ body }, locales]) => ({ payload: JSON.parse(body), locales })),
		tap((message) => wsLogger(message, 'gameplay')),
		mergeMap(({ payload, locales }: { payload: IWSTournamentSocketMessages; locales: ILocales }) => {
			switch (payload.type) {
				case 'NEW_TOURNAMENT': {
					return [
						emitTournamentsWSnewTournaments({
							type: payload.type,
							data: payload.data.map((tournament) => buildTournamentWithTranslation(tournament, locales)),
						}),
					];
				}
				case 'PARTICIPANT_REGISTERED':
					return [wsTournamentParticipantRegistered(payload), wsLobbyParticipantsJoin(payload)];
				case 'TOURNAMENT_STATUS_UPDATE':
					return [emitTournamentsWSstatusChanged(payload), wsUpdateTournamentStatus(payload)];
				case 'NEW_CHAMPIONSHIP':
					return [emitChampionshipWSnewChampionship(payload)];
				case 'CHAMPIONSHIP_STATUS_UPDATE':
					const actions: AnyAction[] = [emitChampionshipWSnewChampionshipStatus(payload)];
					if (payload.data.status === 'FINISHED_HIDDEN' || payload.data.status === 'FINISHED_VISIBLE') {
						actions.push(
							emitTournamentsWSFinishSelectedTournament({ championshipIds: payload.data.championshipIds })
						);
					}
					return actions;
			}
		})
	);

gameplayEpics.push(openGameplaySocketEpic, subscribeForPlayTopicEpic, subscribeForTournamentTopicEpic);

export default gameplayEpics;
