import { INamedObject } from '@features/common/models/namedObject.model';

export const parseToRelativeTime = (scheduledDate: Date, isNowOrLessThanMinute: boolean): INamedObject<string> => {
	const seconds = Math.abs(new Date(Date().toLocaleString()).getTime() - scheduledDate.getTime()) / 1000;

	if (seconds < 60 && isNowOrLessThanMinute) {
		return { id: 'bcs-Common-TimeNow', name: Math.floor(seconds).toString() };
	}

	if (seconds < 60 && !isNowOrLessThanMinute) {
		return { id: 'bcs-Common-LessThanMinute', name: Math.floor(seconds).toString() };
	}

	const minutes = seconds / 60;

	if (minutes < 60) {
		return { id: 'bcs-Common-TimeMinute', name: Math.floor(minutes).toString() };
	}

	const hours = minutes / 60;

	if (hours < 24) {
		return { id: 'bcs-Common-TimeHour', name: Math.floor(hours).toString() };
	}

	const days = hours / 24;

	if (days < 30) {
		return { id: 'bcs-Common-TimeDay', name: Math.floor(days).toString() };
	}

	const months = days / 30;

	if (months < 12) {
		return { id: 'bcs-Common-TimeMonth', name: Math.floor(months).toString() };
	}

	const years = months / 12;

	return { id: 'bcs-Common-TimeYear', name: Math.floor(years).toString() };
};

const useParseToRelativeTime = () => {
	return parseToRelativeTime;
};

export default useParseToRelativeTime;
