import React from 'react';

const IconArrowUp = () => {
	return (
		<svg width='26' height='15' viewBox='0 0 26 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2 13L13 2L24 13'
				stroke='#3C3C3C'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default IconArrowUp;
