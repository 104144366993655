export enum AuthActionTypes {
	LOGIN = '[AUTH] - Login',
	REGISTER = '[AUTH] - Register',
	FETCH_CURRENT_USER = '[AUTH] - Fetch Current User',
	FETCH_ALL_AVATARS = '[AUTH] - Fetch All Avatars',
	FORGOT_PASSWORD = '[AUTH] - Forgot Password',
	ADD_PASSWORD = '[AUTH] - Add password',
	RESET_PASSWORD = '[AUTH] - Reset Password',
	UPDATE_PROFILE = '[AUTH] - Update profile',
	UPDATE_PASSWORD = '[AUTH] - Update password',
	SET_PASSWORD = '[AUTH] - Set password',
	SEND_VERIFICATION_EMAIL = '[AUTH] - Send verification email',
	VERIFY_REGISTRATION = '[AUTH] - verify registration',
	GENERATE_NICKNAME = '[AUTH] - generate nickname',
	FETCH_NOTIFICATION_SETTINGS = '[AUTH] - fetch notification settings',
	PUT_NOTIFICATION_SETTINGS = '[AUTH] - put notification settings',
	CHANGE_LANGUAGE = '[AUTH] - Change language',
	LOGOUT = '[AUTH] - Logout',
	SET_SPECIAL_SETTINGS = '[AUTH] - Special Settings',
}
