import React from 'react';

const GrayIconClose = () => {
	return (
		<div>
			<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect
					x='0.111908'
					y='12.208'
					width='17.0507'
					height='1.82686'
					rx='0.913432'
					transform='rotate(-45 0.111908 12.208)'
					fill='#9A9A9A'
				/>
				<rect
					x='12.1686'
					y='13.5'
					width='17.0507'
					height='1.82686'
					rx='0.913432'
					transform='rotate(-135 12.1686 13.5)'
					fill='#9A9A9A'
				/>
			</svg>
		</div>
	);
};

export default GrayIconClose;
