import React, { CSSProperties } from 'react';

interface IIconThreeDotsProps {
	iconColor: 'white' | 'black' | 'gray';
	className?: string;
	style?: CSSProperties;
}

const IconThreeDots = ({ iconColor, className, style }: IIconThreeDotsProps) => {
	return (
		<svg
			width='18'
			height='5'
			viewBox='0 0 18 5'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
			style={style}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.81445 2.76758C3.81445 3.8031 2.97498 4.64258 1.93945 4.64258C0.903931 4.64258 0.0644531 3.8031 0.0644531 2.76758C0.0644531 1.73206 0.903931 0.892578 1.93945 0.892578C2.97498 0.892578 3.81445 1.73206 3.81445 2.76758ZM10.605 2.76758C10.605 3.8031 9.7655 4.64258 8.72998 4.64258C7.69446 4.64258 6.85498 3.8031 6.85498 2.76758C6.85498 1.73206 7.69446 0.892578 8.72998 0.892578C9.7655 0.892578 10.605 1.73206 10.605 2.76758ZM15.5205 4.64258C16.556 4.64258 17.3955 3.8031 17.3955 2.76758C17.3955 1.73206 16.556 0.892578 15.5205 0.892578C14.485 0.892578 13.6455 1.73206 13.6455 2.76758C13.6455 3.8031 14.485 4.64258 15.5205 4.64258Z'
				fill={iconColor}
			/>
		</svg>
	);
};

export default IconThreeDots;
