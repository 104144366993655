import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IMyTournament } from '../models/myTournament.model';
import { IMyTournamentsState } from '../models/myTournaments-state.model';
import {
	fetchMyTournamentsHistory,
	fetchTournamentInfo,
	fetchTournamentPodiumParticipants,
} from './myTournaments.actions';

export const myTournamentsAdapter = createEntityAdapter<IMyTournament>({
	selectId: (myGamy) => myGamy.tournamentId,
});

export const initialMyTournamentsState = myTournamentsAdapter.getInitialState<IMyTournamentsState>({
	ids: [],
	entities: {},
	loadingStates: { fetchMyGamysHistory: false, fetchTournamentInfo: false, fetchTournamentPodiumParticipants: false },
	totalPlayedGamys: 0,
	tournamentInfo: null,
	totalParticipants: 0,
	tournamentPodiumParticipants: [],
});

const myTournamentsSlice = createSlice({
	name: '[MY_GAMYS] - ',
	initialState: initialMyTournamentsState,
	reducers: {
		clearAllMyTournaments: (state) => {
			state.totalPlayedGamys = 0;
			myTournamentsAdapter.removeAll(state);
		},
		clearTournamentLeaderboard: (state) => {
			state.tournamentInfo = null;
			state.totalParticipants = 0;
			state.tournamentPodiumParticipants = [];
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchMyTournamentsHistory.pending, (state) => {
			state.loadingStates.fetchMyGamysHistory = true;
		});
		addCase(fetchMyTournamentsHistory.fulfilled, (state, { payload }) => {
			state.loadingStates.fetchMyGamysHistory = false;
			state.totalPlayedGamys = payload.data.total;
			myTournamentsAdapter.addMany(state, payload.data.result);
		});
		addCase(fetchMyTournamentsHistory.rejected, (state) => {
			state.loadingStates.fetchMyGamysHistory = false;
		});
		addCase(fetchTournamentInfo.pending, (state) => {
			state.loadingStates.fetchTournamentInfo = true;
		});
		addCase(fetchTournamentInfo.fulfilled, (state, { payload }) => {
			state.tournamentInfo = payload.data;
			state.loadingStates.fetchTournamentInfo = false;
		});
		addCase(fetchTournamentInfo.rejected, (state) => {
			state.loadingStates.fetchTournamentInfo = false;
		});
		addCase(fetchTournamentPodiumParticipants.pending, (state) => {
			state.loadingStates.fetchTournamentPodiumParticipants = true;
		});
		addCase(fetchTournamentPodiumParticipants.fulfilled, (state, { payload }) => {
			state.totalParticipants = payload.data.total;
			state.tournamentPodiumParticipants = payload.data.result;
			state.loadingStates.fetchTournamentPodiumParticipants = false;
		});
		addCase(fetchTournamentPodiumParticipants.rejected, (state) => {
			state.loadingStates.fetchTournamentPodiumParticipants = false;
		});
	},
});
export const myTournamentsReducer = myTournamentsSlice.reducer;
export const { clearAllMyTournaments, clearTournamentLeaderboard } = myTournamentsSlice.actions;
