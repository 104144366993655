import IconEmail from '@features/common/components/IconEmail';
import IconFacebookColored from '@features/common/components/IconFacebookColored';
import IconGoogleColored from '@features/common/components/IconGoogleColored';
import { useAppDispatch } from '@store/store';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { updateSwitchToPage } from '../store/auth.reducer';
import { getVerificationStatus } from '../store/auth.selectors';
import AuthFormWrapper from './AuthFormWrapper';

const Login = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const status = useSelector(getVerificationStatus);

	const navigateToLogin = () => {
		dispatch(updateSwitchToPage('Login With Email'));
	};

	return (
		<AuthFormWrapper
			pathForBack='/'
			title={formatMessage({ id: 'bcs-Header-Login' })}
			description={`${formatMessage({ id: 'bcs-Auth-NewUser' })}?`}
			link={{
				href: '/register',
				name: formatMessage({ id: 'bcs-Auth-CreateAnAccount' }),
				testId: 'loginCreateAccountLink',
			}}
		>
			<div className='grid grid-flow-row gap-2 text-white text-sm' style={{ fontFamily: "'Inter', sans-serif" }}>
				<a data-testid='loginWithFacebookLink' href='/api/v1/oauth2/authorization/facebook'>
					<div className='bg-dark-600 flex px-2 rounded-2xl h-14 items-center cursor-pointer'>
						<IconFacebookColored />
						<p className='flex-1 text-center'>
							{formatMessage({
								id: 'bcs-Auth-ContinueWithFacebook',
							})}
						</p>
					</div>
				</a>
				<a data-testid='loginWithGoogleLink' href='/api/v1/oauth2/authorization/google'>
					<div
						className='bg-dark-600 flex rounded-2xl h-14 items-center cursor-pointer'
						style={{ padding: '0 10px' }}
					>
						<IconGoogleColored />
						<p className='flex-1 text-center'>
							{formatMessage({
								id: 'bcs-Auth-ContinueWithGoogle',
							})}
						</p>
					</div>
				</a>
				{status !== 'Unknown User' ? (
					<Link href={'/login-email'} passHref>
						<a data-testid='loginWithEmailLink'>
							<div className='bg-dark-600 flex px-4 rounded-2xl h-14 items-center cursor-pointer'>
								<IconEmail />
								<p className='flex-1 text-center'>
									{formatMessage({
										id: 'bcs-Auth-ContinueWithEmail',
									})}
								</p>
							</div>
						</a>
					</Link>
				) : (
					<button
						className='bg-dark-600 flex px-4 rounded-2xl h-14 items-center cursor-pointer'
						onClick={navigateToLogin}
					>
						<IconEmail />
						<p className='flex-1 text-center'>
							{formatMessage({
								id: 'bcs-Auth-ContinueWithEmail',
							})}
						</p>
					</button>
				)}
			</div>
		</AuthFormWrapper>
	);
};

export default Login;
