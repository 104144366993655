import { IAuthState } from '@features/auth/models/auth-state.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	loginUser,
	registerUser,
	updateProfile,
	fetchCurrentUser,
	logoutUser,
	updatePassword,
	sendVerificationEmail,
	forgotPassword,
	resetPassword,
	fetchAllAvatars,
	verifyRegistration,
	generateNickname,
	addPassword,
	setPassword,
	fetchNotificationSettings,
	putNotificationSettings,
	changeUserLanguage,
} from './auth.actions';
import { IVerificationStatuses } from '../models/auth-verification-type-statuses.model';
import { AuthNavigationThroughModal } from '../models/auth-switch-to-state.model';

export const initialAuthState: IAuthState = {
	loadingStates: {
		fetchCurrentUser: false,
		forgotPassword: false,
		loginUser: false,
		logoutUser: false,
		registerUser: false,
		resetPassword: false,
		sendVerificationEmail: false,
		updatePassword: false,
		updateProfile: false,
		verifyRegistration: false,
		generateNickname: false,
		fetchAllAvatars: false,
		addPassword: false,
		setPassword: false,
		fetchNotificationSettings: false,
		changeUserLanguage: false,
	},
	authStateDetermined: false,
	avatars: null,
	user: null,
	randomNickname: null,
	notificationSettings: [],
	verificationStatus: null,
	authNavigationThroughModal: null,
};

const authSlice = createSlice({
	name: '[AUTH] - ',
	initialState: initialAuthState,
	reducers: {
		clearRandomNickname: (state) => {
			state.randomNickname = null;
		},
		updateCurrentUserSocialStatus: (state, { payload }: PayloadAction<boolean>) => {
			if (state.user) {
				state.user.socialLoginOnly = payload;
			}
		},
		updateFirstSocialLoginStatus: (state, { payload }: PayloadAction<boolean>) => {
			if (state.user) {
				state.user.notificationNewGamysWithRewards = payload;
			}
		},
		updateChampionshipStatusModal: (state, { payload }: PayloadAction<boolean>) => {
			if (state.user) {
				state.user.notificationNewChampionshipsWithRewards = payload;
			}
		},
		updateVerificationStatus: (state, action: PayloadAction<IVerificationStatuses>) => {
			state.verificationStatus = action.payload;
		},
		updateSwitchToPage: (state, action: PayloadAction<AuthNavigationThroughModal>) => {
			state.authNavigationThroughModal = action.payload;
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(loginUser.pending, (state) => {
			state.loadingStates.loginUser = true;
		});
		addCase(loginUser.fulfilled, (state) => {
			state.loadingStates.loginUser = false;
		});
		addCase(loginUser.rejected, (state) => {
			state.loadingStates.loginUser = false;
		});
		addCase(registerUser.pending, (state) => {
			state.loadingStates.registerUser = true;
		});
		addCase(registerUser.fulfilled, (state) => {
			state.loadingStates.registerUser = false;
		});
		addCase(registerUser.rejected, (state) => {
			state.loadingStates.registerUser = false;
		});
		addCase(updateProfile.pending, (state) => {
			state.loadingStates.updateProfile = true;
		});
		addCase(updateProfile.fulfilled, (state, action) => {
			state.loadingStates.updateProfile = false;
			if (state.user) {
				const { avatarId, email, id, nickname } = action.payload.data;

				state.user = { ...state.user, avatarId, email, id, nickname };
			}
		});
		addCase(updateProfile.rejected, (state) => {
			state.loadingStates.updateProfile = false;
		});
		addCase(fetchCurrentUser.pending, (state) => {
			state.loadingStates.fetchCurrentUser = true;
		});
		addCase(fetchCurrentUser.fulfilled, (state, { payload: { data } }) => {
			state.user = data;
			state.loadingStates.fetchCurrentUser = false;
			state.authStateDetermined = true;
		});
		addCase(fetchCurrentUser.rejected, (state) => {
			state.loadingStates.fetchCurrentUser = false;
			state.authStateDetermined = true;
		});
		addCase(forgotPassword.pending, (state) => {
			state.loadingStates.forgotPassword = true;
		});
		addCase(forgotPassword.fulfilled, (state) => {
			state.loadingStates.forgotPassword = false;
		});
		addCase(forgotPassword.rejected, (state) => {
			state.loadingStates.forgotPassword = false;
		});
		addCase(addPassword.pending, (state) => {
			state.loadingStates.addPassword = true;
		});
		addCase(addPassword.fulfilled, (state) => {
			state.loadingStates.addPassword = false;
		});
		addCase(addPassword.rejected, (state) => {
			state.loadingStates.addPassword = false;
		});
		addCase(resetPassword.pending, (state) => {
			state.loadingStates.resetPassword = true;
		});
		addCase(resetPassword.fulfilled, (state) => {
			state.loadingStates.resetPassword = false;
		});
		addCase(resetPassword.rejected, (state) => {
			state.loadingStates.resetPassword = false;
		});
		addCase(setPassword.pending, (state) => {
			state.loadingStates.setPassword = true;
		});
		addCase(setPassword.fulfilled, (state) => {
			state.loadingStates.setPassword = false;
		});
		addCase(setPassword.rejected, (state) => {
			state.loadingStates.setPassword = false;
		});
		addCase(logoutUser.pending, (state) => {
			state.loadingStates.logoutUser = true;
		});
		addCase(logoutUser.fulfilled, (state) => {
			state.user = null;
			state.loadingStates.logoutUser = false;
		});
		addCase(logoutUser.rejected, (state) => {
			state.loadingStates.logoutUser = false;
		});
		addCase(updatePassword.pending, (state) => {
			state.loadingStates.updatePassword = true;
		});
		addCase(updatePassword.fulfilled, (state) => {
			state.loadingStates.updatePassword = false;
		});
		addCase(updatePassword.rejected, (state) => {
			state.loadingStates.updatePassword = false;
		});
		addCase(sendVerificationEmail.pending, (state) => {
			state.loadingStates.sendVerificationEmail = true;
		});
		addCase(sendVerificationEmail.fulfilled, (state) => {
			state.loadingStates.sendVerificationEmail = false;
		});
		addCase(sendVerificationEmail.rejected, (state) => {
			state.loadingStates.sendVerificationEmail = false;
		});
		addCase(verifyRegistration.pending, (state) => {
			state.loadingStates.verifyRegistration = true;
		});
		addCase(verifyRegistration.fulfilled, (state) => {
			state.loadingStates.verifyRegistration = false;
		});
		addCase(verifyRegistration.rejected, (state) => {
			state.loadingStates.verifyRegistration = false;
		});
		addCase(generateNickname.pending, (state) => {
			state.loadingStates.generateNickname = true;
		});
		addCase(generateNickname.fulfilled, (state, { payload }) => {
			state.loadingStates.generateNickname = false;
			state.randomNickname = payload.data;
		});
		addCase(generateNickname.rejected, (state) => {
			state.loadingStates.generateNickname = false;
		});
		addCase(fetchAllAvatars.pending, (state) => {
			state.loadingStates.fetchAllAvatars = true;
		});
		addCase(fetchAllAvatars.fulfilled, (state, { payload: { data } }) => {
			state.avatars = data;
			state.loadingStates.fetchAllAvatars = false;
		});
		addCase(fetchAllAvatars.rejected, (state) => {
			state.loadingStates.fetchAllAvatars = false;
		});
		addCase(fetchNotificationSettings.pending, (state) => {
			state.loadingStates.fetchNotificationSettings = true;
		});
		addCase(fetchNotificationSettings.fulfilled, (state, { payload: { data } }) => {
			state.notificationSettings = data;
			state.loadingStates.fetchNotificationSettings = false;
		});
		addCase(fetchNotificationSettings.rejected, (state) => {
			state.loadingStates.fetchNotificationSettings = false;
		});
		addCase(putNotificationSettings.fulfilled, (state, { payload: { data } }) => {
			state.notificationSettings = data;
		});
		addCase(changeUserLanguage.pending, (state) => {
			state.loadingStates.changeUserLanguage = true;
		});
		addCase(changeUserLanguage.fulfilled, (state, action) => {
			state.loadingStates.changeUserLanguage = false;
			if (state.user) {
				state.user.language.id = action.payload.data.id;
			}
		});
		addCase(changeUserLanguage.rejected, (state) => {
			state.loadingStates.changeUserLanguage = false;
		});
	},
});
export const {
	clearRandomNickname,
	updateCurrentUserSocialStatus,
	updateFirstSocialLoginStatus,
	updateChampionshipStatusModal,
	updateVerificationStatus,
	updateSwitchToPage,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
