import { AnyAction } from '@reduxjs/toolkit';
import { actionsMiddlewareSubject } from '@store/middlewares/actions.middleware';
import { useEffect } from 'react';
import { tap } from 'rxjs/operators';

const useListenReduxActions = (actionListener: (action: AnyAction) => void) => {
	useEffect(() => {
		const subscription = actionsMiddlewareSubject.pipe(tap(actionListener)).subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, [actionListener]);
};

export default useListenReduxActions;
