import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { createSelector } from 'reselect';
import actionsMiddleware from './middlewares/actions.middleware';
import rootReducer from './root.reducer';
import { rootEpics } from './sockets/root.epics';
import environmentBasedConfig from "@utils/config";

const useAppDispatch = () => useDispatch<typeof store.dispatch>();
const epicMiddleware = createEpicMiddleware();


const devToolsIsEnabled = () => {
	try {
		return environmentBasedConfig("REDUX_DEVTOOLS_ENABLED", window.location.host) as boolean;
	} catch (e) {
		return true;
	}
};

export const store = configureStore({
	reducer: rootReducer,
	devTools: devToolsIsEnabled(),
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware, actionsMiddleware),
	// enhancers:
	// preloadedState:
});

epicMiddleware.run(rootEpics);

export { useAppDispatch, createSelector };
export default store;
