import { IMerchant } from '@features/tournaments/models/tournament-merchant.model';
import { httpHandler } from '@http/httpHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { MerchantsActionTypes } from './merchants.action-types';

export const fetchMerchantById = createAsyncThunk(MerchantsActionTypes.FETCH_MERCHANT_BY_ID, (merchantId: string) => {
	return httpHandler(axios.get<IMerchant>(`/merchants/${merchantId}`));
});

export const fetchMerchants = createAsyncThunk(MerchantsActionTypes.FETCH_MERCHANTS, () => {
	return httpHandler(axios.get<IPageableResponse<IMerchant>>('/merchants'));
});
