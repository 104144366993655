import { forwardRef, ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';

interface IPrimaryButtonProps {
	text?: string;
	tabIndex: number;
	onClick?: () => void;
	disabled?: boolean;
	testId?: string;
	type?: 'submit' | 'reset' | 'button';
	children?: ReactNode;
}

const PrimaryButton = forwardRef<HTMLButtonElement, IPrimaryButtonProps>(function PrimaryButton(
	props: IPrimaryButtonProps,
	ref
) {
	const { onClick, text, tabIndex, disabled, type, testId } = props;
	const [isPressing, setIsPressing] = useState(false);

	const onMouseDown = () => {
		if (isMobile) {
			return;
		}
		setIsPressing(true);
	};
	const onMouseUp = () => {
		if (isMobile) {
			return;
		}
		setIsPressing(false);
	};

	const onTouchStart = () => {
		setIsPressing(true);
	};

	const onTouchEnd = () => {
		setIsPressing(false);
	};

	const getButtonColor = () => {
		if (isPressing) {
			return 'bg-main-600';
		}
		return 'bg-main-500';
	};

	return (
		<button
			className={`w-full min-w-max ${getButtonColor()} disabled:bg-disabledMain-500 border border-transparent pointer-events-auto disabled:pointer-events-none text-base text-white font-bold rounded-2xl focus:outline-none outline-none transition-all`}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onTouchStart={onTouchStart}
			onTouchEnd={onTouchEnd}
			type={type}
			tabIndex={tabIndex}
			disabled={disabled}
			data-testid={testId}
			style={{ padding: '16px 14px', fontFamily: "'Inter', sans-serif" }}
			ref={ref}
		>
			{text || props.children}
		</button>
	);
});

export default PrimaryButton;
