import { useEffect } from 'react';

type WorkBoxEventCallback = (event: any) => void;

const LayoutPWAUpdate = () => {
	useEffect(() => {
		const wb = (window as any).workbox;

		let promptNewVersionAvailable: WorkBoxEventCallback | undefined = undefined;

		if (typeof window !== 'undefined' && 'serviceWorker' in navigator && wb !== undefined) {
			promptNewVersionAvailable = async () => {
				try {
					const cachedKeys = await caches.keys();

					await Promise.allSettled(cachedKeys.map((key) => caches.delete(key)));
				} catch (error) {
					//
				}

				wb.addEventListener('controlling', (event: any) => {
					window.location.reload();
				});

				wb.messageSkipWaiting();
			};

			wb.addEventListener('waiting', promptNewVersionAvailable);

			wb.register();
		}

		return () => {
			if (wb && promptNewVersionAvailable) {
				wb.removeEventListener('waiting', promptNewVersionAvailable);
			}
		};
	}, []);

	return null;
};

export default LayoutPWAUpdate;
