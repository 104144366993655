import React, { ReactNode } from 'react';

interface IInputContainer {
	children: ReactNode;
}

const InputContainer = ({ children }: IInputContainer) => {
	return (
		<div className='flex flex-col' style={{ fontFamily: "'Inter', sans-serif" }}>
			{children}
		</div>
	);
};

export default InputContainer;
