import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import React, { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, map, tap } from 'rxjs/operators';
import SimpleBar from 'simplebar-react';

type IScrollLayout = 'vertical' | 'horizontal';

interface IScrollViewProps {
	children: ReactNode;
	asyncFetchProps?: {
		fetchData: (skip: number, take: number) => Promise<any>;
		loadingSize: number;
		skip: number;
		take: number;
		hasMoreData: boolean;
	};
	layout?: IScrollLayout;
	className?: string;
}

interface IScrollVIewState {
	isLoading: boolean;
	skip: number;
	take: number;
}

export interface IScrollViewRef {
	scrollToTop: () => void;
	resetState: () => void;
}

const ScrollView = forwardRef<IScrollViewRef, IScrollViewProps>((props: IScrollViewProps, ref) => {
	const { children, asyncFetchProps, className, layout } = props;

	const [state, setState] = useState<IScrollVIewState>({
		isLoading: false,
		skip: asyncFetchProps?.skip || 0,
		take: asyncFetchProps?.take || 0,
	});
	const [scrollContainer, setScrollContainer] = useState<HTMLDivElement>();

	useImperativeHandle(ref, () => ({
		scrollToTop: () => {
			scrollContainer?.scrollTo({ top: 0, left: 0 });
		},
		resetState: () => {
			setState({
				isLoading: false,
				skip: asyncFetchProps?.skip || 0,
				take: asyncFetchProps?.take || 0,
			});
		},
	}));

	const onBottomReached = useCallback(
		(params: Pick<HTMLDivElement, 'scrollHeight' | 'scrollTop' | 'clientHeight'>) => {
			if (!asyncFetchProps) {
				return;
			}
			const { hasMoreData, loadingSize, fetchData } = asyncFetchProps;
			const { clientHeight, scrollHeight, scrollTop } = params;
			const scrollPosition = scrollTop + clientHeight;
			const hasReachedLastElement = scrollHeight - scrollPosition <= loadingSize;

			if (hasReachedLastElement && !state.isLoading && hasMoreData) {
				setState((prevState) => ({ ...prevState, isLoading: true }));

				fetchData(state.skip, state.take)
					.then(() => setState(({ take, skip }) => ({ isLoading: false, skip: skip + take, take })))
					.catch(() => setState((prevState) => ({ ...prevState, isLoading: false })));
			}
		},
		[asyncFetchProps, state.isLoading, state.skip, state.take]
	);

	useEffect(() => {
		if (scrollContainer) {
			const { scrollHeight, scrollTop, clientHeight } = scrollContainer;

			onBottomReached({ scrollTop, scrollHeight, clientHeight });
		}
	}, [onBottomReached, scrollContainer]);

	useEffect(() => {
		let scroll$: Subscription;

		if (scrollContainer && asyncFetchProps) {
			scroll$ = fromEvent<any>(scrollContainer, 'scroll')
				.pipe(
					debounce(() => timer(20)),
					map(({ target: { scrollHeight, scrollTop, clientHeight } }) => ({
						scrollHeight,
						scrollTop,
						clientHeight,
					})),
					tap(onBottomReached)
				)
				.subscribe();
		}

		return () => scroll$?.unsubscribe();
	}, [asyncFetchProps, onBottomReached, scrollContainer]);

	return (
		<AutoSizer>
			{({ height, width }: { height: number; width: number }) => (
				<SimpleBar autoHide={true} scrollableNodeProps={{ ref: setScrollContainer }} style={{ width, height }}>
					{layout !== 'horizontal' ? (
						<div className={className}>
							{children}
							{asyncFetchProps?.hasMoreData ? (
								<LoadingIndicator contained size={asyncFetchProps.loadingSize} />
							) : null}
						</div>
					) : (
						<div className={`flex w-max overflow-scroll ${className}`}>{children}</div>
					)}
				</SimpleBar>
			)}
		</AutoSizer>
	);
});

ScrollView.displayName = 'ScrollView';
export default ScrollView;
