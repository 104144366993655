import React from 'react';
import { FieldError } from 'react-hook-form';

interface IInputError {
	inputTestId: string;
	inputHint?: string;
	fieldError: FieldError | undefined;
	contentLength?: number;
	limit?: number;
}

const InputMessage = ({ inputTestId, fieldError, inputHint, contentLength, limit }: IInputError) => {
	return (
		<div className={`${limit! > 0 ? 'flex' : ''}`} style={{ marginTop: '3px' }}>
			{inputHint ? (
				<p className='font-medium text-xs ml-3 h-max text-dark-300' data-testid={`${inputTestId}Hint`}>
					{inputHint}
				</p>
			) : null}
			<p
				className={`font-medium text-xs ml-3 h-4 text-razzmatazz-500 ${fieldError ? 'visible' : 'invisible'} ${
					limit! > 0 ? 'w-[50%]' : ''
				}`}
				data-testid={`${inputTestId}Error`}
			>
				{fieldError?.message}
			</p>
			{limit! > 0 ? (
				<p className={`font-medium text-xs ml-3 h-4 w-[50%] flex justify-end`}>
					{contentLength}/{limit}
				</p>
			) : null}
		</div>
	);
};

export default InputMessage;
