export enum NotificationStatus {
	READ = 'READ',
	UNREAD = 'UNREAD',
}

export interface INotification {
	id: string;
	receiver: string;
	messageData: { [key: string]: any };
	messageContent: string;
	eventObjectId: string;
	status: NotificationStatus;
	scheduledDate: string;
	dateSent: string;
	language: string;
	clientId: string;
	imageId: string;
	merchantId: string;
	templateId: string;
}
