import { IMerchant } from '@features/tournaments/models/tournament-merchant.model';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMerchantsState } from '../models/merchants-state.model';
import { fetchMerchantById, fetchMerchants } from './merchants.action';

const sortCompareMerchantByAlphabet = (merchant1: IMerchant, merchant2: IMerchant) => {
	return merchant1.name.toLocaleLowerCase().localeCompare(merchant2.name);
};

export const merchantsAdapter = createEntityAdapter<IMerchant>({
	selectId: (merchant) => merchant.id,
	sortComparer: sortCompareMerchantByAlphabet,
});

export const initialMerchantState: IMerchantsState = {
	...merchantsAdapter.getInitialState({ entities: {}, ids: [], total: null }),
	loadingState: { fetchMerchantById: false, fetchAllMerchants: false },
};

const merchantSlice = createSlice({
	name: '[MERCHANTS] - ',
	initialState: initialMerchantState,
	reducers: {
		emitMerchantsRemoveById: (state, action: PayloadAction<Pick<IMerchant, 'id'>>) => {
			merchantsAdapter.removeOne(state, action.payload.id);
		},
		emitMerchantRemoveAll: (state) => {
			merchantsAdapter.removeAll(state);
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchMerchantById.pending, (state) => {
			state.loadingState.fetchMerchantById = true;
		});
		addCase(fetchMerchantById.fulfilled, (state, action) => {
			state.loadingState.fetchMerchantById = false;
			merchantsAdapter.upsertOne(state, action.payload.data);

			if (state.total === null) {
				state.total = 1;
			} else {
				state.total += 1;
			}
		});
		addCase(fetchMerchantById.rejected, (state) => {
			state.loadingState.fetchMerchantById = false;
		});
		addCase(fetchMerchants.pending, (state) => {
			state.loadingState.fetchAllMerchants = true;
		});
		addCase(fetchMerchants.fulfilled, (state, action) => {
			state.loadingState.fetchAllMerchants = false;
			state.total = action.payload.data.total;
			merchantsAdapter.upsertMany(state, action.payload.data.result);
		});
		addCase(fetchMerchants.rejected, (state) => {
			state.loadingState.fetchAllMerchants = false;
		});
	},
});

export const merchantReducer = merchantSlice.reducer;
export const { emitMerchantsRemoveById, emitMerchantRemoveAll } = merchantSlice.actions;
