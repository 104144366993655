import { useIntl } from 'react-intl';
import React, { ReactNode } from 'react';

interface IButtonLinkProps {
	onClick: () => void;
	buttonText: string;
	testId: string;
	icon?: ReactNode;
}

const ButtonLink = ({ onClick: onClose, buttonText, testId, icon }: IButtonLinkProps) => {
	const { formatMessage } = useIntl();

	return (
		<button onClick={onClose} data-testid={testId} className={`${icon ? 'flex ml-2 items-center' : 'flex'}`}>
			{icon || null}
			<p
				className='ml-2 text-headerColor-100 text-left font-semibold xs:text-3xl text-2xl'
				style={{
					fontFamily: "'Montserrat', normal",
					letterSpacing: '-1px',
					lineHeight: '4.1875rem'
				}}
			>
				{formatMessage({
					id: `${buttonText}`,
				})}
			</p>
		</button>
	);
};

export default ButtonLink;
