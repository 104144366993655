import { IAuthState } from '@features/auth/models/auth-state.model';
import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { AuthLoading } from '../models/auth-loading-state.model';

const authSelector = ({ auth }: IApplicationState): IAuthState => auth;

export const getCurrentUser = createSelector(authSelector, ({ user }) => user);
export const getCurrentCustomerId = createSelector(getCurrentUser, (user) => (user ? user.id : null));
export const getAllAvatars = createSelector(authSelector, ({ avatars }) => avatars);
export const getAuthIsLoading = (action: keyof AuthLoading) =>
	createSelector(authSelector, ({ loadingStates }) => loadingStates[action]);
export const getAuthStateDetermined = createSelector(authSelector, ({ authStateDetermined }) => authStateDetermined);
export const getAuthRandomNickname = createSelector(authSelector, ({ randomNickname }) => randomNickname);
export const getAuthNotificationSettings = createSelector(
	authSelector,
	({ notificationSettings }) => notificationSettings
);
export const getVerificationStatus = createSelector(authSelector, ({ verificationStatus }) => verificationStatus);
export const getSwitchToPage = createSelector(
	authSelector,
	({ authNavigationThroughModal }) => authNavigationThroughModal
);
