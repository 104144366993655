import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCookie, setCookie } from 'tiny-cookie';

interface ILinkText {
	children: React.ReactNode;
	url: string;
}

const LinkText = ({ url, children }: ILinkText) => {
	return (
		<Link href={url}>
			<span className='underline cursor-pointer'>{children}</span>
		</Link>
	);
};

const CookiePolicyPopup = () => {
	const { formatMessage } = useIntl();

	const isDateExpired = (name: string) => getCookie(name) === null || getCookie(name) === undefined;
	const [isCookieAccepted, setIsCookieAccepted] = useState(false);
	const policyName = 'CookiePolicy';
	const policyValue = 'Cookie policy';

	const setCookieClose = () => {
		const date = new Date();
		date.setDate(date.getDate() + 3600);
		setCookie(policyName, policyValue, { expires: date.toString() });
		setIsCookieAccepted(false);
	};

	useEffect(() => {
		if (!isDateExpired(policyName)) {
			setIsCookieAccepted(true);
		}
	}, []);

	useEffect(() => {
		if (getCookie(policyName) === policyValue) {
			setIsCookieAccepted(false);
		}
		if (getCookie(policyName) === null) {
			setIsCookieAccepted(true);
		}
	}, []);

	return (
		<>
			{isCookieAccepted ? (
				<div className='bg-black fixed items-center justify-center text-xs flex flex-row bottom-0 w-full z-[50]'>
					<span className='text-white text-center py-2 sm:py-5 pr-1 w-3/4 sm:w-auto'>
						{formatMessage(
							{ id: 'bcs-Common-CookiesDescriptionPolicy' },
							{
								a: (text: any) => <LinkText url={'privacy-policy'}>{text}</LinkText>,
							}
						)}
					</span>
					<div className='w-16 h-full'>
						<button
							className='w-full h-full bg-white focus:outline-none font-bold not-italic text-dark-500 rounded-lg'
							style={{ padding: '8px 0', fontFamily: 'Roboto' }}
							onClick={() => setCookieClose()}
							data-testid='cookieConsentBtn'
						>
							{formatMessage({ id: 'bcs-Common-AgreePolicy' })}
						</button>
					</div>
				</div>
			) : null}
		</>
	);
};

export default CookiePolicyPopup;
