import { useEffect, useState } from 'react';

interface IIconCloseModalProps {
	animate?: boolean;
	onAnimationEnd?: () => void;
}

export const IconCloseModal = ({ onAnimationEnd, animate }: IIconCloseModalProps) => {
	const [animateElement, setAnimateElement] = useState<SVGElement | null>(null);
	useEffect(() => {
		if (!animateElement) {
			return;
		}

		const endListener = () => {
			onAnimationEnd && onAnimationEnd();
		};

		animateElement.addEventListener('endEvent', endListener);

		return () => {
			animateElement.removeEventListener('endEvent', endListener);
		};
	}, [onAnimationEnd, animateElement]);

	return (
		<div
			className='focus:outline-none self-start w-12 h-12 grid place-items-center rounded-full'
			style={{
				boxShadow: 'rgb(0 0 0 / 3%) 0px 10px 10px 0px',
				willChange: 'transform',
				transform: 'scale(-1, 1) rotateZ(270deg)',
			}}
		>
			<svg width='49' height='49' viewBox='0 0 49 49' fill='white' xmlns='http://www.w3.org/2000/svg'>
				<circle
					cx='24'
					cy='24'
					r='23.5'
					stroke={animate ? '#FF652E' : 'white'}
					strokeDasharray='147'
					fill='white'
				>
					{animate ? (
						<animate
							attributeType='XML'
							attributeName='stroke-dashoffset'
							from='0'
							to='147'
							dur={`3s`}
							repeatCount='1'
							fill='freeze'
							ref={setAnimateElement}
						/>
					) : null}
				</circle>
				<path
					d='M33.375 16.0555L16.125 33.3055'
					stroke='#11142D'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M33.375 33.3055L16.125 16.0555'
					stroke='#11142D'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</div>
	);
};

export default IconCloseModal;
