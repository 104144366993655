import { logoutUser } from '@features/auth/store/auth.actions';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import IconArrowDown from '@features/common/components/IconArrowDown';
import { getCdnUrl } from '@features/common/store/common.selectors';
import useOnClickOutside from '@hooks/useClickOutside';
import { unwrapResult } from '@reduxjs/toolkit';
import { resetState } from '@store/root.actions';
import { useAppDispatch } from '@store/store';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const HeaderProfile = () => {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(getCurrentUser);
	const cdnUrl = useSelector(getCdnUrl);
	const [isOpened, setIsOpened] = useState(false);
	const dropdownMenuRef = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	useOnClickOutside(
		dropdownMenuRef,
		useCallback(() => setIsOpened(false), [])
	);

	const onLogoutClick = () => {
		dispatch(logoutUser());
	};

	return (
		<div className='cursor-pointer'>
			<div>
				<button
					data-testid='navProfileDropdownBtn'
					className={`flex items-center focus:outline-none ${isOpened ? 'pointer-events-none' : ''}`}
					onClick={() => setIsOpened(true)}
				>
					<div className='mr-1 grid place-items-center min-w-[36px]'>
						<Image
							alt='avatar'
							unoptimized={true}
							src={`${cdnUrl}avatars/${currentUser?.avatarId}.jpg`}
							objectFit='cover'
							width={36}
							height={36}
							priority={true}
							className='rounded-full'
						/>
					</div>
					<div className='flex items-center space-x-2'>
						<div>
							<p
								title={currentUser?.nickname}
								style={{ color: '#808191' }}
								className='select-none truncate max-w-[100px] w-max'
							>
								{currentUser?.nickname}
							</p>
						</div>
						<span className={`transition-transform ${isOpened ? 'rotate-180' : 'rotate-0'}`}>
							<IconArrowDown />
						</span>
					</div>
				</button>
				<AnimatePresence mode='wait'>
					{isOpened ? (
						<motion.div
							initial={{ opacity: 0, y: -10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
							transition={{ duration: 0.2 }}
							ref={dropdownMenuRef}
							className='absolute py-2 w-44 bg-white border rounded-lg shadow-xl'
							style={{ right: '11px', marginTop: '17px' }}
						>
							<Link href='/account-settings/general' passHref>
								<a data-testid='navDesktopSettingsLink'>
									<div
										className='block px-4 text-left py-2 transition-colors hover:bg-brightGray-100 text-headerColor-100 font-normal'
										onClick={() => setIsOpened(false)}
									>
										{formatMessage({ id: 'bcs-User-Profile-Account-Settings' })}
									</div>
								</a>
							</Link>
							<button
								type='button'
								data-testid='navDesktopLogoutBtn'
								className='block w-full px-4 text-left py-2 transition-colors hover:bg-brightGray-100 text-headerColor-100'
								onClick={onLogoutClick}
							>
								{formatMessage({ id: 'bcs-Header-SignOut' })}
							</button>
						</motion.div>
					) : null}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default HeaderProfile;
