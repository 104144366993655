import IconGamyLogoWhite from '@features/common/components/IconGamyLogoWhite';
import React from 'react';

const SplashScreen = () => {

  return (
    <div className={'w-screen h-screen bg-black flex justify-center items-center'}>
      <IconGamyLogoWhite />
    </div>
  );
};

export default SplashScreen;
