import PwaInstallPrompt from "@features/layout/components/PwaInstallPrompt";
import React from "react";

interface PwaConfig {
    testId: string;
    isDesktop: boolean;
}

const PwaInstaller = () => {
    return (
        <div className='lg:hidden block'>
            <PwaInstallPrompt />
        </div>
    );
};

export default PwaInstaller;