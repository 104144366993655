import { INamedObject } from '@features/common/models/namedObject.model';
import useParseToRelativeTime from '@hooks/useParseToRelativeTime';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITournamentCompact, TournamentTypes } from '../models/tournament.model';
import { TournamentStatusIdTypes } from '../models/tournament-status.model';
import { ITournamentType } from '../models/tournament-type.model';
import useFormatDateTime from '@hooks/useFormatDateTime';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from '@features/layout/store/layout.selectors';

interface ITournamentTimeRemainingProps {
	tournamentType?: TournamentTypes;
	registered: ITournamentCompact['registered'];
	tournamentStartDate: string;
	registrationEndDate: string;
	statusId: TournamentStatusIdTypes;
	isPopupRegisteredSuccess: boolean;
	gamyType?: ITournamentType;
	withAwards?: boolean;
}

const TournamentTimeRemaining = (props: ITournamentTimeRemainingProps) => {
	const {
		tournamentType,
		isPopupRegisteredSuccess,
		registered,
		registrationEndDate,
		statusId,
		tournamentStartDate,
		gamyType,
		withAwards,
	} = props;
	const parseToRelativeTime = useParseToRelativeTime();
	const { formatDate } = useFormatDateTime();
	const { formatMessage } = useIntl();
	const selectedLanguageFromState = useSelector(getSelectedLanguage);

	const textStyle = () => {
		if (tournamentType === 'registeredTournament') {
			return 'text-msm text-white ml-3 my-auto';
		}
		return 'sm:text-msm text-white ml-3 my-auto text-sxs';
	};

	const generateTimeToShow = useCallback(
		(
			statusId: ITournamentCompact['statusId'],
			registered: ITournamentCompact['registered'],
			endDate: string,
			startDate: string
		) => {
			if (statusId === 'REGISTERING' && !registered) {
				return parseToRelativeTime(new Date(endDate), false);
			}
			if ((statusId === 'REGISTERING' || statusId === 'READY') && registered && startDate) {
				return parseToRelativeTime(new Date(startDate), false);
			}
			return null;
		},
		[parseToRelativeTime]
	);

	const [timeToShow, setTimeToShow] = useState<INamedObject<string> | null>(
		generateTimeToShow(statusId, registered, registrationEndDate, tournamentStartDate)
	);

	useEffect(() => {
		const subscription = interval(60000)
			.pipe(
				tap(() =>
					setTimeToShow(generateTimeToShow(statusId, registered, registrationEndDate, tournamentStartDate))
				)
			)
			.subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, [generateTimeToShow, registrationEndDate, statusId, registered, tournamentStartDate]);

	useEffect(() => {
		setTimeToShow(generateTimeToShow(statusId, registered, registrationEndDate, tournamentStartDate));
	}, [generateTimeToShow, registrationEndDate, statusId, registered, tournamentStartDate]);

	if (
		!timeToShow &&
		statusId !== 'RUNNING' &&
		statusId !== 'ACTIVE' &&
		statusId !== 'CANCELED' &&
		gamyType !== 'PLAY_NOW_TEMPLATE' &&
		gamyType !== 'PLAY_NOW_COPY'
	) {
		return null;
	}

	if (statusId === 'REGISTERING' && !registered && timeToShow) {
		return (
			<div className={`${textStyle()} text-center`} data-testid='timeToRegister'>
				{formatMessage({ id: timeToShow.id }, { value: timeToShow.name })}{' '}
				{formatMessage({ id: 'bcs-Tournaments-ToRegister' })}
			</div>
		);
	}

	if (
		(statusId === 'REGISTERING' || statusId === 'READY') &&
		registered &&
		timeToShow &&
		gamyType !== 'PLAY_NOW_COPY'
	) {
		if (isPopupRegisteredSuccess) {
			return (
				<span data-testid='timeToStartPopup'>
					{formatMessage({ id: timeToShow.id }, { value: timeToShow.name })}
				</span>
			);
		}

		return (
			<div className={`${textStyle()} text-center`} data-testid='timeTillStart'>
				{formatMessage({ id: timeToShow.id }, { value: timeToShow.name })}{' '}
				{formatMessage({ id: 'bcs-Tournaments-TillStart' })}
			</div>
		);
	}

	if (statusId === 'RUNNING' && registered && gamyType !== 'PLAY_NOW_COPY') {
		return (
			<div className={`${textStyle()} text-center`} data-testid='gamyHasStarted'>
				{formatMessage({ id: 'bcs-Tournaments-GamyHasStarted' })}
			</div>
		);
	}

	if (
		(gamyType === 'PLAY_NOW_TEMPLATE' && !withAwards) ||
		(gamyType === 'PLAY_NOW_TEMPLATE' && !withAwards && statusId === 'CANCELED') ||
		gamyType === 'PLAY_NOW_COPY'
	) {
		return (
			<div
				className={`text-headerColor-100 ml-[18px] my-auto sm:text-sm text-xs text-start font-medium`}
				style={{ fontFamily: 'Roboto' }}
				data-testid='playNowWithoutAwards'
			>
				{formatMessage({ id: 'bcs-Tournaments-Play-Now' })}
			</div>
		);
	}

	if (
		(gamyType === 'PLAY_NOW_TEMPLATE' && withAwards) ||
		(gamyType === 'PLAY_NOW_TEMPLATE' && withAwards && statusId === 'CANCELED')
	) {
		return (
			<div className='relative text-headerColor-100 sm:text-sm text-[10px] text-start w-full font-medium sm:top-0 top-[3px]'>
				<div className='sm:block hidden'>
					<svg width='100%' height='28' viewBox='0 0 262 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<rect y='0.273438' width='100%' height='28' fill='#11142D' fillOpacity='0.7' />
						<path d='M0 0.273438H110L97 30.2734H0V0.273438Z' fill='#FFBF01' />
					</svg>
				</div>
				<div className='sm:hidden block'>
					<svg width='170' height='25' viewBox='0 0 170 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<rect width='170' height='25' fill='#11142D' fillOpacity='0.7' />
						<path d='M0 0H70L60.5 25H0V0Z' fill='#FFBF01' />
					</svg>
				</div>

				<div
					className='absolute sm:top-0 top-[-1px] left-0 w-full h-full flex justify-between items-center'
					data-testid='playNowWithAwards'
				>
					<p className='sm:ml-[18px] ml-[5px]'>{formatMessage({ id: 'bcs-Tournaments-Play-Now' })}</p>

					<div className='sm:mr-3 mr-[5px] flex items-center h-5'>
						<div className='sm:block hidden'>
							<svg
								width='13'
								height='20'
								viewBox='0 0 13 20'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									id='Vector'
									d='M4.33333 7.16406H2.88889V8.66406H4.33333V7.16406ZM7.22222 7.16406H5.77778V8.66406H7.22222V7.16406ZM10.1111 7.16406H8.66667V8.66406H10.1111V7.16406ZM11.5556 1.91406H10.8333V0.414062H9.38889V1.91406H3.61111V0.414062H2.16667V1.91406H1.44444C0.642778 1.91406 0 2.58906 0 3.41406V13.9141C0 14.3119 0.152182 14.6934 0.423068 14.9747C0.693954 15.256 1.06135 15.4141 1.44444 15.4141H11.5556C11.9386 15.4141 12.306 15.256 12.5769 14.9747C12.8478 14.6934 13 14.3119 13 13.9141V3.41406C13 3.01624 12.8478 2.63471 12.5769 2.3534C12.306 2.0721 11.9386 1.91406 11.5556 1.91406ZM11.5556 13.9141H1.44444V5.66406H11.5556V13.9141Z'
									fill='white'
								/>
							</svg>
						</div>
						<div className='sm:hidden block'>
							<svg
								width='10'
								height='12'
								viewBox='0 0 10 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M3.33333 5.4H2.22222V6.6H3.33333V5.4ZM5.55556 5.4H4.44444V6.6H5.55556V5.4ZM7.77778 5.4H6.66667V6.6H7.77778V5.4ZM8.88889 1.2H8.33333V0H7.22222V1.2H2.77778V0H1.66667V1.2H1.11111C0.494444 1.2 0 1.74 0 2.4V10.8C0 11.1183 0.117063 11.4235 0.325437 11.6485C0.533811 11.8736 0.816426 12 1.11111 12H8.88889C9.18357 12 9.46619 11.8736 9.67456 11.6485C9.88294 11.4235 10 11.1183 10 10.8V2.4C10 2.08174 9.88294 1.77652 9.67456 1.55147C9.46619 1.32643 9.18357 1.2 8.88889 1.2ZM8.88889 10.8H1.11111V4.2H8.88889V10.8Z'
									fill='white'
								/>
							</svg>
						</div>

						<p className='sm:ml-2 ml-[5px] text-white font-normal'>
							{formatDate(
								tournamentStartDate,
								{ month: 'short', day: '2-digit' },
								selectedLanguageFromState
							)}{' '}
							-{' '}
							{formatDate(
								registrationEndDate,
								{ month: 'short', day: '2-digit' },
								selectedLanguageFromState
							)}
						</p>
					</div>
				</div>
			</div>
		);
	}

	if (statusId === 'ACTIVE' && gamyType !== 'PLAY_NOW_TEMPLATE') {
		return (
			<div className={`${textStyle()} text-start`} data-testid='gamyIsActive'>
				{formatMessage({ id: 'bcs-Tournaments-GamyIsActive' })}
			</div>
		);
	}

	return null;
};

export default TournamentTimeRemaining;
