import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { httpHandler } from '@http/httpHelpers';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IChampionship } from '../models/championship-model';
import { ChampionshipsActionTypes } from './championships.action-types';
import { IQueryParams } from '@http/queryCreators';

export const emitFinishGameCountOnHomepage = createAction(
	ChampionshipsActionTypes.UPDATE_TOURNAMENT_FINISH_GAMES,
	(payload) => {
		return { payload };
	}
);

export const emitChampionshipEndedTournamentsCount = createAction(
	ChampionshipsActionTypes.UPDATE_CHAMPIONSHIP_FINISHED_GAMYS,
	(payload) => {
		return { payload };
	}
);

export const emitHttpChampionshipsOneTimeFetchAll = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS,
	() => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get('/championships/list', { params: { skip: 0, take: 6, type: 'ONE_TIME_GAMES' } })
		);
	}
);

export const emitHttpChampionshipsPlayNowFetchAll = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS_PLAY_NOW,
	({ take, skip }: Pick<IQueryParams, 'take' | 'skip'>) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get('/championships/list', { params: { skip: skip, take: take, type: 'PLAY_NOW_GAMES' } })
		);
	}
);

export const emitHttpChampionshipsPlayNowAwardsFetchAll = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS_PLAY_NOW_AWARD,
	({ take, skip }: Pick<IQueryParams, 'take' | 'skip'>) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get('/championships/list', {
				params: { skip: skip, take: take, type: 'PLAY_NOW_GAMES', withAwards: true },
			})
		);
	}
);

export const emitHttpChampionshipById = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS_BY_ID,
	(championshipId: string) => {
		return httpHandler<IChampionship>(axios.get(`/championships/${championshipId}`));
	}
);

export const emitHttpChampionshipsOneTimeFetchByMerchantId = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS_ONE_TIME_BY_MERCHANT_ID,
	(merchantId: string) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get(`/championships/merchants/${merchantId}`, {
				params: { skip: 0, take: 6, type: 'ONE_TIME_GAMES' },
			})
		);
	}
);

export const emitHttpPlayNowAllFetchByMerchantId = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_CHAMPIONSHIPS_PLAY_NOW_ALL_BY_MERCHANT_ID,
	({ merchantId, take, skip }: Pick<IQueryParams, 'take' | 'skip'> & Record<'merchantId', string>) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get(`/championships/merchants/${merchantId}`, {
				params: { skip: skip, take: take, type: 'PLAY_NOW_GAMES' },
			})
		);
	}
);

export const emitHttpFinishedChampionshipsOneTimeFetchByMerchantId = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_FINISHED_CHAMPIONSHIPS_ONE_TIME_BY_MERCHANT_ID,
	({ merchantId, take, skip }: Pick<IQueryParams, 'take' | 'skip'> & Record<'merchantId', string>) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get(`/championships/merchants/${merchantId}/finished`, {
				params: { skip: skip, take: take, type: 'ONE_TIME_GAMES' },
			})
		);
	}
);

export const emitHttpFinishedPlayNowAllFetchByMerchantId = createAsyncThunk(
	ChampionshipsActionTypes.FETCH_FINISHED_CHAMPIONSHIPS_PLAY_NOW_ALL_BY_MERCHANT_ID,
	({ merchantId, take, skip }: Pick<IQueryParams, 'take' | 'skip'> & Record<'merchantId', string>) => {
		return httpHandler<IPageableResponse<IChampionship>>(
			axios.get(`/championships/merchants/${merchantId}/finished`, {
				params: { skip: skip, take: take, type: 'PLAY_NOW_GAMES' },
			})
		);
	}
);
