import { IPageableResponse } from '@features/common/models/pageable-response.model';
import { RecursiveKeyOf } from '@features/common/models/recursiveKeyof.model';
import { ITournamentParticipantRanking } from '@features/gameplay/models/tournament-participant-ranking.model';
import { ITournamentInfo } from '@features/myTournaments/models/myTournament-info.model';
import { httpHandler } from '@http/httpHelpers';
import { buildQuery, IQueryParams } from '@http/queryCreators';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IMyTournament } from '../models/myTournament.model';
import { MyTournamentsActionTypes } from './myTournaments.action-types';

export const fetchMyTournamentsHistory = createAsyncThunk(
	MyTournamentsActionTypes.FETCH_MY_TOURNAMENTS_HISTORY,
	(queryParams: IQueryParams<RecursiveKeyOf<IMyTournament>>) => {
		return httpHandler<IPageableResponse<IMyTournament>>(
			axios.get('/tournaments/history', { params: buildQuery(queryParams) })
		);
	}
);

export const fetchTournamentInfo = createAsyncThunk(
	MyTournamentsActionTypes.FETCH_TOURNAMENT_INFO,
	(tournamentId: string) => {
		return httpHandler<ITournamentInfo>(axios.get(`/tournaments/${tournamentId}/info`));
	}
);

export const fetchTournamentPodiumParticipants = createAsyncThunk(
	MyTournamentsActionTypes.FETCH_TOURNAMENT_PODIUM_PARTICIPANTS,
	(tournamentId: string) => {
		const params = {
			tournamentId,
			skip: 0,
			take: 3,
		};
		return httpHandler<IPageableResponse<ITournamentParticipantRanking>>(
			axios.get('/game-round-participants/ranking', { params })
		);
	}
);
