import { PayloadAction } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { Subject } from 'rxjs';

export const actionsMiddlewareSubject = new Subject<PayloadAction>();

const actionsMiddleware: Middleware = () => (next) => (params: PayloadAction | PayloadAction[]) => {
	if (Array.isArray(params)) {
		params.forEach((action) => {
			actionsMiddlewareSubject.next(action);
			next(action);
		});
		return;
	}
	actionsMiddlewareSubject.next(params);
	next(params);
};

export default actionsMiddleware;
