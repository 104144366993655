/* eslint-disable @next/next/no-img-element */
import GrayIconClose from '@features/common/components/GrayIconClose';
import IPhoneInstallIcon from '@features/common/components/IPhoneInstallIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { useIntl } from 'react-intl';

interface INotificationInstallPromptProps {
	isOpen: boolean;
	onClose: () => void;
}

export const NotificationInstallPrompt = (props: INotificationInstallPromptProps) => {
	const { formatMessage } = useIntl();

	return (
		<AnimatePresence mode='wait'>
			{props.isOpen ? (
				<motion.div
					initial={{ opacity: 0, dur: 250 }}
					animate={{ opacity: 1, dur: 250 }}
					exit={{ opacity: 0, dur: 250 }}
					className='fixed top-0 left-0 right-0 bottom-0 z-50'
					style={{ background: 'rgba(0, 0, 0, 0.67)' }}
					onClick={props.onClose}
				>
					<div
						className='fixed top-3/4 left-1/2 bg-[#E9E9E9] z-50 rounded-xl'
						style={{ transform: 'translate(-50%, -50%', width: '304px', height: '243px' }}
						onClick={(e) => e.stopPropagation()}
					>
						<div className='flex flex-col justify-between items-center h-full'>
							<button type='button' onClick={props.onClose} className='pt-2 pr-2 self-end'>
								<GrayIconClose />
							</button>
							<div
								className='bg-white'
								style={{
									boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
									borderRadius: '15px',
									maxWidth: '80px',
									maxHeight: '80px',
								}}
							>
								<img src='/icons/android-icon-96x96.png' alt='apple' />
							</div>
							<div
								className='font-bold not-italic text-center text-lg'
								style={{ fontFamily: 'Helvetica' }}
							>
								{formatMessage({ id: 'bcs-Notification-AddGamyText' })}
							</div>
							<div className='flex items-center justify-center w-full rounded-xl bg-[#F8F8F8] h-10 self-stretch text-xs text-brightGray-250'>
								{formatMessage(
									{ id: 'bcs-Notification-AddGamyIphoneText' },
									{
										icon: (
											<div className='mx-2 mb-2' style={{ width: '15px', height: '15px' }}>
												<IPhoneInstallIcon />
											</div>
										),
									}
								)}
							</div>
						</div>
					</div>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};

export default NotificationInstallPrompt;
