import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { INotification } from '../models/notification.model';
import { INotificationsState } from '../models/notifications-state.model';
import {
	fetchAllNotifications,
	fetchUnreadCountNotifications,
	readAllNotification,
	readNotification,
	unreadNotification,
} from './notifications.actions';

export const notificationsAdapter = createEntityAdapter<INotification>({
	selectId: (notification) => notification.id,
});

export const initialNotificationsState = notificationsAdapter.getInitialState<INotificationsState>({
	ids: [],
	entities: {},
	isLoading: false,
	unreadCount: null,
	totalCount: null,
});

const notificationsSlice = createSlice({
	name: '[NOTIFICATIONS] - ',
	initialState: initialNotificationsState,
	reducers: {
		emitNotificationsIncrementUnreadCount: (state) => {
			if (state.unreadCount !== null) {
				state.unreadCount += 1;
			} else {
				state.unreadCount = 1;
			}
		},
		clearNotifications: (state) => {
			notificationsAdapter.removeAll(state);
			state.totalCount = null;
		},
		clearUnreadNotification: (state) => {
			state.unreadCount = null;
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchUnreadCountNotifications.pending, (state) => {
			state.isLoading = true;
		});
		addCase(fetchUnreadCountNotifications.fulfilled, (state, { payload: { data } }) => {
			state.isLoading = false;
			state.unreadCount = data;
		});
		addCase(fetchUnreadCountNotifications.rejected, (state) => {
			state.isLoading = false;
		});
		addCase(unreadNotification.pending, (state) => {
			state.isLoading = true;
		});
		addCase(unreadNotification.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.unreadCount = state.unreadCount !== null ? state.unreadCount + 1 : null;
			notificationsAdapter.updateOne(state, payload);
		});
		addCase(unreadNotification.rejected, (state) => {
			state.isLoading = false;
		});
		addCase(readNotification.pending, (state) => {
			state.isLoading = true;
		});
		addCase(readNotification.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.unreadCount = state.unreadCount !== null ? state.unreadCount - 1 : null;
			notificationsAdapter.updateOne(state, payload);
		});
		addCase(readNotification.rejected, (state) => {
			state.isLoading = false;
		});
		addCase(readAllNotification.pending, (state) => {
			state.isLoading = true;
		});
		addCase(readAllNotification.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.unreadCount = 0;
			notificationsAdapter.updateMany(state, payload);
		});
		addCase(readAllNotification.rejected, (state) => {
			state.isLoading = false;
		});
		addCase(fetchAllNotifications.pending, (state) => {
			state.isLoading = true;
		});
		addCase(fetchAllNotifications.fulfilled, (state, { payload: { data } }) => {
			state.isLoading = false;
			state.totalCount = data.total;
			notificationsAdapter.upsertMany(state, data.result);
		});
		addCase(fetchAllNotifications.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
export const { emitNotificationsIncrementUnreadCount, clearNotifications, clearUnreadNotification } =
	notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
