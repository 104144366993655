import axios, { AxiosPromise } from 'axios';

export interface IHttpError {
	statusCode: number;
	message: string;
}

interface IFieldError<T> {
	code: string;
	field: keyof T;
	details: string;
}

type IHttpBackEndCode =
	| 'UNEXPECTED_ERROR'
	| 'BAD_CREDENTIALS'
	| 'INVALID_FIELDS'
	| 'NOT_FOUND'
	| 'DISABLED'
	| 'CONFLICT'
	| 'UNAVAILABLE'
	| 'INVALID_PARAMETER'
	| 'NO_PERMISSION'
	| 'NOT_SUPPORTED'
	| 'FORBIDDEN'
	| 'NOT_VERIFIED'
	| 'PARTICIPANT_LIMIT_REACHED'
	| 'ANSWER_WAIT_TIME_ELAPSE'
	| 'PLAY_LIMIT_REACHED';

export interface IErrorContent<T> {
	code: IHttpBackEndCode;
	message: string;
	fieldErrors: IFieldError<T>[];
	status: number;
}

export interface IHttpResponse<Response> {
	data: Response;
	status: number;
}

export function parseError<T>(error: IHttpError): IErrorContent<T> {
	try {
		const throwError: IErrorContent<T> = JSON.parse(error.message);
		return throwError;
	} catch (e) {
		return {
			code: 'UNEXPECTED_ERROR',
			fieldErrors: [],
			message: 'Something went wrong',
			status: error.statusCode,
		};
	}
}

export const httpHandler = async function <T>(request: AxiosPromise<T>) {
	try {
		const { data, status } = await request;
		return { data, status };
	} catch ({ response }) {
		const error: IErrorContent<T> = {
			status: (response as any).status,
			code: (response as any).data.code,
			message: (response as any).data.message,
			fieldErrors: (response as any).data.fieldErrors || [],
		};

		throw JSON.stringify(error);
	}
};
