import React from 'react';

interface IInputLabel {
	inputId: string;
	inputTestId: string;
	label: string;
}

const InputLabel = ({ inputId, inputTestId, label }: IInputLabel) => {
	return (
		<label
			className='font-medium text-xs'
			htmlFor={inputId}
			data-testid={`${inputTestId}Label`}
			style={{ color: '#1B1D21', marginBottom: '7px' }}
		>
			{label}
		</label>
	);
};

export default InputLabel;
