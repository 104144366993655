export const configServerSide = (context: any) => {
	if (context.headers) {
		if (
			context.headers.host.includes('localhost') ||
			context.headers.host.includes('127.0.0.1') ||
			context.headers.host.includes('192.168') ||
			context.headers.host.includes('dev.private.gamy.fun')
		) {
			return 'https://dev.private.gamy.fun';
		} else {
			return 'https://gamy.fun';
		}
	}
};
