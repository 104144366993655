import { getSelectedChampionship } from '@features/championships/store/championships.selectors';
import { getMerchantById } from '@features/merchants/store/merchants.selectors';
import { getTournamentsSelectedTournament } from '@features/tournaments/store/tournaments.selectors';
import useGoogleAnalytics from '@hooks/useGoogleAnalytics';
import { environmentBasedConfig } from '@utils/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IGoogleAnalyticsProps {
	host: string;
}

const GoogleAnalytics = ({ host }: IGoogleAnalyticsProps) => {
	const [googleAnalyticsKey] = useState(environmentBasedConfig('GOOGLE_ANALYTICS_KEY', host) as string);
	const router = useRouter();
	const merchant = useSelector(getMerchantById(`${router.query.organizerId}`));
	const championship = useSelector(getSelectedChampionship);
	const tournament = useSelector(getTournamentsSelectedTournament);
	const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();

	const sendGoogleEvent = useCallback(
		(name: string) => {
			sendGoogleAnalyticsEvent({
				eventName: 'page_view',
				params: { eventLabel: name, eventCategory: 'page_view' },
			});
		},
		[sendGoogleAnalyticsEvent]
	);

	const sendEvent = useCallback(() => {
		const check: string = router.pathname.split('/')[1];
		switch (check) {
			case 'organizer':
				if (merchant?.name) {
					sendGoogleEvent(document.title);
				}
				break;
			case 'championships':
				if (championship?.translation.name) {
					sendGoogleEvent(document.title);
				}
				break;
			case 'tournaments':
				if (tournament?.translation.name) {
					sendGoogleEvent(document.title);
				}
				break;
			default:
				sendGoogleEvent(document.title);
				break;
		}
	}, [
		championship?.translation.name,
		merchant?.name,
		router.pathname,
		sendGoogleEvent,
		tournament?.translation.name,
	]);

	useEffect(() => {
		sendEvent();
	}, [sendEvent]);

	if (host === 'localhost:3000' || host === '127.0.0.1:3000') {
		return null;
	}

	return (
		<>
			<Script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`} />
			<Script id='google-analytics' strategy='afterInteractive'>
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
		  gtag('config', '${googleAnalyticsKey}', {
			send_page_view: false
		  });
        `}
			</Script>
		</>
	);
};
export default GoogleAnalytics;
