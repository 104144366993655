// THIS FUNCTION IS WRITTEN SO WE CAN SHOW VALID DATES ON IOS DEVICES,
// BECAUSE IN SOME CASES THE BE RETURNS
// THIS FORMAT: 'YYYY-MM-DD HH:MM:SS +0000', INSTEAD: 'YYYY-MM-DDTHH:MM:SSZ'

// THE CASES ARE:
// - FROM CONFIG API THE SERVER DATE TIME;
// - ALL DATES FROM WEBSOCKET MESSAGES;

const parseIOSDate = (date: string): Date => {
	const parsedToValidFormat = date.slice(0, date.indexOf(' +')).concat('Z').replace(' ', 'T');

	return new Date(parsedToValidFormat);
};

export default parseIOSDate;
