import { IAuthState } from '@features/auth/models/auth-state.model';
import { authReducer, initialAuthState } from '@features/auth/store/auth.reducer';
import { IChampionshipsState } from '@features/championships/models/championships-state.model';
import { championshipsReducer, initialChampionshipsState } from '@features/championships/store/championships.reducer';
import { ICommonState } from '@features/common/models/common-state.model';
import { commonReducer } from '@features/common/store/common.reducer';
import { IGamePlayState } from '@features/gameplay/models/gameplay-state.model';
import { gameplayReducer, initialGameplayState } from '@features/gameplay/store/gameplay.reducer';
import { ILayoutState } from '@features/layout/models/layout-state.model';
import { layoutReducer } from '@features/layout/store/layout.reducer';
import { IMerchantsState } from '@features/merchants/models/merchants-state.model';
import { initialMerchantState, merchantReducer } from '@features/merchants/store/merchants.reducer';

import { IMyTournamentsState } from '@features/myTournaments/models/myTournaments-state.model';
import { initialMyTournamentsState, myTournamentsReducer } from '@features/myTournaments/store/myTournaments.reducer';
import { INotificationsState } from '@features/notifications/models/notifications-state.model';
import { initialNotificationsState, notificationsReducer } from '@features/notifications/store/notifications.reducer';
import { IOnsiteNotificationsState } from '@features/onsiteNotifications/models/onsiteNotifications-state.model';
import {
	initialOnsiteNotificationsState,
	onsiteNotificationsReducer,
} from '@features/onsiteNotifications/store/onsiteNotifications.reducer';
import { ITournamentsState } from '@features/tournaments/models/tournaments-state.model';
import { initialTournamentsState, tournamentsReducer } from '@features/tournaments/store/tournaments.reducer';
import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import { resetState } from './root.actions';
import { IStatisticsState } from '@features/statistics/models/statistics-state.model';
import { statisticsReducer } from '@features/statistics/store/statistics.reducer';

export interface IApplicationState {
	auth: IAuthState;
	common: ICommonState;
	notifications: INotificationsState;
	layout: ILayoutState;
	merchants: IMerchantsState;
	onsiteNotifications: IOnsiteNotificationsState;
	tournaments: ITournamentsState;
	gameplay: IGamePlayState;
	myTournaments: IMyTournamentsState;
	championships: IChampionshipsState;
	statistics: IStatisticsState;
}

const appReducer = combineReducers<IApplicationState>({
	auth: authReducer,
	common: commonReducer,
	notifications: notificationsReducer,
	layout: layoutReducer,
	merchants: merchantReducer,
	onsiteNotifications: onsiteNotificationsReducer,
	tournaments: tournamentsReducer,
	gameplay: gameplayReducer,
	myTournaments: myTournamentsReducer,
	championships: championshipsReducer,
	statistics: statisticsReducer,
});

const rootReducer: Reducer = (state: CombinedState<IApplicationState>, action: AnyAction) => {
	if (action.type === resetState.type && state.auth.user !== null) {
		const initialState = {
			common: state.common,
			layout: state.layout,
			statistics: state.statistics,
			championships: initialChampionshipsState,
			merchants: initialMerchantState,
			myTournaments: initialMyTournamentsState,
			notifications: initialNotificationsState,
			onsiteNotifications: initialOnsiteNotificationsState,
			tournaments: initialTournamentsState,
			gameplay: initialGameplayState,
			auth: { ...initialAuthState, authStateDetermined: true },
		} as CombinedState<IApplicationState>;

		return appReducer(initialState, action);
	}
	return appReducer(state, action);
};

export default rootReducer;
