import ColoredLine from '@features/common/components/ColoredLine';
import IconFacebook from '@features/common/components/IconFacebook';
import IconGamyLogoWhite from '@features/common/components/IconGamyLogoWhite';
import React from 'react';
import { useIntl } from 'react-intl';
import FooterInfo from './FooterInfo';
import { gamyEmail } from '@utils/ui-constants';
import IconInstagram from '@features/common/components/IconInstagram';

const Footer = () => {
	const { formatMessage } = useIntl();
	const currentYearFooter = new Date(Date.now()).getUTCFullYear();

	return (
		<footer>
			<ColoredLine />
			<div className='bg-black text-white flex flex-col justify-between pt-14 pr-10 pb-8 pl-13'>
				<div className='flex flex-col sm:flex-row justify-between'>
					<div className='sm:flex'>
						<div className='mb-14'>
							<FooterInfo
								titleTranslationKey={'bcs-Footer-QuickLinks'}
								items={[
									{ translationKey: 'bcs-Footer-Home', href: '/' },
									{ translationKey: 'bcs-Footer-HowGamyWorks', href: '/how-gamy-works' },
									{ translationKey: 'bcs-Footer-TermsAndConditions', href: '/terms-and-conditions' },
									{ translationKey: 'bcs-Footer-PrivacyPolicy', href: '/privacy-policy' },
								]}
								titleColor='#ED1F79'
							/>
						</div>
						<div className='mb-14 sm:ml-10'>
							<FooterInfo
								titleTranslationKey={'bcs-Footer-GameRules'}
								items={[{ translationKey: 'bcs-Footer-RulesQuizz', href: '/rules-quizz' }]}
								titleColor='#00E090'
							/>
						</div>
						<div className='mb-14 sm:mx-10' style={{ fontFamily: 'Roboto', fontSize: '13px' }}>
							<FooterInfo titleTranslationKey={'bcs-Footer-GetInTouch'} titleColor='#FFBF01' items={[]} />
							<a className='cursor-pointer underline' href={`mailto:${gamyEmail}`} rel='noreferrer'>
								{gamyEmail}
							</a>
							<p className='text-grayText-550 mt-2 mb-[12px]'>
								{formatMessage({ id: 'bcs-Footer-GetInTouchSubtitle' })}
							</p>
							<p className='text-grayText-550 mt-2'>
								{formatMessage({ id: 'bcs-Footer-OrganizeEmailSubtitle' })}
							</p>
						</div>
					</div>
					<div className='hidden sm:flex flex-1 justify-end'>
						<IconGamyLogoWhite />
					</div>
				</div>
				<div className='flex justify-between mb-14 sm:mb-0'>
					<div className='grid sm:grid-flow-col grid-cols-4 sm:auto-rows-max gap-5'>
						<p className='sm:text-lg font-medium text-sm col-span-4 w-max' style={{ fontFamily: 'Roboto' }}>
							{formatMessage({ id: 'bcs-Footer-FollowUs' })}:
						</p>
						<a href='https://www.facebook.com/Gamy-107674231575916' target='_blank' rel='noreferrer'>
							<IconFacebook />
						</a>
						<a href='https://www.instagram.com/gamy.fun_official/' target='_blank' rel='noreferrer'>
							<IconInstagram />
						</a>
					</div>
					<p
						className='font-medium self-center hidden sm:block'
						style={{ color: '#F0F3F6', fontFamily: "'Inter', sans-serif", fontSize: '11px' }}
					>
						{formatMessage({ id: 'bcs-Footer-CopyRightBrainStars' }, { currentYear: currentYearFooter })}
					</p>
				</div>
				<div className='flex flex-col space-y-2 sm:hidden'>
					<IconGamyLogoWhite />
					<p style={{ color: '#F0F3F6', fontFamily: "'Inter', sans-serif", fontSize: '11px' }}>
						{formatMessage({ id: 'bcs-Footer-CopyRightBrainStars' }, { currentYear: currentYearFooter })}
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
