import { getCurrentUser } from '@features/auth/store/auth.selectors';
import { fetchConfig } from '@features/common/store/common.actions';
import { showToast } from '@features/common/store/common.reducer';
import { setLanguage } from '@features/layout/store/layout.reducer';
import { getSelectedLanguage } from '@features/layout/store/layout.selectors';
import axiosConfig from '@http/axios.config';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { IApplicationState } from '@store/root.reducer';
import store, { useAppDispatch } from '@store/store';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { setCookie } from 'tiny-cookie';
import bgMessages from '../../../locales/bg.json';
import enMessages from '../../../locales/en.json';
import { useRouter } from 'next/router';

interface ILocalizationProps {
	children: ReactNode;
	cookie: string;
	defaultLanguage: string;
}

const allLocales: Record<string, Record<string, string>> = {
	EN: enMessages,
	BG: bgMessages,
};

export const getIntlInstance = () => {
	const state: IApplicationState = store.getState();

	const selectedLanguage = state.layout.selectedLanguage || state.common.defaultLanguage;

	return createIntl({ locale: selectedLanguage, messages: allLocales[selectedLanguage] }, createIntlCache());
};

const extractCookieLanguage = (cookie: string | undefined) => {
	const languageCookieName = 'language';

	if (!cookie) {
		return undefined;
	}

	const cookieLanguageStartIndex = cookie.indexOf(languageCookieName);

	if (cookieLanguageStartIndex === -1) {
		return undefined;
	}

	return cookie
		.slice(
			cookieLanguageStartIndex + 1 + languageCookieName.length,
			cookieLanguageStartIndex + 1 + languageCookieName.length + 2
		)
		.toLowerCase();
};

const Localization = (props: ILocalizationProps) => {
	const { children, cookie, defaultLanguage } = props;
	const dispatch = useAppDispatch();
	const router = useRouter();
	const selectedLanguageFromState = useSelector(getSelectedLanguage);
	const currentUser = useSelector(getCurrentUser);

	const getCookieLanguage = useCallback(() => {
		const cookieLanguage = extractCookieLanguage(cookie)?.toUpperCase();
		if (!cookieLanguage) {
			return undefined;
		}
		const isCookieLanguageInAvailableTranslations = Object.keys(allLocales).includes(cookieLanguage);

		if (isCookieLanguageInAvailableTranslations) {
			return cookieLanguage;
		}

		return undefined;
	}, [cookie]);

	const getBrowserLanguage = () => {
		try {
			const browserLanguage = window.navigator.language.split('-')[0].toUpperCase();
			const isBrowserLanguageInAvailableTranslations = Object.keys(allLocales).includes(browserLanguage);

			if (isBrowserLanguageInAvailableTranslations) {
				return browserLanguage;
			}
		} catch (error) {
			return undefined;
		}
	};

	const selectedLanguage = useMemo(() => {
		if (currentUser) {
			return currentUser.language.id;
		}

		if (selectedLanguageFromState) {
			return selectedLanguageFromState;
		}

		const cookieLanguage = getCookieLanguage();

		if (cookieLanguage) {
			return cookieLanguage;
		}

		if (router.locale && router.locale !== 'default') {
			const urlLanguage = router.locale.toLocaleUpperCase();
			return urlLanguage;
		}

		if (router.locale === 'default') {
			const browserLanguage = getBrowserLanguage();
			if (browserLanguage) {
				return browserLanguage;
			}
		}

		return defaultLanguage;
	}, [currentUser, defaultLanguage, getCookieLanguage, router.locale, selectedLanguageFromState]);

	useEffect(() => {
		const date = new Date();
		date.setDate(date.getDate() + 3600);
		setCookie('language', selectedLanguage, {
			expires: date.toString(),
		});
	}, [selectedLanguage]);

	useEffect(() => {
		if (!selectedLanguageFromState || selectedLanguageFromState !== selectedLanguage) {
			dispatch(setLanguage(selectedLanguage));
		}
	}, [currentUser, dispatch, selectedLanguage, selectedLanguageFromState]);

	useEffect(() => {
		document.documentElement.lang = selectedLanguage;

		axiosConfig.upsertHeader('Accept-Language', selectedLanguage);
		dispatch(setLanguage(selectedLanguage));
		dispatch(fetchConfig())
			.then(unwrapResult)
			.catch((error) => {
				const thrownError = parseError(error);
				dispatch(
					showToast({
						message: thrownError.message,
						type: 'warning',
						title: getIntlInstance().formatMessage({ id: 'bcs-Common-Oops' }),
					})
				);
			});
	}, [dispatch, selectedLanguage]);

	useEffect(() => {
		if (router.locale !== selectedLanguage.toLocaleLowerCase()) {
			router.push({ pathname: router.pathname, query: router.query }, undefined, {
				locale: selectedLanguage.toLocaleLowerCase(),
			});
		}
	}, [router, selectedLanguage]);

	return (
		<IntlProvider locale={selectedLanguage} messages={allLocales[selectedLanguage]}>
			{children}
		</IntlProvider>
	);
};

export default Localization;
