import React from 'react';

const IconGamyLogoBlack = () => {
	return (
		<svg width='100' height='28' viewBox='0 0 100 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.4891 10.5802H20.9291V19.1002C19.7891 19.9602 18.4691 20.6202 16.9691 21.0802C15.4691 21.5402 13.9591 21.7702 12.4391 21.7702C10.2591 21.7702 8.29908 21.3102 6.55908 20.3902C4.81908 19.4502 3.44908 18.1602 2.44908 16.5202C1.46908 14.8602 0.979082 12.9902 0.979082 10.9102C0.979082 8.83016 1.46908 6.97016 2.44908 5.33016C3.44908 3.67016 4.82908 2.38016 6.58908 1.46016C8.34908 0.520156 10.3291 0.0501559 12.5291 0.0501559C14.3691 0.0501559 16.0391 0.360155 17.5391 0.980155C19.0391 1.60015 20.2991 2.50016 21.3191 3.68016L18.1991 6.56016C16.6991 4.98016 14.8891 4.19016 12.7691 4.19016C11.4291 4.19016 10.2391 4.47016 9.19908 5.03016C8.15908 5.59016 7.34908 6.38016 6.76908 7.40016C6.18908 8.42016 5.89908 9.59016 5.89908 10.9102C5.89908 12.2102 6.18908 13.3702 6.76908 14.3902C7.34908 15.4102 8.14908 16.2102 9.16908 16.7902C10.2091 17.3502 11.3891 17.6302 12.7091 17.6302C14.1091 17.6302 15.3691 17.3302 16.4891 16.7302V10.5802ZM31.1236 5.03016C33.6236 5.03016 35.5436 5.63016 36.8836 6.83016C38.2236 8.01016 38.8936 9.80016 38.8936 12.2002V21.4102H34.5136V19.4002C33.6336 20.9002 31.9936 21.6502 29.5936 21.6502C28.3536 21.6502 27.2736 21.4402 26.3536 21.0202C25.4536 20.6002 24.7636 20.0202 24.2836 19.2802C23.8036 18.5402 23.5636 17.7002 23.5636 16.7602C23.5636 15.2602 24.1236 14.0802 25.2436 13.2202C26.3836 12.3602 28.1336 11.9302 30.4936 11.9302H34.2136C34.2136 10.9102 33.9036 10.1302 33.2836 9.59016C32.6636 9.03016 31.7336 8.75016 30.4936 8.75016C29.6336 8.75016 28.7836 8.89016 27.9436 9.17016C27.1236 9.43016 26.4236 9.79016 25.8436 10.2502L24.1636 6.98016C25.0436 6.36016 26.0936 5.88016 27.3136 5.54015C28.5536 5.20016 29.8236 5.03016 31.1236 5.03016ZM30.7636 18.5002C31.5636 18.5002 32.2736 18.3202 32.8936 17.9602C33.5136 17.5802 33.9536 17.0302 34.2136 16.3102V14.6602H31.0036C29.0836 14.6602 28.1236 15.2902 28.1236 16.5502C28.1236 17.1502 28.3536 17.6302 28.8136 17.9902C29.2936 18.3302 29.9436 18.5002 30.7636 18.5002Z'
				fill='#FF652E'
			/>
			<path
				d='M63.2992 5.03016C65.3192 5.03016 66.9192 5.63016 68.0992 6.83016C69.2992 8.01016 69.8992 9.79016 69.8992 12.1702V21.4102H65.2192V12.8902C65.2192 11.6102 64.9492 10.6602 64.4092 10.0402C63.8892 9.40016 63.1392 9.08016 62.1592 9.08016C61.0592 9.08016 60.1892 9.44016 59.5492 10.1602C58.9092 10.8602 58.5892 11.9102 58.5892 13.3102V21.4102H53.9092V12.8902C53.9092 10.3502 52.8892 9.08016 50.8492 9.08016C49.7692 9.08016 48.9092 9.44016 48.2692 10.1602C47.6292 10.8602 47.3092 11.9102 47.3092 13.3102V21.4102H42.6292V5.27015H47.0992V7.13016C47.6992 6.45016 48.4292 5.93016 49.2892 5.57016C50.1692 5.21016 51.1292 5.03016 52.1692 5.03016C53.3092 5.03016 54.3392 5.26016 55.2592 5.72016C56.1792 6.16016 56.9192 6.81015 57.4792 7.67016C58.1392 6.83016 58.9692 6.18016 59.9692 5.72016C60.9892 5.26016 62.0992 5.03016 63.2992 5.03016ZM89.3211 5.27015L82.0311 22.4002C81.2911 24.2602 80.3711 25.5702 79.2711 26.3302C78.1911 27.0902 76.8811 27.4702 75.3411 27.4702C74.5011 27.4702 73.6711 27.3402 72.8511 27.0802C72.0311 26.8202 71.3611 26.4602 70.8411 26.0002L72.5511 22.6702C72.9111 22.9902 73.3211 23.2402 73.7811 23.4202C74.2611 23.6002 74.7311 23.6902 75.1911 23.6902C75.8311 23.6902 76.3511 23.5302 76.7511 23.2102C77.1511 22.9102 77.5111 22.4002 77.8311 21.6802L77.8911 21.5302L70.9011 5.27015H75.7311L80.2611 16.2202L84.8211 5.27015H89.3211Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M91.7695 5.56132C91.7695 3.46095 93.4498 1.68982 95.6183 1.68982C97.7755 1.68982 99.4558 3.42689 99.4558 5.53861C99.4558 7.63899 97.7641 9.41011 95.5956 9.41011C93.4385 9.41011 91.7695 7.66169 91.7695 5.56132ZM92.587 5.56132C92.587 7.20756 93.8926 8.55861 95.5956 8.55861C97.3213 8.55861 98.6383 7.18485 98.6383 5.53861C98.6383 3.89237 97.3213 2.54132 95.6183 2.54132C93.904 2.54132 92.587 3.91508 92.587 5.56132ZM95.7205 3.58583C96.6969 3.58583 97.2419 4.05132 97.2419 4.85741C97.2419 5.43643 96.9921 5.84515 96.5379 6.04951L97.344 7.27568H96.4017L95.8113 6.20846H95.7205H94.9712V7.27568H94.1537V3.58583H95.7205ZM94.9712 4.24433V5.54997H95.7205C96.186 5.54997 96.4585 5.31155 96.4585 4.89147C96.4585 4.4714 96.186 4.24433 95.7205 4.24433H94.9712Z'
				fill='black'
			/>
		</svg>
	);
};

export default IconGamyLogoBlack;
