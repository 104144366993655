import { ILayoutState } from '@features/layout/models/layout-state.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialLayoutState: ILayoutState = {
	selectedLanguage: '',
	navOpen: false,
};

const layoutSlice = createSlice({
	name: '[LAYOUT] - ',
	initialState: initialLayoutState,
	reducers: {
		setLanguage: (state, { payload }: PayloadAction<string>) => {
			state.selectedLanguage = payload;
		},
		setNavOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.navOpen = payload;
		},
	},
});

export const { setLanguage, setNavOpen } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
