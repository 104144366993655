import Script from 'next/script';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCommonScriptKey } from '../store/common.selectors';
interface IHotjarProps {
	host: string;
}

const Hotjar = ({ host }: IHotjarProps) => {
	const hotJarKey = useSelector(getCommonScriptKey('hotJarKey'));

	if (!hotJarKey || host === 'localhost:3000' || host === '127.0.0.1:3000') {
		return null;
	}

	return (
		<>
			<Script
				id='hotjar'
				dangerouslySetInnerHTML={{
					__html: `(function(h,o,t,j,a,r){
						h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
						h._hjSettings={hjid:${hotJarKey},hjsv:6};
						a=o.getElementsByTagName('head')[0];
						r=o.createElement('script');r.async=1;
						r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
						a.appendChild(r);
					})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/>
		</>
	);
};

export default Hotjar;
