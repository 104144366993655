import { IGameplayJoinGameResponse } from '@features/gameplay/models/gameplay-join-game.model';
import { determineGameState } from '@features/gameplay/utils/GameCurrentScreen';
import { cloneDeep, map } from 'lodash';
import { IGameRoundParticipant } from '../models/game-round-participant.model';
import { IGamePlayState } from '../models/gameplay-state.model';
import { IGamePlayTournamentInfo } from '../models/gameplay-tournament-info.model';
import {
	applyWsMessageToGameInfo,
	filterByDatesMessages,
	getWSLastMessage,
	sortByWsLastModifiedDate,
} from './gameInfoUtils';
import { Update } from '@reduxjs/toolkit';

export const determineBalloonPoints = (data: IGameplayJoinGameResponse): number | null => {
	if (data.gameRoundParticipantScoreResource) {
		const { gameRoundParticipantScoreResource } = data;
		const { currentGameRoundParticipant } = gameRoundParticipantScoreResource;
		const { currentPlayResult } = currentGameRoundParticipant;
		if (currentPlayResult) {
			const { points } = currentPlayResult;
			return points;
		}
	}

	return 0;
};

export const ensureCurrentParticipantIsActive = (data: IGameplayJoinGameResponse): IGameplayJoinGameResponse => {
	if (data.gameRoundParticipantScoreResource) {
		const currentGameRoundParticipantId = data.gameRoundParticipantScoreResource.currentGameRoundParticipant.id;
		data.gameRoundParticipantScoreResource.currentGameRoundParticipant.active = true;
		data.gameRoundParticipantScoreResource.gameRoundParticipants.result = map(
			data.gameRoundParticipantScoreResource.gameRoundParticipants.result,
			(participant) => {
				if (participant.id === currentGameRoundParticipantId) {
					participant.active = true;
				}
				return participant;
			}
		);
	}

	return data;
};

export const gameInfoIsValid = (data: IGameplayJoinGameResponse): boolean => {
	const currentScreen = determineGameState(data);

	// game info says that we have 15 questions
	// for 15
	// get start time, add N seconds = next event date time from ws message -> push to array

	/*
		[
			{state: 'QUESTION', time: 123123123},
			{state: 'CHECK_ANSWER', time: 123123123},
			{state: 'QUESTION_RESULTS', time: 123123123},

			{state: 'QUESTION', time: 123123123},
			{state: 'CHECK_ANSWER', time: 123123123},
			{state: 'QUESTION_RESULTS', time: 123123123},

			{state: 'QUESTION', time: 123123123},
			{state: 'CHECK_ANSWER', time: 123123123},
			{state: 'QUESTION_RESULTS', time: 123123123},
		]
	 */

	// START AT TIME

	// QUESTION
	// CHECK ANSWER
	// RESULTS
	// ....

	// QUESTION
	// CHECK ANSWER
	// RESULTS
	// ....

	// QUESTION
	// CHECK ANSWER
	// RESULTS
	// ....

	// QUESTION
	// CHECK ANSWER
	// RESULTS
	// ....

	//console.log('currentScreen', currentScreen); // QUESTION

	return false;
};

export const getCurrentParticipant = (currentGameRoundParticipant: IGameRoundParticipant): IGameRoundParticipant => {
	return {
		...currentGameRoundParticipant,
		hasAnswered: false,
		active: true,
		currentPlayResult: {
			answerId: currentGameRoundParticipant.currentPlayResult!.answerId,
			correct: currentGameRoundParticipant.currentPlayResult!?.correct,
			points: null,
		},
	};
};

export const participantDuringQuestion = (participant: IGameRoundParticipant): IGameRoundParticipant => {
	return Object.assign({}, participant, {
		currentPlayResult: {
			answerId: participant.currentPlayResult?.answerId,
			correct: participant.currentPlayResult?.correct,
			points: null,
		},
	});
};

export const participantDuringQuestionResults = (participant: IGameRoundParticipant): IGameRoundParticipant => {
	return Object.assign(participant, {
		currentPlayResult: Object.assign({}, participant.currentPlayResult, { points: null }),
	});
};

export const createGameRoundParticipantsWithoutCurrentPlayResult = (gameRoundParticipants: IGameRoundParticipant[]) => {
	return gameRoundParticipants.map((participant) => {
		return participantDuringQuestion(participant);
	});
};

export const createGameRoundParticipantsWithoutCurrentPoints = (gameRoundParticipants: IGameRoundParticipant[]) => {
	return gameRoundParticipants.map((participant) => {
		return participantDuringQuestionResults(participant);
	});
};

export const fillGameInfo = (payload: { data: IGameplayJoinGameResponse }): IGamePlayTournamentInfo => {
	return {
		correctAnswerId: payload.data.correctAnswerId,
		gameRoundId: payload.data.gameRoundId,
		logoId: payload.data.logoId,
		playNumber: payload.data.playNumber,
		questionResource: payload.data.questionResource,
		results: payload.data.results,
		rewardsCount: payload.data.rewardsCount,
		totalPlays: payload.data.totalPlays,
		tournamentName: payload.data.tournamentName,
		merchantId: payload.data.merchantId,
		tournamentStartDate: payload.data.tournamentStartDate,
		tournamentStatusId: payload.data.tournamentStatusId,
		currentGameRoundPlayResource: payload.data.currentGameRoundPlayResource,
		nextEventDateTime: payload.data.nextEventDateTime,
		nextQuestionWaitTimeMillis: payload.data.nextQuestionWaitTimeMillis,
		questionResultsWaitTimeMillis: payload.data.questionResultsWaitTimeMillis,
		tournamentType: payload.data.tournamentType,
		championshipId: payload.data.championshipId,
		createdBy: payload.data.createdBy,
		maxParticipantsLimit: payload.data.maxParticipantsLimit,
		participantsCount: payload.data.participantsCount,
		entranceTypeId: payload.data.entranceTypeId,
	};
};

export interface IGetApplicableWebsocketMessagesParams {
	messages: IGamePlayState['bufferWsMessages'];
	gameInfo: IGameplayJoinGameResponse;
}
export interface IGetApplicableWebsocketMessagesReturnType {
	gameInfo: IGameplayJoinGameResponse;
	updateParticipantsScores: ReadonlyArray<Update<IGameRoundParticipant>> | null;
}

export const getApplicableWebsocketMessages = (
	params: IGetApplicableWebsocketMessagesParams
): IGetApplicableWebsocketMessagesReturnType => {
	const { gameInfo, messages } = params;

	// sort by date
	const wsSortByLastModifiedDate = sortByWsLastModifiedDate(messages);

	// get all messages that are older then gameInfo api lastModifiedDate +
	const wsFilterByLastModifiedDate = filterByDatesMessages(wsSortByLastModifiedDate, gameInfo);

	// get last date method
	const lastWsMessage = getWSLastMessage(wsFilterByLastModifiedDate);

	if (lastWsMessage) {
		return applyWsMessageToGameInfo(lastWsMessage, gameInfo);
	} else {
		return {
			gameInfo: params.gameInfo,
			updateParticipantsScores: null,
		};
	}
};

export const updateCurrentParticipant = (
	currentParticipant: IGameRoundParticipant,
	updateParticipantsScores: ReadonlyArray<Update<IGameRoundParticipant>> | null,
	balloonPoints: number | null
) => {
	if (!updateParticipantsScores) {
		return { currentParticipant: currentParticipant, balloonPoints: balloonPoints };
	}

	let updateResultForCurrentParticipant = cloneDeep(currentParticipant);

	const currentParticipantUpdate = updateParticipantsScores.find(({ id }) => currentParticipant.id === id);

	if (currentParticipantUpdate?.changes.currentPlayResult) {
		updateResultForCurrentParticipant = {
			...updateResultForCurrentParticipant,
			currentPlayResult: {
				...currentParticipantUpdate.changes.currentPlayResult,
				answerId: updateResultForCurrentParticipant.currentPlayResult?.answerId || null,
			},
		};
		balloonPoints = currentParticipantUpdate.changes.currentPlayResult.points;
	}

	return { currentParticipant: updateResultForCurrentParticipant, balloonPoints: balloonPoints };
};
