import { useRouter } from 'next/router';
import React, { ReactNode, useState } from 'react';

interface ITabButtonProps {
	children: ReactNode;
	colorful?: boolean;
	LinkHref?: string;
	isSelected?: boolean;
}

const TabButton = ({ children, colorful, LinkHref, isSelected }: ITabButtonProps) => {
	const router = useRouter();
	const [hover, setHover] = useState(false);

	const getLinkVisualState = () => {
		if (
			(hover ||
				(LinkHref && router.pathname.includes(LinkHref) && LinkHref !== '/') ||
				LinkHref === router.pathname ||
				isSelected) &&
			!colorful
		) {
			return 'bg-main-500';
		}
		return 'bg-transparent';
	};

	const getColorFulStyle = () => {
		if (colorful) {
			return 'bg-main-500 text-white px-4 py-2';
		}
		return 'text-headerColor-100';
	};

	return (
		<div className='h-full grid place-items-center relative w-max'>
			<div
				className={`cursor-pointer rounded-xl 2xl:mx-4 mx-2 my-2 ${getColorFulStyle()}`}
				onMouseOver={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				data-testid='linkContent'
			>
				{children}
			</div>

			<span
				className={`transition-all absolute -bottom-0 inset-x-0 w-full ${getLinkVisualState()}`}
				style={{ height: '6px' }}
				data-testid='linkHover'
			></span>
		</div>
	);
};

export default TabButton;
