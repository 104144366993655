import { showToast } from '@features/common/store/common.reducer';
import { getIntlInstance } from '@features/layout/components/Localization';
import { resetState } from '@store/root.actions';
import store from '@store/store';
import axios, { AxiosRequestConfig } from 'axios';
import { IErrorContent } from './httpHelpers';
import { v4 as uuIdv4 } from 'uuid';

interface IRequestInterceptors {
	'Accept-Language'?: string;
	// ADD MORE HEADERS HERE IF NEEDED AND THEN USE 'upsertHeader' method to add header;
}

export class AxiosConfig {
	private requestInterceptorsIds: IRequestInterceptors;

	constructor() {
		axios.defaults.baseURL = '/api/v1';
		this.interceptAuthorization();
		this.interceptCorrelationId();
		this.requestInterceptorsIds = {};
	}

	private ejectInterceptor(headerKey: keyof IRequestInterceptors) {
		if (this.requestInterceptorsIds[headerKey] !== undefined) {
			axios.interceptors.request.eject(Number.parseInt(`${this.requestInterceptorsIds[headerKey]}`));
		}
	}

	private interceptCorrelationId() {
		axios.interceptors.request.use((request: AxiosRequestConfig) => ({
			...request,
			headers: { ...request.headers, ...{ 'Correlation-ID': uuIdv4() } },
		}));
	}

	private interceptAuthorization() {
		axios.interceptors.response.use(undefined, (error) => {
			const { formatMessage } = getIntlInstance();

			if (error.toJSON().message === 'Network Error' && !window.location.href.includes('/play')) {
				store.dispatch(
					showToast({
						message: formatMessage({ id: 'bcs-Error-NoConnection' }),
						type: 'warning',
						title: formatMessage({ id: 'bcs-Common-Oops' }),
					})
				);
				const errorData: IErrorContent<any> = {
					status: 400,
					code: 'UNAVAILABLE',
					message: formatMessage({ id: 'bcs-Error-NoConnection' }),
					fieldErrors: [],
				};

				return Promise.reject({ ...error, response: { ...error.response, data: errorData } });
			}

			const { status, config, data } = error.response;
			if (status === 502) {
				return Promise.reject({ ...error, response: { ...error.response, data: '' } });
			}

			if (status >= 500 && !window.location.href.includes('/play')) {
				store.dispatch(
					showToast({
						message: formatMessage({ id: 'bcs-Common-SomethingWentWrong' }),
						type: 'warning',
						title: formatMessage({ id: 'bcs-Common-Oops' }),
						buttonText: formatMessage({ id: 'bcs-Common-GotIt' }),
					})
				);
			}

			if (status === 401 && config.url !== '/customers/change-password') {
				store.dispatch(resetState());

				if (config.url !== '/customers/current-customer') {
					const errorData: IErrorContent<any> = {
						status: status,
						code: data.code,
						message: formatMessage({ id: 'bcs-Auth-LoginSessionExpired' }),
						fieldErrors: [],
					};

					return Promise.reject({ ...error, response: { ...error.response, data: errorData } });
				}
			}

			if (error.response.data.code === 'CUSTOMER_NOT_FOUND') {
				store.dispatch(resetState());

				const customerId = error.response.data.message.substring(17, 54);

				const errorData: IErrorContent<any> = {
					status: status,
					code: data.code,
					message: formatMessage({ id: `bcs-Error-NOT_FOUND` }, { cid: customerId }),
					fieldErrors: [],
				};

				return Promise.reject({ ...error, response: { ...error.response, data: errorData } });
			}
			return Promise.reject(error);
		});
	}

	upsertHeader(headerKey: keyof IRequestInterceptors, value: string) {
		this.ejectInterceptor(headerKey);

		const newInterceptorId = axios.interceptors.request.use((request: AxiosRequestConfig) => ({
			...request,
			headers: { ...request.headers, ...{ [headerKey]: value } },
		}));

		this.requestInterceptorsIds[headerKey] = newInterceptorId.toString();
	}
}

const axiosConfig = new AxiosConfig();

export default axiosConfig;
