import { toggleIsRecaptchaLoaded } from '@features/common/store/common.reducer';
import {
	getCommonScriptKey,
	getCommonIsRecaptchaActivated,
	getIsRecaptchaLoaded,
} from '@features/common/store/common.selectors';
import { useAppDispatch } from '@store/store';
import Head from 'next/head';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const RecaptchaScript = () => {
	const dispatch = useAppDispatch();
	const recaptchaKey = useSelector(getCommonScriptKey('recaptchaKey'));
	const isRecaptchaLoaded = useSelector(getIsRecaptchaLoaded);
	const isRecaptchaActivated = useSelector(getCommonIsRecaptchaActivated);
	const scriptRef = useRef<HTMLScriptElement>();
	const widgetRef = useRef<HTMLDivElement>();

	const onLoad = useCallback((): void => {
		const widget = document.createElement('div');
		widget.id = 'g-recaptcha';
		widgetRef.current = document.body.appendChild(widget);
		window.grecaptcha.render('g-recaptcha', {
			sitekey: recaptchaKey as string,
			size: 'invisible',
		});
		window.grecaptcha.ready(() => {
			dispatch(toggleIsRecaptchaLoaded(true));
		});
	}, [dispatch, recaptchaKey]);

	const loadScript = useCallback(() => {
		window.captchaOnLoad = onLoad;

		const scriptElement = document.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.src = `https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=${recaptchaKey}`;
		scriptElement.id = 'recaptcha-script';
		scriptElement.async = true;
		scriptElement.defer = true;

		scriptRef.current = document.body.appendChild(scriptElement);
	}, [onLoad, recaptchaKey]);

	useEffect(() => {
		if (recaptchaKey && !isRecaptchaLoaded && isRecaptchaActivated) {
			loadScript();
		}
	}, [loadScript, recaptchaKey, isRecaptchaLoaded, isRecaptchaActivated]);

	return (
		<Head>
			<link rel='preconnect' href='https://www.google.com' />
			<link rel='preconnect' href='https://www.gstatic.com' crossOrigin='' />
		</Head>
	);
};

export default RecaptchaScript;
