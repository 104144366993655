import { IConfigResponse } from '@features/common/models/config-response.model';
import { httpHandler } from '@http/httpHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import parseIOSDate from '@utils/parseIOSDate';
import axios from 'axios';
import { CommonActionTypes } from './common.action-types';

export const fetchConfig = createAsyncThunk(CommonActionTypes.FETCH_CONFIG, async () => {
	const dateBeforeSyncReq = new Date();
	const { data, status } = await httpHandler<IConfigResponse>(axios.get('/config'));

	const dateAfterSyncReq = new Date();
	const roundTripDelayMs = (dateAfterSyncReq.getTime() - dateBeforeSyncReq.getTime()) / 2;
	const beTime = parseIOSDate(data.fieldValidations.serverDateTime);
	const synchronizedTime = new Date(beTime);

	synchronizedTime.setMilliseconds(roundTripDelayMs);
	const datesSyncDiffMs = synchronizedTime.getTime() - dateAfterSyncReq.getTime();

	const response: { data: IConfigResponse & { datesSyncDiffMs: number }; status: number } = {
		data: { ...data, datesSyncDiffMs },
		status,
	};

	return response;
});
