import React from 'react';

const IconFacebook = () => {
	return (
		<svg width='11' height='22' viewBox='0 0 11 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.46587 4.65653V7.50753H0.380859V10.9935H2.46986V21.3535H6.76086V10.9935H9.63986C9.63986 10.9935 9.90986 9.32153 10.0399 7.49353H6.77586V5.11053C6.81497 4.88721 6.92786 4.68347 7.09647 4.5319C7.26507 4.38033 7.47965 4.28971 7.70586 4.27453H10.0439V0.644531H6.86486C2.35986 0.644531 2.46587 4.13553 2.46587 4.65653Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconFacebook;
