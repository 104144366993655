import Image from 'next/image';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCdnUrl } from '@features/common/store/common.selectors';

interface IAvatarIconProps {
	avatarId: string;
	width: string;
	height: string;
	rounded?: boolean;
	className?: string;
	borderRadius?: string;
	marginTop?: string;
	onLoad?: () => void;
	onAvatarClicked?: (avatarId: string) => void;
}

export const AvatarIcon = (props: IAvatarIconProps) => {
	const cdnUrl = useSelector(getCdnUrl);

	return (
		<Image
			alt='avatar'
			unoptimized={true}
			onClick={() => props.onAvatarClicked && props.onAvatarClicked(props.avatarId)}
			src={`${cdnUrl}avatars/${props.avatarId}.jpg`}
			objectFit='fill'
			layout={'responsive'}
			width={props.width}
			height={props.width}
			priority={true}
			id={props.avatarId}
			onLoad={props.onLoad}
			className={`${props.rounded ? `rounded-full h-full` : null} | ${props.className}`}
		/>
	);
};

export default AvatarIcon;
