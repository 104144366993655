import { IOnsiteNotificationsState } from '@features/onsiteNotifications/models/onsiteNotifications-state.model';
import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { onsiteNotificationsAdapter } from './onsiteNotifications.reducer';

const onsiteNotificationsSelector = ({ onsiteNotifications }: IApplicationState): IOnsiteNotificationsState =>
	onsiteNotifications;
const onsiteNotificationsEntitySelectors = onsiteNotificationsAdapter.getSelectors();

export const getAllOnsiteNotifications = createSelector(onsiteNotificationsSelector, (featureState) =>
	onsiteNotificationsEntitySelectors.selectAll(featureState)
);
