import React from 'react';

const IconDice = () => {
	return (
		<svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.86 1.43579C17.2211 1.43579 19.1422 3.35666 19.1422 5.71805V14.2823C19.1422 16.6435 17.2211 18.5646 14.86 18.5646H6.29546C3.93429 18.5646 2.01321 16.6437 2.01321 14.2823V5.71782C2.01321 3.35666 3.93429 1.43557 6.29546 1.43557H14.86V1.43579ZM14.86 0.00830078H6.29546C3.14228 0.00830078 0.585938 2.56442 0.585938 5.71782V14.2821C0.585938 17.4357 3.14228 19.9919 6.29546 19.9919H14.8597C18.0132 19.9919 20.5695 17.4357 20.5695 14.2823V5.71782C20.5695 2.56442 18.0132 0.00830078 14.86 0.00830078ZM6.29544 7.14535C7.08382 7.14535 7.72293 6.50624 7.72293 5.71786C7.72293 4.92948 7.08382 4.29037 6.29544 4.29037C5.50706 4.29037 4.86795 4.92948 4.86795 5.71786C4.86795 6.50624 5.50706 7.14535 6.29544 7.14535ZM16.2874 14.2824C16.2874 15.0708 15.6483 15.7099 14.86 15.7099C14.0716 15.7099 13.4325 15.0708 13.4325 14.2824C13.4325 13.494 14.0716 12.8549 14.86 12.8549C15.6483 12.8549 16.2874 13.494 16.2874 14.2824ZM14.86 7.14535C15.6483 7.14535 16.2874 6.50624 16.2874 5.71786C16.2874 4.92948 15.6483 4.29037 14.86 4.29037C14.0716 4.29037 13.4325 4.92948 13.4325 5.71786C13.4325 6.50624 14.0716 7.14535 14.86 7.14535ZM7.72293 14.2824C7.72293 15.0708 7.08382 15.7099 6.29544 15.7099C5.50706 15.7099 4.86795 15.0708 4.86795 14.2824C4.86795 13.494 5.50706 12.8549 6.29544 12.8549C7.08382 12.8549 7.72293 13.494 7.72293 14.2824ZM6.29544 11.4276C7.08382 11.4276 7.72293 10.7885 7.72293 10.0001C7.72293 9.21176 7.08382 8.57265 6.29544 8.57265C5.50706 8.57265 4.86795 9.21176 4.86795 10.0001C4.86795 10.7885 5.50706 11.4276 6.29544 11.4276ZM16.2874 10.0001C16.2874 10.7885 15.6483 11.4276 14.86 11.4276C14.0716 11.4276 13.4325 10.7885 13.4325 10.0001C13.4325 9.21176 14.0716 8.57265 14.86 8.57265C15.6483 8.57265 16.2874 9.21176 16.2874 10.0001Z'
				fill='#1B1D21'
			/>
		</svg>
	);
};

export default IconDice;
