import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWSOnsiteNotification } from '../models/onsiteNotification-message.model';
import { IOnsiteNotification, IOnsiteNotificationLocal } from '../models/onsiteNotification.model';
import { IOnsiteNotificationsState } from '../models/onsiteNotifications-state.model';

export const onsiteNotificationsAdapter = createEntityAdapter<IOnsiteNotification>({
	selectId: (notification) => notification.id,
	sortComparer: (n1, n2) => new Date(n2.dateReceived).getDate() - new Date(n1.dateReceived).getDate(),
});

export const initialOnsiteNotificationsState = onsiteNotificationsAdapter.getInitialState<IOnsiteNotificationsState>({
	ids: [],
	entities: {},
	isOpened: false,
});

const onsiteNotificationsSlice = createSlice({
	name: '[ONSITE_NOTIFICATIONS] - ',
	initialState: initialOnsiteNotificationsState,
	reducers: {
		removeOnsiteNotification: (state, action: PayloadAction<Pick<IOnsiteNotification, 'id'>>) => {
			onsiteNotificationsAdapter.removeOne(state, action.payload.id);
		},
		onsiteNotificationReceivedWS: (state, action: PayloadAction<IWSOnsiteNotification>) => {
			onsiteNotificationsAdapter.addOne(state, action.payload.data);
		},
		onsiteNotificationReceivedLocal: (state, action: PayloadAction<IOnsiteNotificationLocal>) => {
			onsiteNotificationsAdapter.addOne(state, action.payload);
		},
		closeNotificationsSocket: (state) => {
			onsiteNotificationsAdapter.removeAll(state);
		},
		setIsNotificationsSocketOpened: (state, action: PayloadAction<boolean>) => {
			state.isOpened = action.payload;
		},
	},
});
export const {
	removeOnsiteNotification,
	onsiteNotificationReceivedWS,
	onsiteNotificationReceivedLocal,
	closeNotificationsSocket,
	setIsNotificationsSocketOpened,
} = onsiteNotificationsSlice.actions;
export const onsiteNotificationsReducer = onsiteNotificationsSlice.reducer;
