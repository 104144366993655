import IconPWADownload from '@features/common/components/IconPWADownload';
import NotificationInstallPrompt from '@features/notifications/components/NotificationInstallPrompt';
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

interface InstallPromptEvent extends Event {
	prompt: () => Promise<void>;
	userChoice: Promise<Record<'outcome', string>>;
}

let deferredInstall: InstallPromptEvent | null = null;

const PwaInstallPrompt = () => {
	const [promptInstall, setPromptInstall] = useState<boolean>(false);
	const [showPrompt, setShowPrompt] = useState<boolean>(false);
	const [isIOs, setIsIOs] = useState<boolean>(false);
	const [isSupported, setIsSupported] = useState<boolean | null>(false);
	const [isPromptReady, setIsPromptReady] = useState(deferredInstall);
	const { formatMessage } = useIntl();

	useEffect(() => {
		const subscription = fromEvent(window, 'beforeinstallprompt')
			.pipe(
				tap((event: Event) => {
					event.preventDefault();
					deferredInstall = event as InstallPromptEvent;
					setIsPromptReady(deferredInstall);
				})
			)
			.subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const onInstallPromptClose = () => {
		setShowPrompt(false);
	};

	useEffect(() => {
		setIsIOs(isIOS);
	}, []);

	const checkPlatform = () => {
		if (promptInstall) {
			setShowPrompt(true);
		} else {
			setShowPrompt(false);
			try {
				(deferredInstall as any).prompt();
			} catch (error) {
				return error;
			}
		}
	};
	useEffect(() => {
		if (isIOS) {
			setIsSupported(true);
			setPromptInstall(true);
		} else if (deferredInstall === null) {
			setIsSupported(false);
		} else {
			setIsSupported(true);
		}
	}, [isIOs, isPromptReady]);

	const onInstallPrompt = async () => {
		checkPlatform();
	};

	if (!isSupported) {
		return null;
	}

	return (
		<>
			<div
				className='w-[60%] ml-2 mt-13 lg:hidden block'
				style={{ border: '1px solid #E4E4E4', marginBottom: '47px' }}
			/>

			<div className='cursor-pointer'>
				<div className='flex'>
					<button
						title={formatMessage({ id: 'bcs-Install-Gamy' })}
						data-testid={'InstallGamyBtn'}
						onClick={onInstallPrompt}
						className='flex'
					>
						<IconPWADownload />
						<p
							className='lg:hidden block'
							style={{
								fontSize: '18px',
								lineHeight: '22px',
								fontStyle: 'normal',
								fontFamily: 'Montserrat',
								marginLeft: '10px',
							}}
						>
							{formatMessage({ id: 'bcs-Install-Gamy' })}
						</p>
					</button>
				</div>
				<NotificationInstallPrompt isOpen={showPrompt} onClose={() => onInstallPromptClose()} />
			</div>
		</>
	);
};

export default PwaInstallPrompt;
