import FacebookPixel from '@features/common/components/FacebookPixel';
import Hotjar from '@features/common/components/Hotjar';
import RecaptchaScript from '@features/common/components/Recaptcha/RecaptchaScript';
import GoogleTagManagerScript from '@features/layout/components/GoogleTagManagerScript';
import Layout from '@features/layout/components/Layout';
import LayoutPWAUpdate from '@features/layout/components/LayoutPWAUpdate';
import Localization from '@features/layout/components/Localization';
import store from '@store/store';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import 'tailwindcss/tailwind.css';
import '../styles/globals.scss';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import SplashScreen from '@features/layout/components/SplashScreen';
import packageJson from '../package.json';
import { configServerSide } from '@utils/configServerSide';
import GoogleAnalytics from '@features/common/components/GoogleAnalytics';
import { IConfigResponse } from '@features/common/models/config-response.model';

// polyfill start
require('intersection-observer');
// polyfill end

interface AppPropsWithConfig extends AppProps {
	host: string;
	cookie: string;
	defaultLanguage: string;
	cdnUrl?: string;
}

const MyApp = ({ Component, pageProps, host, cookie, cdnUrl, defaultLanguage }: AppPropsWithConfig) => {
	const [appIsSuspended, setAppIsSuspended] = useState(false);

	const cdnStatic = `${cdnUrl}/static/${packageJson.version}/social_share_image.jpg`;

	const addVisibilityEventListener = () => {
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				window.location.reload();
			} else {
				setAppIsSuspended(true);
			}
		});
	};

	useEffect(() => {
		if (isMobile) {
			addVisibilityEventListener();
		}
	}, []);

	return (
		<Provider store={store}>
			<Head>
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
				/>
				<meta
					property='og:title'
					content={(pageProps as any).title ? (pageProps as any).title : 'Gamy.fun'}
					key='ogTitle'
				/>
				<meta property='og:description' content='Играй куизове, печели награди!' />
				<meta property='og:image' content={cdnStatic} />
				<meta property='og:image:width' content='400' />
				<meta property='og:image:height' content='300' />
			</Head>
			<GoogleTagManagerScript host={host} />
			<Hotjar host={host} />
			<RecaptchaScript />
			<FacebookPixel host={host} />
			<GoogleAnalytics host={host} />
			<LayoutPWAUpdate />
			{appIsSuspended ? (
				<SplashScreen></SplashScreen>
			) : (
				<Localization cookie={cookie} defaultLanguage={defaultLanguage}>
					<Layout>
						<Component {...pageProps} />
					</Layout>
				</Localization>
			)}
		</Provider>
	);
};

MyApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext);
	let host: any = '';
	let configurationResponse: IConfigResponse | null = null;

	if (appContext.ctx.req) {
		host = configServerSide(appContext.ctx?.req);
		const apiUrl = `${host}/api/v1/config`;

		const response = await fetch(apiUrl, { method: 'GET' });

		configurationResponse = await response.json();
	}

	return {
		...appProps,
		host: appContext.ctx.req?.headers.host || '',
		cookie: appContext.ctx.req?.headers.cookie || '',
		cdnUrl: configurationResponse?.cdnUrl,
		defaultLanguage: configurationResponse?.defaultLanguage,
	};
};

export default MyApp;
