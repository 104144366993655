import React from 'react';

const IconInstagram = () => {
	return (
		<svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.10042 0.53125H14.5004C17.7004 0.53125 20.3004 3.13125 20.3004 6.33125V14.7313C20.3004 16.2695 19.6893 17.7448 18.6016 18.8325C17.5139 19.9202 16.0387 20.5312 14.5004 20.5312H6.10042C2.90042 20.5312 0.300415 17.9313 0.300415 14.7313V6.33125C0.300415 4.79299 0.911485 3.31774 1.9992 2.23003C3.08691 1.14232 4.56216 0.53125 6.10042 0.53125ZM5.90041 2.53125C4.94564 2.53125 4.02996 2.91053 3.35483 3.58567C2.6797 4.2608 2.30042 5.17647 2.30042 6.13125V14.9312C2.30042 16.9212 3.91041 18.5312 5.90041 18.5312H14.7004C15.6552 18.5312 16.5709 18.152 17.246 17.4768C17.9211 16.8017 18.3004 15.886 18.3004 14.9312V6.13125C18.3004 4.14125 16.6904 2.53125 14.7004 2.53125H5.90041ZM15.5504 4.03125C15.8819 4.03125 16.1999 4.16295 16.4343 4.39737C16.6687 4.63179 16.8004 4.94973 16.8004 5.28125C16.8004 5.61277 16.6687 5.93071 16.4343 6.16513C16.1999 6.39955 15.8819 6.53125 15.5504 6.53125C15.2189 6.53125 14.901 6.39955 14.6665 6.16513C14.4321 5.93071 14.3004 5.61277 14.3004 5.28125C14.3004 4.94973 14.4321 4.63179 14.6665 4.39737C14.901 4.16295 15.2189 4.03125 15.5504 4.03125ZM10.3004 5.53125C11.6265 5.53125 12.8983 6.05803 13.8359 6.99572C14.7736 7.9334 15.3004 9.20517 15.3004 10.5312C15.3004 11.8573 14.7736 13.1291 13.8359 14.0668C12.8983 15.0045 11.6265 15.5312 10.3004 15.5312C8.97433 15.5312 7.70256 15.0045 6.76488 14.0668C5.8272 13.1291 5.30042 11.8573 5.30042 10.5312C5.30042 9.20517 5.8272 7.9334 6.76488 6.99572C7.70256 6.05803 8.97433 5.53125 10.3004 5.53125ZM10.3004 7.53125C9.50477 7.53125 8.7417 7.84732 8.17909 8.40993C7.61649 8.97254 7.30042 9.7356 7.30042 10.5312C7.30042 11.3269 7.61649 12.09 8.17909 12.6526C8.7417 13.2152 9.50477 13.5312 10.3004 13.5312C11.0961 13.5312 11.8591 13.2152 12.4217 12.6526C12.9843 12.09 13.3004 11.3269 13.3004 10.5312C13.3004 9.7356 12.9843 8.97254 12.4217 8.40993C11.8591 7.84732 11.0961 7.53125 10.3004 7.53125Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconInstagram;
