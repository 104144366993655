import ThreeDotMenu from '@features/common/components/ThreeDotMenu';
import { enableScroll } from '@features/common/store/common.reducer';
import { getCdnUrl } from '@features/common/store/common.selectors';
import { INotification, NotificationStatus } from '@features/notifications/models/notification.model';
import { readNotification, unreadNotification } from '@features/notifications/store/notifications.actions';
import useParseToRelativeTime from '@hooks/useParseToRelativeTime';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames/dedupe';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

interface INotificationItemProps {
	notification: INotification;
}

const NotificationItem = (props: INotificationItemProps) => {
	const { notification } = props;
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const router = useRouter();
	const parseToRelativeTime = useParseToRelativeTime();
	const notificationSentTime = parseToRelativeTime(new Date(notification.scheduledDate), true);
	const cdnUrl = useSelector(getCdnUrl);

	const onMarkNotificationAsRead = (notificationId: string) => {
		if (notification.status === 'UNREAD') {
			dispatch(readNotification(notificationId))
				.then(unwrapResult)
				.catch((err) => err);
		}
	};

	const onMarkNotificationAsUnread = (notificationId: string) => {
		dispatch(unreadNotification(notificationId))
			.then(unwrapResult)
			.catch((err) => err);
	};

	const onClickNotificationItem = (notification: INotification) => {
		onMarkNotificationAsRead(notification.id);
		dispatch(enableScroll());
		if (notification.messageData && notification.messageData.championshipName) {
			router.push(`/championships/${notification.eventObjectId}/view`);
		} else {
			router.push(`/tournaments/${notification.eventObjectId}/view`);
		}
	};

	return (
		<div
			className={classNames(`flex w-full justify-start items-center space-x-2 min-h-[97px] h-full`, {
				'bg-white': notification.status === NotificationStatus.UNREAD,
				'bg-backgroundGray-200': notification.status !== NotificationStatus.UNREAD,
			})}
		>
			<div
				className={classNames(`bg-main-500 h-16 min-w-[0.375rem]`, {
					'bg-main-500': notification.status === NotificationStatus.UNREAD,
					'bg-transparent': notification.status !== NotificationStatus.UNREAD,
				})}
			></div>
			<div className='pr-4 flex-1 h-full'>
				<div className='flex flex-1 h-full border-b border-brightGray-200 pl-1 space-x-2'>
					<div className='flex flex-1 items-center space-x-4 self-stretch'>
						<button
							data-testid='notificationClickableItemBtn'
							onClick={() => onClickNotificationItem(notification)}
						>
							<div className={`flex justify-start flex-1 space-x-3 items-center my-[19px]`}>
								<div className='w-18 min-w-[72px]'>
									<div className='aspect-w-14 aspect-h-9 rounded-[0.188rem] overflow-hidden'>
										{notification.imageId ? (
											<Image
												alt='notification-image'
												unoptimized={true}
												src={`${cdnUrl}files/${notification.imageId}.jpeg`}
												layout={'fill'}
												objectFit='cover'
												priority={true}
											/>
										) : null}
									</div>
								</div>
								<p
									className='font-normal text-sm text-dark-700 tracking-[0.1px] text-left'
									data-testid='messageContent'
									title={notification.messageContent}
								>
									{notification.messageContent}
								</p>
							</div>
						</button>
					</div>
					<div className='flex flex-col sm:w-max items-center justify-center sm:space-y-2 h-auto min-h-[96px]'>
						<p className='text-grayText-600 text-xs text-left' data-testid='dateSent'>
							{formatMessage({ id: notificationSentTime.id }, { value: notificationSentTime.name })}
						</p>
						<ThreeDotMenu iconColor={'gray'} className='h-10 sm:h-auto'>
							{notification.status === NotificationStatus.UNREAD ? (
								<button
									className='w-full text-left hover:bg-gray-300 xs:h-8 px-2 transition-all'
									type='button'
									data-testid='markAsReadBtn'
									onClick={() => onMarkNotificationAsRead(notification.id)}
								>
									{formatMessage({ id: 'bcs-Notification-MarkAsRead' })}
								</button>
							) : (
								<button
									className='w-full text-left hover:bg-gray-300 xs:h-8 px-2 transition-all'
									type='button'
									data-testid='markAsReadBtn'
									onClick={() => onMarkNotificationAsUnread(notification.id)}
								>
									{formatMessage({ id: 'bcs-Notification-MarkAsUnread' })}
								</button>
							)}
						</ThreeDotMenu>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationItem;
