import { ITournament, ITournamentCompact, ITournamentFull } from '@features/tournaments/models/tournament.model';
import { ITournamentsState } from '@features/tournaments/models/tournaments-state.model';
import { PayloadAction, TaskAbortError, Update, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IChampionshipEndedTournament } from '../models/tournament-championship-ended.model';
import { IWSTournamentNewEntriesParsed } from '../models/tournament-event-new-tournament.model';
import { IWSTournamentStatusChanged } from '../models/tournament-event-status.model';
import {
	emitHttpChampionshipEndedGamys,
	emitHttpChampionshipsTournaments,
	emitHttpFetchMerchantFinishedTournaments,
	emitHttpFetchMerchantTournaments,
	emitHttpFetchPrizedTournaments,
	emitHttpFetchRegisteredTournaments,
	emitHttpFetchTournament,
	emitHttpFetchTournamentsHome,
	emitHttpFetchTournamentsPageable,
	emitHttpRegisterForTournament,
	wsTournamentParticipantRegistered,
} from './tournaments.actions';
import { IWSChampionshipStatusChanged } from '@features/championships/models/championship-event-status.model';

const sortComparerTournaments = (tournament1: ITournament, tournament2: ITournament) => {
	return (
		new Date(tournament1.tournamentStartDate).getTime() - new Date(tournament2.tournamentStartDate).getTime() ||
		tournament2.participantsCount - tournament1.participantsCount
	);
};

const sortCompareGamyRewardsTournaments = (tournament1: ITournament, tournament2: ITournament) => {
	return (
		tournament2.tournamentPriority - tournament1.tournamentPriority ||
		sortComparerTournaments(tournament1, tournament2)
	);
};

const sortCompareChampionshipEndedTournaments = (
	tournament1: IChampionshipEndedTournament,
	tournament2: IChampionshipEndedTournament
) => {
	return new Date(tournament1.dateTime).getTime() - new Date(tournament2.dateTime).getTime();
};

export const tournamentsAdapter = createEntityAdapter<ITournamentCompact>({
	selectId: (tournament) => tournament.id,
	sortComparer: sortComparerTournaments,
});

export const registeredTournamentsAdapter = createEntityAdapter<ITournamentCompact>({
	selectId: (tournament) => tournament.id,
	sortComparer: sortComparerTournaments,
});
export const prizedTournamentsAdapter = createEntityAdapter<ITournamentCompact>({
	selectId: (tournament) => tournament.id,
	sortComparer: sortCompareGamyRewardsTournaments,
});

export const finishedMerchantTournamentsAdapter = createEntityAdapter<ITournamentCompact>({
	selectId: (tournament) => tournament.id,
});

export const championshipEndedTournamentsAdapter = createEntityAdapter<IChampionshipEndedTournament>({
	selectId: (tournament) => tournament.id,
	sortComparer: sortCompareChampionshipEndedTournaments,
});

const getIsFullTournament = (tournament: ITournament): tournament is ITournamentFull => {
	return Object.getOwnPropertyNames(tournament).includes('rewardsCount');
};

export const convertToCompactTournament = (tournament: ITournament): ITournamentCompact => {
	if (!getIsFullTournament(tournament)) {
		return tournament;
	}

	let awardName: string | null = null;

	if (tournament.rewards.length) {
		awardName = tournament.rewards[0].award.translation.name;
	}

	return {
		answerTimeMillis: tournament.answerTimeMillis,
		battleTypeId: tournament.battleTypeId,
		clientId: tournament.clientId,
		id: tournament.id,
		logoId: tournament.logoId,
		merchant: tournament.merchant,
		participantsCount: tournament.participantsCount,
		playsPerRound: tournament.playsPerRound,
		registered: tournament.registered,
		registrationEndDate: tournament.registrationEndDate,
		registrationStartDate: tournament.registrationStartDate,
		awardName: awardName,
		roundsCount: tournament.roundsCount,
		statusId: tournament.statusId,
		entranceTypeId: tournament.entranceTypeId,
		tournamentPriority: tournament.tournamentPriority,
		tournamentStartDate: tournament.tournamentStartDate,
		tournamentType: tournament.tournamentType,
		maxParticipantsLimit: tournament.maxParticipantsLimit,
		championshipId: tournament.championshipId,
		name: tournament.translation.name,
		description: tournament.translation.description,
	};
};

export const initialTournamentsState: ITournamentsState = {
	loadingStates: {
		fetchTournament: false,
		fetchTournaments: false,
		registerForTournament: false,
		fetchRegisteredTournaments: false,
		fetchPrizedTournaments: false,
		fetchMerchantTournaments: false,
		fetchFinishedMerchantTournaments: false,
		fetchAllTournaments: false,
		fetchChampionshipTournaments: false,
		fetchChampionshipEndedTournaments: false,
	},
	allTournaments: tournamentsAdapter.getInitialState({ ids: [], entities: {}, total: null }),
	registeredTournaments: registeredTournamentsAdapter.getInitialState({ ids: [], entities: {}, total: null }),
	prizedTournaments: prizedTournamentsAdapter.getInitialState({ ids: [], entities: {}, total: null }),
	championshipEndedTournaments: championshipEndedTournamentsAdapter.getInitialState({
		ids: [],
		entities: {},
		total: null,
	}),
	finishedMerchantTournaments: finishedMerchantTournamentsAdapter.getInitialState({
		ids: [],
		entities: {},
		total: null,
	}),
	selectedTournament: null,
	totalRankingParticipants: null,
};

const tournamentsSlice = createSlice({
	name: '[TOURNAMENTS] - ',
	initialState: initialTournamentsState,
	reducers: {
		emitTournamentsRemoveSelected: (state) => {
			state.selectedTournament = null;
		},
		emitTournamentRanking: (state, { payload }: any) => {
			state.totalRankingParticipants = payload;
		},
		emitTournamentsRemoveAll: (state) => {
			tournamentsAdapter.removeAll(state.allTournaments);
			registeredTournamentsAdapter.removeAll(state.registeredTournaments);
			prizedTournamentsAdapter.removeAll(state.prizedTournaments);
			championshipEndedTournamentsAdapter.removeAll(state.championshipEndedTournaments);
			finishedMerchantTournamentsAdapter.removeAll(state.finishedMerchantTournaments);

			state.allTournaments.total = null;
			state.registeredTournaments.total = null;
			state.prizedTournaments.total = null;
			state.championshipEndedTournaments.total = null;
			state.finishedMerchantTournaments.total = null;
		},
		emitTournamentsWSsubscribe: () => {
			//
		},
		emitTournamentsWSnewTournaments: (state, { payload }: PayloadAction<IWSTournamentNewEntriesParsed>) => {
			const compactTournaments = payload.data.map(convertToCompactTournament);
			tournamentsAdapter.addMany(state.allTournaments, compactTournaments);

			if (state.allTournaments.total !== null) {
				state.allTournaments.total = state.allTournaments.total + 1;
			} else {
				state.allTournaments.total = 1;
			}

			const compactPrizedTournaments = payload.data
				.filter(({ awardName }) => awardName !== null)
				.map(convertToCompactTournament);

			if (compactPrizedTournaments.length) {
				prizedTournamentsAdapter.addMany(state.prizedTournaments, compactPrizedTournaments);

				if (state.prizedTournaments.total !== null) {
					state.prizedTournaments.total = state.prizedTournaments.total + compactPrizedTournaments.length;
				} else {
					state.prizedTournaments.total = compactPrizedTournaments.length;
				}
			}

			const newOriginalCopy = payload.data.find(
				({ originalId }) => state.selectedTournament?.originalId === originalId
			);

			if (state.selectedTournament && newOriginalCopy?.originalId !== null && newOriginalCopy) {
				state.selectedTournament = { ...state.selectedTournament, latestActiveCopyId: newOriginalCopy.id };
			}
		},
		emitTournamentsWSstatusChanged: (state, { payload }: PayloadAction<IWSTournamentStatusChanged>) => {
			const tournamentIds = payload.data.tournaments.map((tournament) => tournament.id);

			if (state.selectedTournament && tournamentIds.includes(state.selectedTournament.id)) {
				if (
					payload.data.status === 'FINISHED' ||
					(payload.data.status === 'CANCELED' && state.selectedTournament.tournamentType === 'PLAY_NOW_COPY')
				) {
					state.selectedTournament = {
						...state.selectedTournament,
						statusId: payload.data.status,
						registered: false,
					};
				} else {
					state.selectedTournament = {
						...state.selectedTournament,
						statusId: payload.data.status,
					};
				}
			}

			if (state.selectedTournament && tournamentIds.includes(state.selectedTournament.id)) {
				state.selectedTournament = {
					...state.selectedTournament,
					statusId: payload.data.status,
				};
			}

			if (payload.data.status === 'CANCELED' || payload.data.status === 'FINISHED') {
				tournamentsAdapter.removeMany(state.allTournaments, tournamentIds);
				registeredTournamentsAdapter.removeMany(state.registeredTournaments, tournamentIds);
				prizedTournamentsAdapter.removeMany(state.prizedTournaments, tournamentIds);
				state.allTournaments.total = state.allTournaments.total
					? state.allTournaments.total - payload.data.tournaments.length
					: 0;
			} else if (payload.data.status === 'READY' || payload.data.status === 'RUNNING') {
				const tournamentsIdsWithNoRegistration = (
					Object.values(state.allTournaments.entities) as ITournamentCompact[]
				)
					.filter(({ registered }) => !registered)
					.filter(({ id }) => tournamentIds.includes(id))
					.map(({ id }) => id);

				const prizedTournamentsIdsWithNoRegistration = (
					Object.values(state.prizedTournaments.entities) as ITournamentCompact[]
				)
					.filter(({ registered }) => !registered)
					.filter(({ id }) => tournamentIds.includes(id))
					.map(({ id }) => id);

				tournamentsAdapter.removeMany(state.allTournaments, tournamentsIdsWithNoRegistration);
				prizedTournamentsAdapter.removeMany(state.prizedTournaments, prizedTournamentsIdsWithNoRegistration);

				state.allTournaments.total = state.allTournaments.total
					? state.allTournaments.total - tournamentsIdsWithNoRegistration.length
					: 0;
			}

			const tournamentsNewStatuses: Update<ITournamentCompact>[] = tournamentIds
				.filter((tournamentId) => state.allTournaments.ids.includes(tournamentId))
				.map((id) => ({ id, changes: { statusId: payload.data.status } }));

			const registeredTournamentsNewStatuses: Update<ITournamentCompact>[] = tournamentIds
				.filter((tournamentId) => state.registeredTournaments.ids.includes(tournamentId))
				.map((id) => ({ id, changes: { statusId: payload.data.status } }));

			const prizedTournamentsNewStatuses: Update<ITournamentCompact>[] = tournamentIds
				.filter((tournamentId) => state.prizedTournaments.ids.includes(tournamentId))
				.map((id) => ({ id, changes: { statusId: payload.data.status } }));

			tournamentsAdapter.updateMany(state.allTournaments, tournamentsNewStatuses);
			registeredTournamentsAdapter.updateMany(state.registeredTournaments, registeredTournamentsNewStatuses);
			prizedTournamentsAdapter.updateMany(state.prizedTournaments, prizedTournamentsNewStatuses);
		},
		emitTournamentsWSFinishSelectedTournament: (
			state,
			action: PayloadAction<Pick<IWSChampionshipStatusChanged['data'], 'championshipIds'>>
		) => {
			if (state.selectedTournament) {
				const shouldUpdateTournamentStatusToFinished = action.payload.championshipIds.some(
					(championshipId) => championshipId === state.selectedTournament?.championshipId
				);
				if (shouldUpdateTournamentStatusToFinished) {
					state.selectedTournament.statusId === 'FINISHED';
				}
			}
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(wsTournamentParticipantRegistered.type, (state, { payload }: PayloadAction | any) => {
			const tournament = state.allTournaments.entities[payload.data.tournamentId];
			const registeredTournament = state.registeredTournaments.entities[payload.data.tournamentId];
			const prizedTournament = state.prizedTournaments.entities[payload.data.tournamentId];

			if (state.selectedTournament !== null) {
				if (state.selectedTournament.id === payload.data.tournamentId) {
					state.selectedTournament = {
						...state.selectedTournament,
						participantsCount: state.selectedTournament.participantsCount + 1,
					};
				}
			}

			if (tournament) {
				tournamentsAdapter.updateOne(state.allTournaments, {
					id: tournament.id,
					changes: { participantsCount: tournament.participantsCount + 1 },
				});
			}

			if (registeredTournament) {
				registeredTournamentsAdapter.updateOne(state.registeredTournaments, {
					id: registeredTournament.id,
					changes: { participantsCount: registeredTournament.participantsCount + 1 },
				});
			}

			if (prizedTournament) {
				prizedTournamentsAdapter.updateOne(state.prizedTournaments, {
					id: prizedTournament.id,
					changes: { participantsCount: prizedTournament.participantsCount + 1 },
				});
			}
		});
		addCase(emitHttpFetchTournamentsPageable.pending, (state) => {
			state.loadingStates.fetchTournaments = true;
		});
		addCase(emitHttpFetchTournamentsPageable.fulfilled, (state, action) => {
			state.loadingStates.fetchTournaments = false;
			state.allTournaments.total = action.payload.data.total;
			tournamentsAdapter.upsertMany(state.allTournaments, action.payload.data.result);
		});
		addCase(emitHttpFetchTournamentsPageable.rejected, (state) => {
			state.loadingStates.fetchTournaments = false;
		});
		addCase(emitHttpFetchTournamentsHome.pending, (state) => {
			state.loadingStates.fetchAllTournaments = true;
		});
		addCase(emitHttpFetchTournamentsHome.fulfilled, (state, action) => {
			state.loadingStates.fetchAllTournaments = false;
			const { allTournaments, tournamentsWithAwards } = action.payload.data;

			tournamentsAdapter.upsertMany(state.allTournaments, allTournaments.result);
			state.allTournaments.total = allTournaments.total;

			prizedTournamentsAdapter.upsertMany(state.prizedTournaments, tournamentsWithAwards.result);
			state.prizedTournaments.total = tournamentsWithAwards.total;
		});
		addCase(emitHttpFetchTournamentsHome.rejected, (state, action) => {
			state.loadingStates.fetchAllTournaments = false;
		});
		addCase(emitHttpFetchTournament.pending, (state) => {
			state.loadingStates.fetchTournament = true;
		});
		addCase(emitHttpFetchTournament.fulfilled, (state, action) => {
			state.loadingStates.fetchTournament = false;
			state.selectedTournament = action.payload.data;
		});
		addCase(emitHttpFetchTournament.rejected, (state) => {
			state.loadingStates.fetchTournament = false;
		});
		addCase(emitHttpRegisterForTournament.pending, (state) => {
			state.loadingStates.registerForTournament = true;
		});
		addCase(emitHttpRegisterForTournament.fulfilled, (state, { payload }) => {
			state.loadingStates.registerForTournament = false;

			if (state.selectedTournament) {
				state.selectedTournament = {
					...state.selectedTournament,
					registered: payload.changes.registered as boolean,
				};
			}

			const compactTournamentChanges: Update<ITournamentCompact> = {
				id: payload.id,
				changes: { registered: true },
			};

			tournamentsAdapter.updateOne(state.allTournaments, compactTournamentChanges);

			let registeredTournament: ITournamentCompact | undefined = undefined;
			let isFound = false;

			if (state.prizedTournaments.ids.some((id) => id === payload.id)) {
				isFound = true;
				registeredTournament = {
					...(state.prizedTournaments.entities[payload.id] as ITournamentCompact),
					...compactTournamentChanges.changes,
				};
			}
			if (state.allTournaments.ids.some((id) => id === payload.id) && !isFound) {
				isFound = true;
				registeredTournament = {
					...(state.allTournaments.entities[payload.id] as ITournamentCompact),
					...compactTournamentChanges.changes,
				};
			}

			if (state.prizedTournaments.ids.includes(payload.id)) {
				prizedTournamentsAdapter.updateOne(state.prizedTournaments, compactTournamentChanges);
			}
		});
		addCase(emitHttpRegisterForTournament.rejected, (state) => {
			state.loadingStates.registerForTournament = false;
		});
		addCase(emitHttpFetchRegisteredTournaments.pending, (state) => {
			state.loadingStates.fetchRegisteredTournaments = true;
		});
		addCase(emitHttpFetchRegisteredTournaments.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			registeredTournamentsAdapter.addMany(state.registeredTournaments, result);
			state.loadingStates.fetchRegisteredTournaments = false;
			state.registeredTournaments.total = total;
		});
		addCase(emitHttpFetchRegisteredTournaments.rejected, (state) => {
			state.loadingStates.fetchRegisteredTournaments = false;
		});
		addCase(emitHttpFetchPrizedTournaments.pending, (state) => {
			state.loadingStates.fetchPrizedTournaments = true;
		});
		addCase(emitHttpFetchPrizedTournaments.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			prizedTournamentsAdapter.addMany(state.prizedTournaments, result);
			state.loadingStates.fetchPrizedTournaments = false;
			state.prizedTournaments.total = total;
		});
		addCase(emitHttpFetchPrizedTournaments.rejected, (state) => {
			state.loadingStates.fetchPrizedTournaments = false;
		});
		addCase(emitHttpFetchMerchantTournaments.pending, (state) => {
			state.loadingStates.fetchMerchantTournaments = true;
		});
		addCase(emitHttpFetchMerchantTournaments.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			tournamentsAdapter.upsertMany(state.allTournaments, result);
			state.loadingStates.fetchMerchantTournaments = false;
			state.allTournaments.total = total;
		});
		addCase(emitHttpFetchMerchantTournaments.rejected, (state) => {
			state.loadingStates.fetchMerchantTournaments = false;
		});
		addCase(emitHttpChampionshipsTournaments.pending, (state) => {
			state.loadingStates.fetchChampionshipTournaments = true;
		});
		addCase(emitHttpChampionshipsTournaments.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			tournamentsAdapter.addMany(state.allTournaments, result);
			state.loadingStates.fetchChampionshipTournaments = false;
			state.allTournaments.total = total;
		});
		addCase(emitHttpChampionshipsTournaments.rejected, (state) => {
			state.loadingStates.fetchChampionshipTournaments = false;
		});
		addCase(emitHttpChampionshipEndedGamys.pending, (state) => {
			state.loadingStates.fetchChampionshipEndedTournaments = true;
		});
		addCase(emitHttpChampionshipEndedGamys.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			championshipEndedTournamentsAdapter.upsertMany(state.championshipEndedTournaments, result);
			state.loadingStates.fetchChampionshipEndedTournaments = false;
			state.championshipEndedTournaments.total = total;
		});
		addCase(emitHttpChampionshipEndedGamys.rejected, (state) => {
			state.loadingStates.fetchChampionshipEndedTournaments = false;
		});
		addCase(emitHttpFetchMerchantFinishedTournaments.pending, (state) => {
			state.loadingStates.fetchFinishedMerchantTournaments = true;
		});
		addCase(emitHttpFetchMerchantFinishedTournaments.fulfilled, (state, action) => {
			const { result, total } = action.payload.data;

			finishedMerchantTournamentsAdapter.upsertMany(state.finishedMerchantTournaments, result);
			state.loadingStates.fetchFinishedMerchantTournaments = false;
			state.finishedMerchantTournaments.total = total;
		});
		addCase(emitHttpFetchMerchantFinishedTournaments.rejected, (state) => {
			state.loadingStates.fetchFinishedMerchantTournaments = false;
		});
	},
});
export const {
	emitTournamentsRemoveAll,
	emitTournamentsWSsubscribe,
	emitTournamentsWSnewTournaments,
	emitTournamentsWSstatusChanged,
	emitTournamentsRemoveSelected,
	emitTournamentsWSFinishSelectedTournament,
	emitTournamentRanking,
} = tournamentsSlice.actions;
export const tournamentsReducer = tournamentsSlice.reducer;
