import InputCheckbox from '@features/common/components/InputCheckbox';
import InputMessage from '@features/common/components/InputMessage';
import InputText from '@features/common/components/InputText';
import PrimaryButton from '@features/common/components/PrimaryButton';
import { showToast } from '@features/common/store/common.reducer';
import { getFieldValidations } from '@features/common/store/common.selectors';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { getSelectedLanguage } from '@features/layout/store/layout.selectors';
import useFacebookPixel from '@hooks/useFacebookPixel';
import useGoogleAnalytics from '@hooks/useGoogleAnalytics';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IRegisterFormRequest } from '../models/register-form-request.model';
import { IRegisterForm } from '../models/register-form.model';
import { generateNickname, registerUser } from '../store/auth.actions';
import { clearRandomNickname, updateSwitchToPage, updateVerificationStatus } from '../store/auth.reducer';
import { getAuthIsLoading, getAuthRandomNickname, getSwitchToPage } from '../store/auth.selectors';
import AuthFormWrapper from './AuthFormWrapper';

const RegisterWithEmail = () => {
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();
	const { sendFacebookPixelEvent } = useFacebookPixel();
	const { register, handleSubmit, formState, getValues, setValue, setError } = useForm<IRegisterForm>({
		defaultValues: {
			notificationNewGamysWithRewards: true,
			newsMarketingNotifications: true,
			notificationNewChampionshipsWithRewards: true,
		},
		mode: 'onBlur',
	});
	const isRegisterLoading = useSelector(getAuthIsLoading('registerUser'));
	const isRandomNicknameLoading = useSelector(getAuthIsLoading('generateNickname'));
	const { email, password } = useSelector(getFieldValidations);
	const randomNickname = useSelector(getAuthRandomNickname);
	const navigateToPage = useSelector(getSwitchToPage);
	const selectedLanguage = useSelector(getSelectedLanguage);

	useEffect(() => {
		dispatch(generateNickname());
	}, [dispatch]);

	useEffect(() => {
		if (randomNickname) {
			setValue('nickname', randomNickname);
		}
	}, [randomNickname, setValue]);

	useEffect(() => {
		setValue('language', { id: selectedLanguage });
	}, [selectedLanguage, setValue]);

	useEffect(() => {
		setValue('terms', true);
	}, [setValue]);

	useEffect(() => {
		return () => {
			dispatch(clearRandomNickname());
		};
	}, [dispatch]);

	const validatePasswordEquality = (confirmPassword: string) => {
		const { password } = getValues();

		return confirmPassword === password || formatMessage({ id: 'bcs-Auth-PasswordShouldMatch' });
	};

	const onSubmit = ({ confirmPassword, ...formForSubmit }: IRegisterFormRequest) => {
		dispatch(registerUser(formForSubmit))
			.then(unwrapResult)
			.then(() => {
				dispatch(
					showToast({
						type: 'success',
						message: formatMessage({
							id: 'bcs-Auth-AccountCreated',
						}),
						buttonText: formatMessage({
							id: 'bcs-Common-GotIt',
						}),
						title: formatMessage({ id: 'bcs-Common-Nice' }),
					})
				);

				sendGoogleAnalyticsEvent({
					eventName: 'register_email',
					params: { eventLabel: 'Register with email', eventCategory: 'register_email' },
				});

				sendFacebookPixelEvent({
					eventName: 'register_email',
					params: { eventLabel: 'Register with email', eventCategory: 'register_email' },
				});
				if (navigateToPage !== null) {
					dispatch(updateVerificationStatus(null));
					dispatch(updateSwitchToPage(null));
				}
			})
			.catch((error) => {
				const { fieldErrors, message } = parseError<IRegisterFormRequest>(error);

				fieldErrors.forEach(({ code, field }) => {
					if (field === 'nickname' && code === 'DUPLICATED') {
						setError(field, {
							message: formatMessage({
								id: 'bcs-Error-TakenNicknameInput',
							}),
						});
					} else {
						setError(field, {
							message: formatMessage({
								id: 'bcs-Error-TakenEmailInput',
							}),
						});
					}
				});

				if (!fieldErrors.length) {
					dispatch(
						showToast({
							message,
							type: 'warning',
							title: formatMessage({ id: 'bcs-Common-Oops' }),
						})
					);
				}
			});
	};

	if (isRegisterLoading || isRandomNicknameLoading || !randomNickname) {
		return (
			<LoadingIndicator
				className={classNames('flex flex-col justify-center items-center w-full h-full', {
					'bg-white md:bg-black': !navigateToPage,
				})}
				contained
			/>
		);
	}

	return (
		<AuthFormWrapper
			pathForBack='/register'
			title={formatMessage({ id: 'bcs-Auth-CreateAccount' })}
			description={`${formatMessage({ id: 'bcs-Auth-AlreadyAUser' })}?`}
			link={{
				href: '/login',
				name: formatMessage({ id: 'bcs-Auth-LoginNow' }),
				testId: 'registerEmailLoginLink',
			}}
		>
			<form
				className={classNames('flex flex-col', {
					'pr-2 space-y-7': navigateToPage !== null,
					'space-y-5': navigateToPage === null,
				})}
				onSubmit={handleSubmit(onSubmit)}
				data-testid='form'
				id='register-email-form'
			>
				<InputText
					id={'email'}
					label={formatMessage({ id: 'bcs-Auth-Email' })}
					type={'email'}
					testId={'email'}
					tabIndex={1}
					fieldError={formState.errors.email}
					placeholder={formatMessage({ id: 'bcs-Auth-Email' })}
					hookRegister={register('email', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-Error-EmptyEmail',
							}),
						},
						pattern: {
							value: RegExp(email),
							message: formatMessage({
								id: 'bcs-Error-InvalidEmail',
							}),
						},
						minLength: {
							value: 6,
							message: formatMessage({ id: 'bcs-Error-InvalidLength' }),
						},
						maxLength: {
							value: 320,
							message: formatMessage({ id: 'bcs-Error-InvalidLength' }),
						},
						validate: (value) => {
							return (
								value.indexOf('@mail.bg') === -1 ||
								formatMessage({ id: 'bcs-Error-InvalidMailProvider' })
							);
						},
					})}
				/>
				<InputText
					id={'nickname'}
					label={formatMessage({ id: 'bcs-Auth-Nickname' })}
					type={'text'}
					testId={'nickname'}
					tabIndex={2}
					fieldError={formState.errors.nickname}
					placeholder={formatMessage({ id: 'bcs-Auth-Nickname' })}
					hookRegister={register('nickname', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-Error-NicknameLength',
							}),
						},
						minLength: {
							value: 3,
							message: formatMessage({
								id: 'bcs-Error-NicknameLength',
							}),
						},
						maxLength: {
							value: 16,
							message: formatMessage({
								id: 'bcs-Error-NicknameLength',
							}),
						},
					})}
					hint={formatMessage({ id: 'bcs-Auth-NicknameHint' })}
				/>
				<InputText
					id={'password'}
					label={formatMessage({ id: 'bcs-Auth-Password' })}
					type={'password'}
					testId={'password'}
					tabIndex={3}
					fieldError={formState.errors.password}
					placeholder={formatMessage({ id: 'bcs-Auth-Password' })}
					hookRegister={register('password', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-Error-EmptyPassword',
							}),
						},
						pattern: {
							value: RegExp(password),
							message: formatMessage(
								{ id: 'bcs-Error-Invalid' },
								{
									field: formatMessage({
										id: 'bcs-Auth-Password',
									}),
								}
							),
						},
					})}
					hint={formatMessage({ id: 'bcs-Auth-PasswordHint' })}
				/>
				<InputText
					id={'confirmPassword'}
					label={formatMessage({ id: 'bcs-Auth-RepeatPassword' })}
					type={'password'}
					testId={'confirmPassword'}
					tabIndex={4}
					fieldError={formState.errors.confirmPassword}
					placeholder={formatMessage({
						id: 'bcs-Auth-RepeatPassword',
					})}
					hookRegister={register('confirmPassword', {
						required: {
							value: true,
							message: formatMessage({
								id: 'bcs-ErrorConfirmPassword',
							}),
						},
						pattern: {
							value: RegExp(password),
							message: formatMessage(
								{ id: 'bcs-Error-Invalid' },
								{
									field: formatMessage({
										id: 'bcs-Auth-RepeatPassword',
									}),
								}
							),
						},
						validate: validatePasswordEquality,
					})}
				/>

				<InputCheckbox
					id={'notificationNewGamysWithRewards'}
					tabIndex={4}
					testId={'notificationNewGamysWithRewards'}
					label={formatMessage({
						id: 'bcs-Auth-Notification-NewGamysWithRewards',
					})}
					hookRegister={register('notificationNewGamysWithRewards')}
					fieldError={undefined}
				/>
				<InputCheckbox
					testId={'notificationNewChampionshipsWithRewards'}
					id={'notificationNewChampionshipsWithRewards'}
					tabIndex={5}
					hookRegister={register('notificationNewChampionshipsWithRewards')}
					fieldError={undefined}
					label={formatMessage({
						id: 'bcs-Auth-Notification-NewsChampionships',
					})}
				/>
				<InputCheckbox
					testId={'notificationNewsAboutGamys'}
					id={'notificationNewsAboutGamys'}
					tabIndex={6}
					hookRegister={register('newsMarketingNotifications')}
					fieldError={undefined}
					label={formatMessage({
						id: 'bcs-Auth-Notification-NewsAboutGamys',
					})}
				/>

				<PrimaryButton
					type='submit'
					text={formatMessage({ id: 'bcs-Auth-Continue' })}
					tabIndex={6}
					testId='registrationSubmitBtn'
					disabled={isRegisterLoading}
				/>
				<InputMessage fieldError={formState.errors.terms} inputTestId={'terms'} />
			</form>
		</AuthFormWrapper>
	);
};

export default RegisterWithEmail;
