import React, { useState } from 'react';
import environmentBasedConfig from 'utils/config';
import Script from 'next/script';

interface IGoogleTagManagerScriptProps {
	host: string;
}
const GoogleTagManagerScript = ({ host }: IGoogleTagManagerScriptProps) => {
	const [googleTagManagerKey] = useState(environmentBasedConfig('GOOGLE_TAG_MANAGER_KEY', host) as string);

	return (
		<Script
		id='google-tag-manager'
		dangerouslySetInnerHTML={{
			__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	  })(window,document,'script','dataLayer','${googleTagManagerKey}');`,
		}}
	/>
	);
};

export default GoogleTagManagerScript;
