import Login from '@features/auth/components/Login';
import LoginWithEmail from '@features/auth/components/LoginWithEmail';
import Register from '@features/auth/components/Register';
import RegisterWithEmail from '@features/auth/components/RegisterWithEmail';
import { getSwitchToPage } from '@features/auth/store/auth.selectors';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const NavigationThroughModal = () => {
	const navigateToPage = useSelector(getSwitchToPage);

	switch (navigateToPage) {
		case 'Log In':
			return <Login />;

		case 'Continue With Email':
			return <RegisterWithEmail />;

		case 'Create Account':
			return <Register />;

		case 'Login With Email':
			return <LoginWithEmail />;

		default:
			return null;
	}
};

const RegistrationNavigationModal = () => {
	const navigateToPage = useSelector(getSwitchToPage);

	useEffect(() => {
		if (!navigateToPage) {
			if (document.body.classList.contains('overflow-hidden')) {
				document.body.classList.toggle('overflow-hidden');
			}
			return;
		}

		if(!document.body.classList.contains('overflow-hidden')) {
			document.body.classList.toggle('overflow-hidden');
		}

	}, [navigateToPage]);

	if (!navigateToPage) {
		return null;
	}

	return (
		<div
			className='fixed inset-0 flex h-full w-full items-center justify-center m-0 z-50'
			style={{ backgroundColor: 'rgba(0,0,0, .4)' }}
		>
			<NavigationThroughModal />
		</div>
	);
};

export default RegistrationNavigationModal;
