import React from 'react';

interface ILoadingIndicatorProps {
	contained?: boolean;
	size?: number;
	className?: string;
	title?: string;
}

const LoadingIndicator = ({ contained, size, className, title }: ILoadingIndicatorProps) => {
	const getLoadingClasses = () => {
		const classes = [];

		if (!contained) {
			classes.push('flex flex-col justify-evenly items-center w-full h-full absolute z-30 inset-0');
		}

		if (className) {
			classes.push(className);
		}

		return classes.join(' ');
	};

	const getLoadingSize = () => {
		if (size) {
			return `${size}px`;
		}
		return '78px';
	};

	return (
		<div className={getLoadingClasses()} data-testid={'loading'}>
			{title ? (
				<div className='relative w-full'>
					<div className='absolute h-screen flex justify-center items-center w-full'>
						<p className='mt-40 text-center text-white text-3xl xl-text-5xl 2xl:text-6xl'>{title}</p>
					</div>
				</div>
			) : null}
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width={getLoadingSize()}
				height={getLoadingSize()}
				viewBox='0 0 100 100'
				preserveAspectRatio='xMidYMid'
				style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }}
			>
				<clipPath id='cp'>
					<path d='M0 -46.5 A46.5 46.5 0 0 1 0 46.5 A46.5 46.5 0 0 1 0 -46.5 M17.5 -1L17.5 1L28.5 1L28.5 -1Z'></path>
				</clipPath>
				<g transform='translate(50,50)'>
					<circle
						clipPath='url(#cp)'
						cx='0'
						cy='0'
						fill='none'
						r='22'
						stroke='#ff652e'
						strokeWidth='9'
						strokeDasharray='34.55751918948772 0 0 0 0 138.23007675795088'
					>
						<animate
							attributeName='stroke-dasharray'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='-0.1282051282051282s'
							keyTimes='0;0.2;0.4;0.6;0.8;1'
							values='
0 0 0 0 0 138.23007675795088;
0 0 0 0 0 138.23007675795088;
0 0 69.11503837897544 0 0 138.23007675795088;
0 0 138.23007675795088 0 0 138.23007675795088;
0 0 69.11503837897544 0 0 138.23007675795088;
0 0 0 0 0 138.23007675795088
'
						></animate>
						<animateTransform
							attributeName='transform'
							type='rotate'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='-0.1282051282051282s'
							values='0;0;0;0;180;360'
						></animateTransform>
					</circle>

					<circle
						cx='0'
						cy='0'
						fill='none'
						r='32'
						stroke='#ffbf01'
						strokeWidth='9'
						strokeDasharray='100.53096491487338 0 0 201.06192982974676'
					>
						<animate
							attributeName='stroke-dasharray'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='0s'
							values='
0 0 0 0 0 201.06192982974676;
0 0 100.53096491487338 0 0 201.06192982974676;
0 0 100.53096491487338 0 0 201.06192982974676;
0 0 100.53096491487338 0 0 201.06192982974676;
0 0 100.53096491487338 0 0 201.06192982974676;
0 0 0 0 0 201.06192982974676
'
						></animate>
						<animateTransform
							attributeName='transform'
							type='rotate'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='0s'
							values='0;0;0;180;180;360'
						></animateTransform>
					</circle>

					<circle
						cx='0'
						cy='0'
						fill='none'
						r='42'
						stroke='#00e090'
						strokeWidth='9'
						strokeDasharray='131.94689145077132 0 0 263.89378290154264'
						transform='rotate(45)'
					>
						<animate
							attributeName='stroke-dasharray'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='0s'
							keyTimes='0;0.06;0.1;0.3;0.45;0.5;0.7;0.90;1'
							values='
0 0 98.96016858807849 0 0 263.89378290154264;
0 0 98.96016858807849 0 0 263.89378290154264;
0 0 131.94689145077132 0 0 263.89378290154264;
0 0 131.94689145077132 0 0 263.89378290154264;
0 0 32.98672286269283 0 0 263.89378290154264;
0 0 32.98672286269283 0 0 263.89378290154264;
0 0 131.94689145077132 0 0 263.89378290154264;
0 0 131.94689145077132 0 0 263.89378290154264;
0 0 98.96016858807849 0 0 263.89378290154264
'
						></animate>
						<animateTransform
							attributeName='transform'
							type='rotate'
							dur='1.282051282051282s'
							repeatCount='indefinite'
							begin='0s'
							keyTimes='0;0.06;0.1;0.3;0.5;0.6;0.8;0.90;1'
							values='-60;0;0;0;180;180;180;180;300'
						></animateTransform>
					</circle>
				</g>
			</svg>
		</div>
	);
};

export default LoadingIndicator;
