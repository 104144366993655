import React from 'react';

const IconWarningSign = () => {
	return (
		<svg width='118' height='104' viewBox='0 0 118 104' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M116.359 92.3907L65.3456 4.07828C62.5257 -0.792368 55.4761 -0.792368 52.6563 4.07828L1.6427 92.3907C-1.17715 97.2614 2.28358 103.414 7.92327 103.414H109.95C115.718 103.414 119.179 97.2614 116.359 92.3907ZM57.3987 31.1232C60.4749 30.3541 63.5511 31.8922 64.9611 34.7121C65.4738 35.7375 65.6019 36.891 65.4738 37.9164C65.2174 41.1208 65.0892 44.3252 64.9611 47.6577C64.7047 52.6565 64.3202 57.7835 64.0638 62.7823C63.9357 64.3204 63.9357 65.8586 63.8075 67.5248C63.6793 70.2165 61.6285 72.2673 58.9368 72.2673C56.3733 72.2673 54.1944 70.2165 54.0662 67.653C53.6817 59.8343 53.169 52.0157 52.7845 44.197C52.6563 42.1462 52.5281 39.9672 52.3999 37.9164C52.3999 34.8402 54.4507 31.8922 57.3987 31.1232ZM59.065 89.8272C55.4761 89.8272 52.6563 86.8792 52.6563 83.2903C52.6563 79.7014 55.6043 76.7534 59.1932 76.7534C62.7821 76.7534 65.6019 79.7014 65.6019 83.4185C65.4738 86.8792 62.5257 89.8272 59.065 89.8272Z'
				fill='#FFBF01'
			/>
		</svg>
	);
};

export default IconWarningSign;
