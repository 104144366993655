import React from 'react';

interface IIconEye {
	fillHexColor?: string;
}

const IconEye = ({ fillHexColor }: IIconEye) => {
	return (
		<svg width='25' height='16' viewBox='0 0 25 16' fill='blue' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M24.638 7.30724C23.3935 5.19462 21.6191 3.4434 19.4903 2.22674C17.3615 1.01008 14.952 0.370117 12.5001 0.370117C10.0481 0.370117 7.63865 1.01008 5.50986 2.22674C3.38106 3.4434 1.60668 5.19462 0.362151 7.30724C0.288713 7.43254 0.25 7.57515 0.25 7.72039C0.25 7.86563 0.288713 8.00824 0.362151 8.13354C1.60668 10.2462 3.38106 11.9974 5.50986 13.214C7.63865 14.4307 10.0481 15.0707 12.5001 15.0707C14.952 15.0707 17.3615 14.4307 19.4903 13.214C21.6191 11.9974 23.3935 10.2462 24.638 8.13354C24.7115 8.00824 24.7502 7.86563 24.7502 7.72039C24.7502 7.57515 24.7115 7.43254 24.638 7.30724ZM12.5001 13.437C10.4155 13.435 8.36457 12.9107 6.53469 11.9121C4.70481 10.9135 3.15426 9.47242 2.02464 7.72039C3.15275 5.96693 4.70298 4.5247 6.5332 3.52592C8.36342 2.52714 10.4151 2.00376 12.5001 2.00376C14.5851 2.00376 16.6368 2.52714 18.467 3.52592C20.2972 4.5247 21.8474 5.96693 22.9755 7.72039C21.8459 9.47242 20.2954 10.9135 18.4655 11.9121C16.6356 12.9107 14.5847 13.435 12.5001 13.437ZM12.5001 3.63706C11.6925 3.63706 10.903 3.87655 10.2315 4.32523C9.56001 4.77391 9.03664 5.41164 8.72758 6.15777C8.41852 6.9039 8.33766 7.72492 8.49522 8.51701C8.65277 9.3091 9.04167 10.0367 9.61274 10.6077C10.1838 11.1788 10.9114 11.5677 11.7035 11.7253C12.4956 11.8828 13.3166 11.802 14.0627 11.4929C14.8088 11.1838 15.4466 10.6605 15.8952 9.98897C16.3439 9.31747 16.5834 8.528 16.5834 7.72039C16.5821 6.63782 16.1515 5.59995 15.386 4.83445C14.6205 4.06895 13.5827 3.63834 12.5001 3.63706ZM12.5001 10.1704C12.0155 10.1704 11.5418 10.0267 11.1389 9.75749C10.736 9.48828 10.422 9.10564 10.2366 8.65796C10.0511 8.21029 10.0026 7.71767 10.0972 7.24242C10.1917 6.76717 10.425 6.33062 10.7677 5.98798C11.1103 5.64534 11.5469 5.412 12.0221 5.31747C12.4974 5.22294 12.99 5.27145 13.4377 5.45689C13.8853 5.64232 14.268 5.95635 14.5372 6.35925C14.8064 6.76215 14.9501 7.23583 14.9501 7.72039C14.9494 8.36995 14.691 8.9927 14.2317 9.45201C13.7724 9.91132 13.1496 10.1697 12.5001 10.1704Z'
				fill={fillHexColor || '#808191'}
			/>
		</svg>
	);
};

export default IconEye;
