type TypeFormatDate = Date | number | string;
const useFormatDateTime = () => {
	const formatTime = (date: TypeFormatDate, options?: Intl.DateTimeFormatOptions, locale?: string) => {
		const defaultOptions: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
		};

		return new Date(date).toLocaleTimeString(locale, options || defaultOptions);
	};

	const formatDate = (date: TypeFormatDate, options?: Intl.DateTimeFormatOptions, locale?: string) => {
		const defaultOptions: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
		};

		return new Date(date).toLocaleDateString(locale, options || defaultOptions);
	};

	const formatDateAndTime = (date: TypeFormatDate, options?: Intl.DateTimeFormatOptions, locale?: string) => {
		const defaultOptions: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		};

		return date === null ? null : new Date(date).toLocaleDateString(locale, options || defaultOptions);
	};

	const formatDateAndYear = (date: TypeFormatDate, options?: Intl.DateTimeFormatOptions, locale?: string) => {
		const defaultOptions: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};

		return new Date(date).toLocaleDateString(locale, options || defaultOptions);
	};

	return {
		formatTime,
		formatDate,
		formatDateAndTime,
		formatDateAndYear,
	};
};

export default useFormatDateTime;
