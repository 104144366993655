import { emitNotificationsIncrementUnreadCount } from '@features/notifications/store/notifications.reducer';
import { removeOnsiteNotification } from '@features/onsiteNotifications/store/onsiteNotifications.reducer';
import { getAllOnsiteNotifications } from '@features/onsiteNotifications/store/onsiteNotifications.selectors';
import { useAppDispatch } from '@store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Subscription, fromEvent, interval } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import OnsiteNotification from './OnsiteNotification';

const OnsiteNotificationsContainer = () => {
	const onsiteNotifications = useSelector(getAllOnsiteNotifications);
	const dispatch = useAppDispatch();
	const isChangingTitle = useRef(false);
	const intl = useIntl();
	const router = useRouter();

	useEffect(() => {
		if (router.pathname.includes('play')) {
			return;
		}
		const wsNotification = onsiteNotifications.some((notification) => notification.type === 'WS');

		if (!wsNotification) {
			return;
		}
		let subscription: Subscription;

		if (!isChangingTitle.current) {
			const oldTitle = document.title;
			isChangingTitle.current = true;
			subscription = interval(1000)
				.pipe(
					tap(() => {
						if (document.title === oldTitle) {
							document.title = intl.formatMessage({ id: 'bcs-Notification-NewNotification' });
						} else {
							document.title = oldTitle;
						}
					}),
					takeUntil(fromEvent(document, 'mousemove')),
					finalize(() => {
						isChangingTitle.current = false;
						document.title = oldTitle;
					})
				)
				.subscribe();
		}

		return () => {
			subscription.unsubscribe();
		};
	}, [intl, onsiteNotifications, router.pathname]);

	useEffect(() => {
		if (router.pathname.includes('play') && onsiteNotifications.length) {
			dispatch(removeOnsiteNotification({ id: onsiteNotifications[0].id }));
			dispatch(emitNotificationsIncrementUnreadCount());
		}
	}, [dispatch, onsiteNotifications, onsiteNotifications.length, router.pathname]);

	const clearOnsiteNotification = (onsiteNotificationId: string) => {
		dispatch(removeOnsiteNotification({ id: onsiteNotificationId }));
	};

	if (router.pathname.includes('play')) {
		return null;
	}

	return (
		<AnimatePresence mode='wait'>
			{onsiteNotifications.length ? (
				<div className='w-full md:w-auto fixed flex flex-col xs:items-stretch md:items-end space-y-3 top-18 md:right-12 md:left-auto left-0 z-50 md:px-4'>
					{onsiteNotifications.map((notification) => (
						<motion.div
							initial={{ opacity: 0, dur: 300 }}
							animate={{ opacity: 1, dur: 300 }}
							exit={{ opacity: 0.3, dur: 300 }}
							key={notification.id}
						>
							<OnsiteNotification
								clearOnsiteNotification={clearOnsiteNotification}
								onsiteNotification={notification}
								key={notification.id}
							/>
						</motion.div>
					))}
				</div>
			) : null}
		</AnimatePresence>
	);
};

export default OnsiteNotificationsContainer;
