import { ICommonState } from '@features/common/models/common-state.model';
import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import packageJson from '../../../package.json';

const isProd = process.env.NODE_ENV === 'production';

const commonSelector = ({ common }: IApplicationState): ICommonState => common;

export const getCommonIsLoading = createSelector(commonSelector, ({ isLoading }) => isLoading);
export const getFieldValidations = createSelector(commonSelector, ({ fieldValidations }) => fieldValidations);
export const getCommonToast = createSelector(commonSelector, ({ toast }) => toast);
export const getShowScrollbar = createSelector(commonSelector, ({ enableScroll }) => enableScroll);
export const getCdnUrl = createSelector(commonSelector, ({ cdnUrl }) => cdnUrl);
export const getDefaultLanguage = createSelector(commonSelector, ({ defaultLanguage }) => defaultLanguage);
export const getCdnStaticUrl = createSelector(commonSelector, ({ cdnUrl }) => {
	if (!isProd) {
		return '/img';
	}
	return `${cdnUrl}static/${packageJson.version}`;
});
export const getSupportedLanguages = createSelector(commonSelector, ({ supportedLanguages }) => supportedLanguages);
export const getIsSocialLoginNotificationsModal = createSelector(
	commonSelector,
	({ showSocialLoginNotificationsModal }) => showSocialLoginNotificationsModal
);
export const getIsRecaptchaLoaded = createSelector(commonSelector, ({ isRecaptchaLoaded }) => isRecaptchaLoaded);
export const getCommonIsRecaptchaActivated = createSelector(
	commonSelector,
	({ isRecaptchaActivated }) => isRecaptchaActivated
);

export const getCommonScriptKey = (
	scriptKey: keyof Pick<ICommonState, 'facebookPixelKey' | 'hotJarKey' | 'recaptchaKey'>
) => createSelector(commonSelector, (state) => state[scriptKey]);

export const getIsPlayNowModal = createSelector(commonSelector, ({ playNowModal }) => playNowModal);
