import React from 'react';

const IconDirectionLeft = () => {
	return (
		<div
			className='w-12 h-12 grid place-items-center rounded-full'
			style={{ boxShadow: 'rgb(0 0 0 / 3%) 0px 10px 10px 0px' }}
		>
			<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M11.3896 16L7.38965 12L11.3896 8'
					stroke='#11142D'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M7.38965 12H17.7188'
					stroke='#11142D'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</div>
	);
};

export default IconDirectionLeft;
