import { ILanguage } from '@features/common/models/language.model';
import { httpHandler } from '@http/httpHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICurrentUser } from '../models/current-user.model';
import { ChangeCustomerLanguageResource } from '../models/language-resource.model';
import { ILoginForm } from '../models/login-form.model';
import { INotificationSetting } from '../models/notification-settings.model';
import { IPageableAvatarResource } from '../models/pageable-avatar-resource.model';
import { IProfileForm } from '../models/profile-form.model';
import { IRegisterFormRequest } from '../models/register-form-request.model';
import { IResetPassword } from '../models/reset-password.model';
import { SpecialNotification } from '../models/special-notification.model';
import { IUpdatePassword } from '../models/update-password.model';
import { AuthActionTypes } from './auth.action-types';
import { resetState } from '@store/root.actions';

export const loginUser = createAsyncThunk(AuthActionTypes.LOGIN, (credentials: ILoginForm) => {
	const formData = new FormData();
	formData.append('username', credentials.email);
	formData.append('password', credentials.password);

	return httpHandler<string>(axios.post('/login', formData));
});

export const registerUser = createAsyncThunk(AuthActionTypes.REGISTER, (registerBody: IRegisterFormRequest) => {
	return httpHandler(axios.post('customers/register', registerBody));
});

export const updateProfile = createAsyncThunk(AuthActionTypes.UPDATE_PROFILE, (updateProfileBody: IProfileForm) => {
	return httpHandler<Pick<ICurrentUser, 'id' | 'avatarId' | 'email' | 'nickname'>>(
		axios.put('customers', updateProfileBody)
	);
});

export const fetchCurrentUser = createAsyncThunk(AuthActionTypes.FETCH_CURRENT_USER, () => {
	return httpHandler<ICurrentUser>(axios.get('/customers/current-customer'));
});

export const fetchAllAvatars = createAsyncThunk(AuthActionTypes.FETCH_ALL_AVATARS, () => {
	return httpHandler<IPageableAvatarResource>(axios.get('/avatars'));
});

export const forgotPassword = createAsyncThunk(
	AuthActionTypes.FORGOT_PASSWORD,
	(forgotPasswordBody: Pick<ILoginForm, 'email'>) => {
		return httpHandler<string>(axios.post('/customers/forgot-password', forgotPasswordBody));
	}
);

export const addPassword = createAsyncThunk(
	AuthActionTypes.ADD_PASSWORD,
	(addPasswordBody: Pick<ILoginForm, 'email'>) => {
		return httpHandler<string>(axios.post('/customers/add-password', addPasswordBody));
	}
);

export const updatePassword = createAsyncThunk(AuthActionTypes.UPDATE_PASSWORD, (passwordParams: IUpdatePassword) => {
	return httpHandler<ICurrentUser>(axios.post('/customers/change-password', passwordParams));
});

export const resetPassword = createAsyncThunk(
	AuthActionTypes.RESET_PASSWORD,
	(resetPasswordBody: Omit<IResetPassword, 'confirmNewPassword'>) => {
		return httpHandler<string>(axios.post('/customers/reset-password', resetPasswordBody));
	}
);

export const setPassword = createAsyncThunk(
	AuthActionTypes.SET_PASSWORD,
	(setPasswordBody: Omit<IResetPassword, 'confirmNewPassword'>) => {
		return httpHandler<string>(axios.post('/customers/set-password', setPasswordBody));
	}
);

export const sendVerificationEmail = createAsyncThunk(
	AuthActionTypes.SEND_VERIFICATION_EMAIL,
	(email: Pick<ILoginForm, 'email'>) => {
		return httpHandler(axios.post('customers/verification-email', email));
	}
);

export const verifyRegistration = createAsyncThunk(AuthActionTypes.VERIFY_REGISTRATION, (token: string) => {
	return httpHandler(axios.post('/customers/verify-registration', undefined, { params: { token } }));
});

export const generateNickname = createAsyncThunk(AuthActionTypes.GENERATE_NICKNAME, () => {
	return httpHandler<string>(axios.get('/customers/generate-nickname'));
});

export const fetchNotificationSettings = createAsyncThunk(AuthActionTypes.FETCH_NOTIFICATION_SETTINGS, () => {
	return httpHandler<INotificationSetting[]>(axios.get('/notification-settings'));
});

export const putNotificationSettings = createAsyncThunk(
	AuthActionTypes.PUT_NOTIFICATION_SETTINGS,
	(newSettings: INotificationSetting[]) => {
		return httpHandler<INotificationSetting[]>(axios.put('/notification-settings', newSettings));
	}
);

export const changeSpecialNotifications = createAsyncThunk(
	AuthActionTypes.SET_SPECIAL_SETTINGS,
	(newSettings: Pick<SpecialNotification, 'notificationNewChampionshipsWithRewards'> | SpecialNotification) => {
		return httpHandler<SpecialNotification>(axios.patch('/customers/special-notifications', newSettings));
	}
);

export const changeUserLanguage = createAsyncThunk(
	AuthActionTypes.CHANGE_LANGUAGE,
	(languageId: ChangeCustomerLanguageResource) => {
		return httpHandler<ILanguage>(axios.patch('/customers/change-language', languageId));
	}
);

export const logoutUser = createAsyncThunk(AuthActionTypes.LOGOUT, async (_, { dispatch }) => {
	try {
		const response = await httpHandler<string>(axios.post('/logout'));
		dispatch(resetState());
		return response;
	} catch (error) {}
});
