import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { INotificationsState } from '../models/notifications-state.model';
import { notificationsAdapter } from './notifications.reducer';

const notificationsSelector = ({ notifications }: IApplicationState): INotificationsState => notifications;
const notificationsEntitySelectors = notificationsAdapter.getSelectors();

export const getUnreadNotificationCount = createSelector(notificationsSelector, ({ unreadCount }) => unreadCount);
export const getNotificationsIsLoading = createSelector(notificationsSelector, ({ isLoading }) => isLoading);
export const getTotalNotifications = createSelector(notificationsSelector, ({ totalCount }) => totalCount);
export const getAllNotifications = (
	() => (state: IApplicationState) =>
		notificationsEntitySelectors.selectAll(notificationsSelector(state))
)();
