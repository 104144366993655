import { IVerificationStatusesProps } from '@features/auth/models/auth-verification-model';
import classNames from 'classnames';
import { ReactNode } from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

const InformationVerificationLabel = (props: IVerificationStatusesProps) => {
	const { formatMessage } = useIntl();
	const { status } = props;

	const expiredMessage = formatMessage(
		{ id: 'bcs-Auth-VerificationExpiredMessage' },
		{
			br: <br />,
			verificationUrl: (
				<Link href={'/verification-email'}>
					<a className='underline cursor-pointer'>{formatMessage({ id: 'bcs-Auth-VerificationUrl' })}</a>
				</Link>
			),
		}
	);

	const getCorrectMessageByStatus = (): ReactNode => {
		if (status === 'Success') {
			return formatMessage({ id: 'bcs-Auth-EmailVerified' });
		}

		if (status === 'Unknown User') {
			return formatMessage({ id: 'bcs-Auth-Registration-Unknown' });
		}

		return expiredMessage;
	};

	if (status === null) {
		return null;
	}

	return (
		<div
			className={classNames('w-full h-full rounded-[5px] border-l-[3px] mb-[29px] flex', {
				'border-l-teal-600 bg-teal-700 mt-3': status === 'Success',
				'border-l-razzmatazz-500 bg-razzmatazz-600 mt-3': status === 'Failed',
				'border-l-teal-200 bg-teal-300 mt-[30px]': status === 'Unknown User',
			})}
			data-testid='informationVerificationLabel'
		>
			<div
				className={classNames('text-sm px-[15px] py-3 items-center block', {
					'text-teal-800': status === 'Success',
					'text-razzmatazz-500': status === 'Failed',
					'text-teal-200': status === 'Unknown User',
				})}
				style={{ fontFamily: 'Inter' }}
			>
				{getCorrectMessageByStatus()}
			</div>
		</div>
	);
};

export default InformationVerificationLabel;
