import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { ITournamentLoadingStates } from '../models/tournament-loading-states.model';
import {
	championshipEndedTournamentsAdapter,
	prizedTournamentsAdapter,
	registeredTournamentsAdapter,
	tournamentsAdapter,
	finishedMerchantTournamentsAdapter,
} from './tournaments.reducer';

const tournamentsSelector = ({ tournaments }: IApplicationState) => tournaments;
const allTournamentsSelector = ({ tournaments }: IApplicationState) => tournaments.allTournaments;
const registeredTournamentsSelector = ({ tournaments }: IApplicationState) => tournaments.registeredTournaments;
const prizedTournamentsSelector = ({ tournaments }: IApplicationState) => tournaments.prizedTournaments;
const endedTournamentsSelector = ({ tournaments }: IApplicationState) => tournaments.championshipEndedTournaments;
const finishedMerchantTournamentsSelector = ({ tournaments }: IApplicationState) =>
	tournaments.finishedMerchantTournaments;

const tournamentsEntitySelectors = tournamentsAdapter.getSelectors();
const registeredTournamentsEntitySelectors = registeredTournamentsAdapter.getSelectors();
const prizedTournamentsEntitySelectors = prizedTournamentsAdapter.getSelectors();
const championshipEndedTournamentsEntitySelectors = championshipEndedTournamentsAdapter.getSelectors();
const finishedMerchantTournamentsEntitySelectors = finishedMerchantTournamentsAdapter.getSelectors();

export const getTotalCountTournaments = createSelector(allTournamentsSelector, ({ total }) => total);
export const getTournamentsRegisteredCount = createSelector(registeredTournamentsSelector, ({ total }) => total);
export const getTournamentsPrizedCount = createSelector(prizedTournamentsSelector, ({ total }) => total);
export const getAllTournaments = createSelector(allTournamentsSelector, tournamentsEntitySelectors.selectAll);
export const getEndedTournaments = createSelector(endedTournamentsSelector, ({ total }) => total);
export const getTotalCountFinishedMerchantTournaments = createSelector(
	finishedMerchantTournamentsSelector,
	({ total }) => total
);

export const getTournamentsSelectedTournament = createSelector(
	tournamentsSelector,
	({ selectedTournament }) => selectedTournament
);

export const getTournamentsByMerchantId = (merchantId: string) =>
	createSelector(allTournamentsSelector, (state) =>
		tournamentsEntitySelectors.selectAll(state).filter(({ merchant }) => merchant.id === merchantId)
	);

export const getTournamentByChampionship = (championship_id: string) =>
	createSelector(allTournamentsSelector, (state) =>
		tournamentsEntitySelectors.selectAll(state).filter(({ championshipId }) => championshipId === championship_id)
	);

export const getEndedTournament = createSelector(
	endedTournamentsSelector,
	championshipEndedTournamentsEntitySelectors.selectAll
);

export const getTournamentsLoadingStates = (action: keyof ITournamentLoadingStates) =>
	createSelector(tournamentsSelector, ({ loadingStates }) => loadingStates[action]);

export const getTournamentsPrized = createSelector(
	prizedTournamentsSelector,
	prizedTournamentsEntitySelectors.selectAll
);

export const getTournamentsRegistered = createSelector(
	registeredTournamentsSelector,
	registeredTournamentsEntitySelectors.selectAll
);

export const getTournamentsRegisteredIds = createSelector(
	registeredTournamentsSelector,
	registeredTournamentsEntitySelectors.selectIds
);

export const getRegisteredTournamentById = (tournamentId: string | null) =>
	createSelector(allTournamentsSelector, prizedTournamentsSelector, (allTournaments, prizedTournaments) => {
		return [
			...tournamentsEntitySelectors.selectAll(allTournaments),
			...prizedTournamentsEntitySelectors.selectAll(prizedTournaments),
		].find((tournament) => tournament.id === tournamentId);
	});
export const getMerchantFinishedTournaments = createSelector(
	finishedMerchantTournamentsSelector,
	finishedMerchantTournamentsEntitySelectors.selectAll
);
export const getRankingParticipantsNumber = createSelector(
	tournamentsSelector,
	({ totalRankingParticipants }) => totalRankingParticipants
);
