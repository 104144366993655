import { changeUserLanguage } from '@features/auth/store/auth.actions';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import IconArrowDown from '@features/common/components/IconArrowDown';
import IconLanguage from '@features/common/components/IconLanguage';
import { showToast } from '@features/common/store/common.reducer';
import { setLanguage } from '@features/layout/store/layout.reducer';
import useOnClickOutside from '@hooks/useClickOutside';
import { parseError } from '@http/httpHelpers';
import { supportedLanguages } from '@locales/index';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from '../store/layout.selectors';

const HeaderLanguage = () => {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(getCurrentUser);
	const [isOpened, setIsOpened] = useState(false);
	const dropdownMenuRef = useRef<HTMLDivElement>(null);
	const selectedLanguage = useSelector(getSelectedLanguage);

	const { formatMessage } = useIntl();

	useOnClickOutside(
		dropdownMenuRef,

		useCallback(() => setIsOpened(false), [])
	);

	const onLanguageChange = (languageId: string) => {
		setIsOpened(false);

		if (currentUser) {
			dispatch(changeUserLanguage({ id: languageId }))
				.then(unwrapResult)
				.then(() => dispatch(setLanguage(languageId)))
				.catch((error) => {
					const thrownError = parseError(error);
					dispatch(
						showToast({
							message: thrownError.message,
							type: 'warning',
							title: formatMessage({ id: 'bcs-Common-Oops' }),
						})
					);
				});
		} else {
			dispatch(setLanguage(languageId));
		}
	};

	return (
		<>
			<div className='cursor-pointer'>
				<div>
					<button
						data-testid='headerLanguage'
						className={`flex items-center space-x-2 focus:outline-none text-headerColor-100 ${
							isOpened ? 'pointer-events-none' : ''
						}`}
						onClick={() => setIsOpened(true)}
					>
						<IconLanguage />
						<span className={`transition-transform ${isOpened ? 'rotate-180' : 'rotate-0'}`}>
							<IconArrowDown />
						</span>
					</button>
					<AnimatePresence mode='wait'>
						{isOpened ? (
							<motion.div
								initial={{ opacity: 0, y: -10 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -10 }}
								transition={{ duration: 0.2 }}
								ref={dropdownMenuRef}
								className={`absolute py-2 mt-4 w-44 bg-white border rounded-lg shadow-xl right-[10px] lg:right-[unset]`}
							>
								<div className='block px-4 text-left py-2 cursor-default underline select-none'>
									{formatMessage({ id: 'bcs-Common-Language' })}
								</div>
								{supportedLanguages.map((language) => (
									<button
										className={`px-4 w-full text-left py-2 transition-colors hover:bg-brightGray-100 font-normal select-none ${
											language.id === selectedLanguage ? 'text-main-500' : ''
										}`}
										data-testid={`headerLanguageOption${language.id}btn`}
										key={language.id}
										onClick={() => onLanguageChange(language.id)}
									>
										{language.name}
									</button>
								))}
							</motion.div>
						) : null}
					</AnimatePresence>
				</div>
			</div>
		</>
	);
};

export default HeaderLanguage;
